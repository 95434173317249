import React, { Component, useState } from "react"; // Import useState
import { withRouter } from "react-router-dom"; // Import withRouter
import { CheckIcon } from "@heroicons/react/outline";
import { observer, inject } from "mobx-react";

@inject("store")
@observer
class EntryPromptBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: "",
      time: 20 * 60, // 20 minutes in seconds
      isTimerActive: false,
    };

    this.interval = null;
  }

  startTimer = () => {
    if (!this.state.isTimerActive && this.state.time > 0) {
      this.setState({ isTimerActive: true });
      this.interval = setInterval(() => {
        this.setState(
          (prevState) => ({
            time: prevState.time - 1,
          }),
          () => {
            if (this.state.time <= 0) {
              clearInterval(this.interval);
              this.setState({ isTimerActive: false });
              alert("Time is up!");
            }
          }
        );
      }, 1000);
    }
  };

  formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  downloadFile = () => {
    // Assuming your file is located in the public directory
    // and your app is served from the root
    const link = document.createElement("a");
    link.href = `${process.env.PUBLIC_URL}/example.png`; // Adjust the path as needed
    link.setAttribute("download", "example.png"); // Set the filename for download
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  generateIeltsAcademic1PracticePaper = () => {
    const topicImagePairs = [
      {
        topic:
          "The graph above shows the number of visitors to four international museums over a period of one year. Summarize the information by selecting and reporting the main features, and make comparisons where relevant.        .",
        image: "/example1.png",
      },
      {
        topic:
          "The pie charts above show the percentage distribution of water usage in a typical household in two different countries, Country A and Country B, in the year 2023. Summarize the information by selecting and reporting the main features, and make comparisons where relevant.",
        image: "/example2.png",
      },
      {
        topic:
          "The line graph above shows the average monthly temperatures (in degrees Celsius) for London, New York, and Sydney over a 12-month period. Summarize the information by selecting and reporting the main features, and make comparisons where relevant.",
        image: "/example3.png",
      },

      {
        topic:
          "The pie charts above show the distribution of spending by a typical household in Country A and Country B in the year 2020. Summarize the information by selecting and reporting the main features, and make comparisons where relevant.",
        image: "/example4.png",
      },

      {
        topic:
          "The bar graph above shows the internet usage (in hours per week) of individuals in four different age groups: 16-24, 25-34, 35-44, and 45-54 in Canada. Summarize the information by selecting and reporting the main features, and make comparisons where relevant.",
        image: "/example5.png",
      },

      {
        topic:
          "The line graph illustrates the yearly number of visitors (in millions) to three museums: the Louvre, the British Museum, and the Metropolitan Museum of Art over a five-year period. Summarize the information by selecting and reporting the main features, and make comparisons where relevant.",
        image: "/example6.png",
      },

      {
        topic:
          "The line graph depicts the growth in the number of registered electric vehicles (EVs) in the United States, China, Norway, and Germany over a period of six years. Summarize the main trends and make comparisons where relevant.",
        image: "/example7.png",
      },
      {
        topic:
          "The line graph illustrates the percentage of individuals using the internet in four different countries—Japan, Sweden, India, and Brazil—over a ten-year period. Describe the trends and differences among the countries, and summarize the main points.",
        image: "/example8.png",
      },
      {
        topic:
          "The bar chart illustrates the average monthly rainfall (in millimeters) in Bangkok, London, Cairo, and Sydney over the course of a year. Summarize the main features and make comparisons where relevant.",
        image: "/example9.png",
      },

      {
        topic:
          "The bar chart displays the total sales revenue (in million USD) of four major technology companies—Apple, Samsung, Google, and Microsoft—over two consecutive years, 2018 and 2019. Summarize the sales trends over the years and compare the performance of these companies.",
        image: "/example10.png",
      },

      {
        topic:
          "The line graph depicts the average price of residential electricity per kilowatt-hour in cents for four countries—Germany, the United States, Canada, and Japan—over a five-year period. Summarize the key trends and compare the prices across countries and over time.",
        image: "/example11.png",
      },

      {
        topic:
          "The line graph shows the daily average number of steps walked by individuals in four different age groups—18-29, 30-49, 50-69, and 70+—over a week. Describe the variations in the daily step count among the different age groups and note any trends over the course of the week.",
        image: "/example12.png",
      },

      {
        topic:
          "The bar graph compares the annual greenhouse gas emissions (in million metric tons of CO2 equivalent) of four sectors—Transportation, Industry, Residential, and Agriculture—in a specific country over the course of a single year. Describe the distribution of emissions among these sectors and highlight any significant trends.",
        image: "/example13.png",
      },

      {
        topic:
          "The bar graph displays the number of public libraries in four different regions—North, South, East, and West—in a country for the year 2020. Summarize the information by selecting and reporting the main features, and make comparisons where relevant.",
        image: "/example14.png",
      },

      {
        topic:
          "The bar graph shows the number of public libraries in four major cities—New York, Los Angeles, Toronto, and London—over two distinct years, 2010 and 2020. Analyze the growth or decline in the number of public libraries in these cities and compare the figures across the two years.",
        image: "/example15.png",
      },

      {
        topic:
          "The line graph illustrates the quarterly GDP growth rate (percentage change from the previous quarter) of four countries—Australia, Brazil, France, and India—over a two-year period. Summarize the main trends and compare the growth rates across the countries and over time.",
        image: "/example16.png",
      },

      {
        topic:
          "The line graph displays the monthly average air quality index (AQI) in four major cities—Beijing, Los Angeles, Delhi, and London—over the course of one year. Summarize the changes in air quality across these cities and throughout the year, highlighting significant variations.",
        image: "/example17.png",
      },

      {
        topic:
          "The two pie charts compare the market share of different smartphone operating systems—Android, iOS, Windows, and Others—in two separate years, 2018 and 2023. Analyze the changes in market share over the five-year period, noting significant trends or shifts.",
        image: "/example18.png",
      },

      {
        topic:
          "The two pie charts illustrate the distribution of global energy consumption by source in two different years, 2010 and 2020. Describe the changes in energy source distribution over the ten-year period, highlighting any significant trends.",
        image: "/example19.png",
      },

      {
        topic:
          "The two pie charts compare the percentage of government spending in six categories—Healthcare, Education, Defense, Infrastructure, Social Welfare, and Others—in two separate years, 2015 and 2020. Analyze the changes in spending allocation over the five-year period, noting significant trends or shifts.",
        image: "/example20.png",
      },

      {
        topic:
          "The two pie charts illustrate the composition of waste management methods—Recycling, Landfill, Incineration, and Composting—in Metro City for the years 2005 and 2015. Summarize the changes in waste management practices over the ten-year period, highlighting significant trends.",
        image: "/example21.png",
      },

      {
        topic:
          "The line graph illustrates the annual carbon dioxide (CO2) emissions (in million metric tons) from transportation in four regions—North America, Europe, Asia, and South America—over a decade, from 2010 to 2019. Summarize the trends in emissions over the period, noting any significant changes and comparisons among the regions.",
        image: "/example22.png",
      },

      {
        topic:
          "The line graph displays the change in average global surface temperature anomalies (degrees Celsius) from 2000 to 2019, compared to the 20th-century average. Summarize the information by selecting and reporting the main features, and make comparisons where relevant.",
        image: "/example23.png",
      },

      {
        topic:
          "The line graph displays the daily number of steps taken by an individual over a month, aiming to achieve a goal of 10,000 steps per day. The graph compares the actual steps taken each day with the goal. Describe the individual's performance over the month, noting any trends and significant deviations from the goal.",
        image: "/example24.png",
      },

      {
        topic:
          "The bar chart illustrates the average time spent on various activities in a day by adults in four different countries—Japan, Italy, Canada, and Australia. The activities include work, leisure, and sleep. Summarize the findings and make comparisons where relevant.",
        image: "/example25.png",
      },

      {
        topic:
          "The line graph displays the average monthly precipitation (in millimeters) in four different climatic zones—Tropical, Arid, Temperate, and Polar—over the course of one year. Describe the variations in precipitation among these climatic zones and throughout the year, noting any significant patterns or differences.",
        image: "/example26.png",
      },

      {
        topic:
          "The line graph illustrates the percentage of households with access to high-speed internet in four countries—Finland, South Korea, Canada, and Italy—over a five-year period. Summarize the trends in internet access among these countries, highlighting significant changes and differences.",
        image: "/example27.png",
      },

      {
        topic:
          "The line graph depicts the annual average temperature anomalies (in degrees Celsius compared to a 20th-century average) in four different global regions—North America, Europe, Asia, and Africa—over a decade, from 2010 to 2019. Describe the temperature trends in these regions over the period, highlighting any significant fluctuations and comparisons.",
        image: "/example28.png",
      },

      {
        topic:
          "The line chart shows the average yearly income in thousand USD for individuals in the United States, Germany, Japan, and Brazil from 2015 to 2019. The bar chart illustrates the average annual savings rate as a percentage of income for the same countries in the year 2019. Summarize the information by selecting and reporting the main features, and make comparisons where relevant.",
        image: "/example29.png",
      },

      {
        topic:
          "The data provided illustrates two aspects of higher education in four different countries—Canada, Australia, the United Kingdom, and India—over a period of five years, from 2015 to 2019. The line chart shows the annual number of international students enrolled in higher education institutions, while the bar chart displays the percentage of graduates in STEM fields (Science, Technology, Engineering, and Mathematics) for the year 2019. Analyze the trends in international student enrollment and the STEM graduates' percentage among these countries, noting significant changes and drawing comparisons where relevant.",
        image: "/example30.png",
      },

      // Additional pairs
    ];

    const randomIndex = Math.floor(Math.random() * topicImagePairs.length);
    const selectedPair = topicImagePairs[randomIndex];

    // Assuming the image is stored in the public directory and accessible
    const imageUrl = `${process.env.PUBLIC_URL}${selectedPair.image}`;
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result;

          // Updating the state with the new image URL and base64 data
          this.setState(
            {
              imageUrl: imageUrl,
              imageBase64: base64data, // Save the Base64-encoded image data in state
            },
            () => {
              // Log the state to verify it's correctly updated
              console.log("Updated state with image data:", this.state);
            }
          );

          // Update the prompt with the selected topic
          this.props.prompt.prompts[0].value = selectedPair.topic;
        };
        reader.readAsDataURL(blob);
      });
  };

  ieltsacademic2 = () => {
    const topics = [
      //1
      {
        topic:
          "With the rapid advancement of technology, some argue that the traditional classroom setting is becoming obsolete. To what extent do you agree or disagree with this statement? Provide reasons for your answer and include any relevant examples from your own knowledge or experience.",
        opening: "",
      },
      //2
      {
        topic:
          "The gap between the rich and the poor seems to be increasing in many parts of the world. What do you think are the main causes of this issue, and what measures could be taken to address it? Use examples from your own knowledge or experience to support your response.",
        opening: "",
      },
      //3
      {
        topic:
          "Environmental pollution has become a serious problem globally. Discuss the main contributors to this issue and suggest possible solutions to reduce the impact of pollution on the planet. Reference any relevant examples from your own knowledge or experience.",
        opening: "",
      },
      //4
      {
        topic:
          "Some educators believe that group assignments are more beneficial for students than individual tasks. Discuss the advantages and disadvantages of this approach in education. Include any relevant examples from your own experience or knowledge to support your argument.",
        opening: "",
      },
      //5
      {
        topic:
          "In many countries, the population is aging rapidly, leading to concerns about healthcare and pension systems. What challenges does this present, and how might these challenges be addressed? Draw on examples from your own knowledge or experience in your answer.",
        opening: "",
      },
      //6
      {
        topic:
          "The accessibility of fast food has been linked to increasing rates of obesity in many societies. Discuss the extent to which fast food chains are responsible for this issue and suggest measures that could mitigate the problem. Use examples from your knowledge or experience to illustrate your points.",
        opening: "",
      },
      //7
      {
        topic:
          "Social media has changed the way we communicate and access information. Discuss the advantages and disadvantages of this development. Provide examples from your own experience or knowledge to support your discussion.",
        opening: "",
      },
      //8
      {
        topic:
          "Telecommuting has become increasingly popular, with some suggesting it could replace traditional office jobs entirely. Discuss the potential benefits and drawbacks of telecommuting for both employees and employers. Use your own experience or knowledge to inform your answer.",
        opening: "",
      },
      //9
      {
        topic:
          "Some argue that governments should invest more in public transport to reduce traffic congestion and improve air quality. To what extent do you agree or disagree with this opinion? Justify your answer with reasons and examples from your knowledge or experience.",
        opening: "",
      },
      //10
      {
        topic:
          "The arts (music, theater, visual arts, etc.) are often seen as less important than subjects such as math, science, and language in school curriculums. Discuss the importance of the arts in education and how they contribute to a student's overall development. Provide examples from your knowledge or experience to support your argument.",
        opening: "",
      },
      //11
      {
        topic:
          "Consumerism is often blamed for many social and environmental issues. To what extent do you agree or disagree with this perspective? Provide reasons and examples from your own knowledge or experience to support your view.",
        opening: "",
      },
      //12
      {
        topic:
          "The rise of artificial intelligence (AI) is seen by some as a threat to many jobs. Discuss the potential impacts of AI on the job market and how workers can prepare for the changes it may bring. Include relevant examples from your knowledge or experience.",
        opening: "",
      },
      //13
      {
        topic:
          "Access to clean water is a significant problem in many parts of the world. Discuss the causes of water scarcity and propose potential solutions to ensure global access to clean water. Use examples from your own knowledge or experience.",
        opening: "",
      },
      //14
      {
        topic:
          "Urbanization is accelerating globally, with more people moving to cities every year. Discuss the advantages and disadvantages of urban living. Support your arguments with examples from your own knowledge or experience.",
        opening: "",
      },
      //15
      {
        topic:
          "Mental health issues are becoming increasingly recognized and discussed. Discuss the importance of mental health awareness and suggest ways in which societies can improve support for those affected. Include relevant examples from your experience or research.",
        opening: "",
      },
      //16
      {
        topic:
          "Climate change is often described as one of the biggest challenges of our time. Discuss the role individuals can play in combating climate change. Provide specific examples from your own knowledge or experience to support your viewpoint.",
        opening: "",
      },
      //17
      {
        topic:
          "The digital divide refers to the gap between those who have access to computers and the internet and those who do not. Discuss the implications of the digital divide and suggest how this gap can be reduced. Reference examples from your knowledge or experience.",
        opening: "",
      },
      //18
      {
        topic:
          "Education systems around the world vary greatly. Discuss the merits and drawbacks of your country's education system compared to another system you are familiar with. Use specific examples to support your comparison.",
        opening: "",
      },
      //19
      {
        topic:
          "Gender equality has been a significant topic of discussion in recent years. Discuss the progress that has been made towards achieving gender equality and the challenges that still exist. Use examples from your own knowledge or experience.",
        opening: "",
      },
      //20
      {
        topic:
          "Renewable energy sources are considered crucial for the future of global energy. Discuss the advantages and limitations of renewable energy technologies. Support your argument with examples from your knowledge or experience.",
        opening: "",
      },
      //21
      {
        topic:
          "The impact of tourism on culture can be both positive and negative. Discuss the effects of tourism on local cultures and how negative impacts can be mitigated. Include specific examples from your own knowledge or experience.",
        opening: "",
      },
      //22
      {
        topic:
          "Sports are an essential part of many societies. Discuss the benefits of sports for individuals and communities. Provide reasons and examples from your own experience or knowledge to support your answer.",
        opening: "",
      },
      //23
      {
        topic:
          "The phenomenon of 'brain drain' affects many countries. Discuss the causes of brain drain and suggest measures that could be taken to address this issue. Use examples from your own knowledge or experience to inform your response.",
        opening: "",
      },
      //24
      {
        topic:
          "Public spaces play a crucial role in community wellbeing. Discuss the importance of public spaces in urban areas and how they can be designed to benefit communities. Include examples from your own experience or knowledge.",
        opening: "",
      },
      //25
      {
        topic:
          "The growing dependence on technology raises questions about privacy and security. Discuss the challenges related to privacy in the digital age and suggest how individuals can protect their personal information. Reference examples from your knowledge or experience.",
        opening: "",
      },
      //26
      {
        topic:
          "Nutrition is increasingly recognized as a crucial aspect of public health. Discuss the role of government in promoting healthy eating habits among the population. Use examples from your own knowledge or experience to support your argument.",
        opening: "",
      },
      //27
      {
        topic:
          "The balance between work and life is a topic of concern for many in today's fast-paced society. Discuss strategies that individuals and companies can use to achieve a healthier work-life balance. Support your discussion with examples from your experience or research.",
        opening: "",
      },
      //28
      {
        topic:
          "Cultural exchange programs have the potential to enhance mutual understanding between different societies. Discuss the benefits of such programs and consider their potential limitations. Use examples from your own experience or knowledge.",
        opening: "",
      },
      //29
      {
        topic:
          "The rise of freelance and gig economy jobs presents new opportunities and challenges for workers. Discuss the implications of this trend for the future of work. Include examples from your knowledge or experience to illustrate your points.",
        opening: "",
      },
      //30
      {
        topic:
          "Sustainability is a critical consideration in all areas of development. Discuss how sustainable practices can be incorporated into everyday life and business operations. Provide examples from your own knowledge or experience to support your discussion.",
        opening: "",
      },
      //31
      {
        topic:
          "The globalization of food production and consumption has both positive and negative impacts on societies. Discuss these impacts and suggest ways to mitigate the negative effects while enhancing the positive ones. Use examples from your knowledge or experience.",
        opening: "",
      },
      //32
      {
        topic:
          "Online learning platforms have become increasingly popular. Discuss the advantages and disadvantages of online education compared to traditional classroom-based learning. Reference your own experience or knowledge where applicable.",
        opening: "",
      },
      //33
      {
        topic:
          "The aging population in many countries is seen as a challenge. Discuss the potential social and economic impacts of an aging population and suggest strategies to address these challenges. Include relevant examples from your knowledge or experience.",
        opening: "",
      },
      //34
      {
        topic:
          "Some argue that space exploration is a waste of resources that could be better used to address problems on Earth. To what extent do you agree or disagree? Support your argument with examples from your own knowledge or research.",
        opening: "",
      },
      //35
      {
        topic:
          "Discuss the importance of biodiversity and the main threats it faces today. Suggest measures that can be taken to protect biodiversity, using examples from your own knowledge or experience.",
        opening: "",
      },
      //36
      {
        topic:
          "Social entrepreneurship is gaining popularity as a way to address various societal problems. Discuss the potential impact of social entrepreneurship and provide examples of successful social enterprises from your knowledge or experience.",
        opening: "",
      },
      //37
      {
        topic:
          "With the increase in urban population, public transportation systems are under pressure. Discuss the challenges and opportunities this presents for sustainable urban development. Reference examples from your own experience or research.",
        opening: "",
      },
      //38
      {
        topic:
          "Cybersecurity threats are becoming more prevalent and sophisticated. Discuss the importance of cybersecurity in today’s digital world and suggest measures to improve security. Use examples from your knowledge or experience.",
        opening: "",
      },
      //39
      {
        topic:
          "Language preservation is important for cultural heritage and diversity. Discuss the challenges faced in preserving minority languages and suggest strategies that could be employed to address these challenges. Include examples from your experience or knowledge.",
        opening: "",
      },
      //40
      {
        topic:
          "The role of media in shaping public opinion is significant. Discuss the positive and negative influences of media on public perception and suggest ways to ensure responsible media reporting. Use examples from your own knowledge or experience.",
        opening: "",
      },
      //41
      {
        topic:
          "Discuss the impact of consumer culture on society and the environment, and suggest ways to promote more responsible consumer behavior. Include relevant examples from your experience or research.",
        opening: "",
      },
      //42
      {
        topic:
          "The development of green technology is seen as a key to sustainable future. Discuss the potential benefits and challenges associated with green technology innovations. Use examples from your knowledge or experience.",
        opening: "",
      },
      //43
      {
        topic:
          "Urban green spaces are vital for city-dwellers' well-being. Discuss the benefits of urban green spaces and the challenges in incorporating them into city planning. Reference examples from your experience or knowledge.",
        opening: "",
      },
      //44
      {
        topic:
          "The rise of autonomous vehicles is poised to revolutionize transportation. Discuss the potential impact on society and the environment, and address the challenges that may arise. Include examples from your own knowledge or experience.",
        opening: "",
      },
      //45
      {
        topic:
          "Ethical consumerism aims to promote positive social and environmental change. Discuss the significance of ethical consumerism and the challenges consumers face in making ethical choices. Use examples from your experience or research.",
        opening: "",
      },
      //46
      {
        topic:
          "The arts can play a significant role in community development and social change. Discuss how the arts can be utilized for societal benefits and provide examples of arts-driven community projects from your knowledge or experience.",
        opening: "",
      },
      //47
      {
        topic:
          "Discuss the ethical implications of genetic engineering and its potential to change society. Include considerations of both the benefits and the risks, referencing examples from your own knowledge or research.",
        opening: "",
      },
      //48
      {
        topic:
          "Water scarcity is becoming a critical problem in many regions of the world. Discuss the causes of water scarcity and suggest sustainable solutions to ensure water security. Use examples from your knowledge or experience.",
        opening: "",
      },
      //49
      {
        topic:
          "Debates on immigration policy often reflect broader social and economic issues. Discuss the complex factors that shape immigration policy and its impact on both host and origin countries. Include examples from your own knowledge or experience.",
        opening: "",
      },
      //50
      {
        topic:
          "The concept of a universal basic income has gained attention as a potential solution to job displacement caused by technology. Discuss the advantages and disadvantages of implementing a universal basic income. Reference examples from your research or knowledge.",
        opening: "",
      },
      //51
      {
        topic:
          "With the increasing prevalence of remote work, discuss the long-term impacts this could have on workforce dynamics and urban development. Use examples from your knowledge or experience to inform your answer.",
        opening: "",
      },
      //52
      {
        topic:
          "The phenomenon of over-tourism has impacted both the environment and local communities adversely in popular destinations. Discuss strategies that could be implemented to manage tourist numbers while still promoting sustainable tourism. Include examples from your own experience or research.",
        opening: "",
      },
      //53
      {
        topic:
          "Examine the role of youth activism in shaping policy and public opinion on climate change. Discuss the effectiveness of these movements and provide examples from recent campaigns or initiatives.",
        opening: "",
      },
      //54
      {
        topic:
          "The use of technology in agriculture, often termed 'precision agriculture,' promises to increase efficiency and reduce waste. Discuss the potential benefits and ethical considerations of implementing such technologies, using examples where possible.",
        opening: "",
      },
      //55
      {
        topic:
          "As artificial intelligence becomes more integrated into healthcare, discuss the potential benefits and challenges this presents to patient care and medical ethics. Reference specific examples from current practices or research.",
        opening: "",
      },
      //56
      {
        topic:
          "Examine the impact of microplastics on marine ecosystems and human health. Discuss measures that can be taken to reduce microplastic pollution, citing examples from initiatives around the world.",
        opening: "",
      },
      //57
      {
        topic:
          "Discuss the phenomenon of urban sprawl and its implications for environmental sustainability and quality of life. Suggest policy measures or urban planning strategies to address the challenges of urban sprawl, using examples where relevant.",
        opening: "",
      },
      //58
      {
        topic:
          "The intersection of technology and education opens new avenues for personalized learning. Discuss how technology can be leveraged to cater to diverse learning needs and styles, including examples from recent educational technology innovations.",
        opening: "",
      },
      //59
      {
        topic:
          "Evaluate the role of international aid in disaster relief and development. Discuss the benefits and potential drawbacks of such aid, including considerations of dependency and sustainability. Use examples from recent aid efforts.",
        opening: "",
      },
      //60
      {
        topic:
          "Social media platforms are often criticized for contributing to political polarization. Discuss the mechanisms by which this occurs and propose measures to mitigate polarization while preserving free speech online. Include relevant examples.",
        opening: "",
      },
      //61
      {
        topic:
          "Discuss the cultural and economic impacts of global film and music industries on local cultures. Evaluate the balance between cultural globalization and cultural preservation, using specific instances to illustrate your points.",
        opening: "",
      },
      //62
      {
        topic:
          "Examine the challenges and opportunities presented by the transition to renewable energy sources in developing countries. Discuss how these countries can overcome barriers to adoption, using examples from recent initiatives.",
        opening: "",
      },
      //63
      {
        topic:
          "With the growth of gig economy jobs, discuss the potential long-term economic and social impacts on workers. Propose measures that could offer protections and benefits for gig workers, citing relevant examples.",
        opening: "",
      },
      //64
      {
        topic:
          "The consolidation of media ownership has raised concerns about media diversity and objectivity. Discuss the implications of this trend and suggest how media pluralism can be preserved, including examples from different countries.",
        opening: "",
      },
      //65
      {
        topic:
          "Investigate the role of smart cities in addressing urban challenges such as traffic congestion, public safety, and sustainability. Provide examples of smart city initiatives and discuss their successes and limitations.",
        opening: "",
      },
      //66
      {
        topic:
          "The ethics of animal testing in medical research is a contentious issue. Discuss alternative methods to animal testing and the potential impacts of these alternatives on research efficacy, referencing specific examples.",
        opening: "",
      },
      //67
      {
        topic:
          "The rise in popularity of plant-based diets has implications for health, the environment, and economies. Discuss these implications, providing evidence from recent studies or statistics to support your arguments.",
        opening: "",
      },
      //68
      {
        topic:
          "Examine the impact of cryptocurrency on global finance and banking. Discuss the potential benefits and risks of cryptocurrency adoption, including considerations of security and regulation.",
        opening: "",
      },
      //69
      {
        topic:
          "The practice of mindfulness and meditation has gained popularity as a method for reducing stress. Discuss the scientific evidence supporting the benefits of these practices and consider how they can be integrated into daily life.",
        opening: "",
      },
      //70
      {
        topic:
          "Assess the role of public art in urban spaces. Discuss how public art contributes to community identity and urban aesthetics, and suggest ways to foster a vibrant public art scene in cities.",
        opening: "",
      },
      //71
      {
        topic:
          "Discuss the significance of preserving intangible cultural heritage, such as languages, rituals, and traditions, for future generations. Propose methods for effective preservation, citing examples of successful efforts.",
        opening: "",
      },
      //72
      {
        topic:
          "The intersection of fashion and sustainability presents both challenges and opportunities. Discuss how the fashion industry can adopt more sustainable practices without compromising on style or profitability. Include recent initiatives as examples.",
        opening: "",
      },
      //73
      {
        topic:
          "With an increasing number of people opting for a nomadic lifestyle enabled by digital technology, discuss the social and economic implications of this trend. How does it redefine concepts of home, work, and community?",
        opening: "",
      },
      //74
      {
        topic:
          "The decline in bee populations globally poses a significant threat to agricultural systems. Discuss the causes of this decline and propose interventions to protect and restore bee populations, using specific case studies.",
        opening: "",
      },
      //75
      {
        topic:
          "Examine the role of digital currencies in the future of financial transactions. Discuss the potential for digital currencies to supplant traditional forms of money and the challenges they may face in achieving widespread adoption.",
        opening: "",
      },
      //76
      {
        topic:
          "The concept of zero-waste living aims to eliminate trash and reduce consumerism. Discuss the practicalities, challenges, and impacts of adopting a zero-waste lifestyle, including examples of communities or individuals who have made significant strides.",
        opening: "",
      },
      //77
      {
        topic:
          "Discuss the impact of virtual reality (VR) technology on education and training. How can VR transform learning experiences and outcomes in various fields such as medicine, engineering, and history?",
        opening: "",
      },
      //78
      {
        topic:
          "Explore the ethical and social implications of gene editing technologies, particularly CRISPR. Discuss the potential benefits and risks associated with editing human DNA, including considerations of accessibility and consent.",
        opening: "",
      },
      //79
      {
        topic:
          "The resurgence of vinyl records in a digital age presents an interesting cultural phenomenon. Discuss the reasons behind this revival and the implications for music consumption, production, and community.",
        opening: "",
      },
      //80
      {
        topic:
          "Analyze the role of corporate social responsibility (CSR) in today’s business landscape. How does genuine CSR benefit companies, communities, and the environment? Provide examples of organizations that have integrated CSR into their business model effectively.",
        opening: "",
      },
      //81
      {
        topic:
          "With the advent of autonomous vehicles, discuss the ethical considerations surrounding decision-making algorithms in the event of unavoidable accidents. How should these systems prioritize outcomes?",
        opening: "",
      },
      //82
      {
        topic:
          "Evaluate the potential of urban agriculture to contribute to food security in densely populated areas. Discuss the benefits and limitations of urban farming practices, including vertical farming and community gardens.",
        opening: "",
      },
      //83
      {
        topic:
          "The phenomenon of social media influencers has changed the landscape of marketing and advertising. Discuss the impact of influencer marketing on consumer behavior and its implications for traditional advertising models.",
        opening: "",
      },
      //84
      {
        topic:
          "Examine the implications of the 'right to be forgotten' in the digital age, particularly in relation to personal data and online histories. How does this right interact with the principles of freedom of information and public interest?",
        opening: "",
      },
      //85
      {
        topic:
          "The rise of 'fast fashion' has significant environmental and ethical implications. Discuss strategies that consumers, companies, and governments can employ to promote sustainable fashion consumption.",
        opening: "",
      },
      //86
      {
        topic:
          "With the growing demand for renewable energy sources, evaluate the potential of ocean energy (wave and tidal). Discuss the challenges and opportunities associated with harnessing energy from the ocean.",
        opening: "",
      },
      //87
      {
        topic:
          "The increasing use of drones in various sectors (from agriculture to logistics) presents new challenges and opportunities. Discuss the implications of widespread drone use on privacy, safety, and regulation.",
        opening: "",
      },
      //88
      {
        topic:
          "Analyze the effect of climate change on traditional agriculture practices and food security. Discuss adaptive measures that can help communities sustain agricultural productivity amid changing weather patterns.",
        opening: "",
      },
      //89
      {
        topic:
          "The global push towards digitization of government services aims to increase efficiency and accessibility. Discuss the benefits and potential pitfalls of digitizing public services, with a focus on examples from healthcare and welfare.",
        opening: "",
      },
      //90
      {
        topic:
          "Telehealth and remote medical services have seen a surge in adoption due to global health challenges. Discuss the long-term impacts of telehealth on patient care and the healthcare system, including considerations of equity and accessibility.",
        opening: "",
      },
      //91
      {
        topic:
          "Discuss the role of artificial intelligence in predictive policing and the ethical considerations it raises. How can societies balance the benefits of crime reduction with the protection of individual rights and privacy?",
        opening: "",
      },
      //92
      {
        topic:
          "Evaluate the impact of microfinance on poverty alleviation in developing countries. Discuss the success stories and potential limitations of microfinance initiatives, with a focus on their sustainability and impact on local economies.",
        opening: "",
      },
      //93
      {
        topic:
          "The increasing occurrence of extreme weather events has highlighted the urgency of climate resilience in urban planning. Discuss measures that cities can take to become more resilient against such events, including examples of successful strategies.",
        opening: "",
      },
      //94
      {
        topic:
          "Analyze the role of social enterprises in addressing societal issues. Discuss how social enterprises differ from traditional charities and for-profit businesses in their approach and impact, including case studies of successful social enterprises.",
        opening: "",
      },
      //95
      {
        topic:
          "Explore the concept of digital nomadism and its implications for the future of work and lifestyle. Discuss the benefits and challenges faced by digital nomads and the potential long-term impacts on society.",
        opening: "",
      },
      //96
      {
        topic:
          "The phenomenon of eSports has grown exponentially, attracting a large global audience. Discuss the cultural and economic impacts of eSports becoming mainstream, including its recognition as a professional sport.",
        opening: "",
      },
      //97
      {
        topic:
          "The global trend towards decriminalizing or legalizing cannabis poses new societal and economic questions. Discuss the implications of these policy changes, including potential benefits and challenges, with a focus on public health and safety.",
        opening: "",
      },
      //98
      {
        topic:
          "Innovations in biotechnology offer potential solutions to environmental challenges. Discuss biotechnological approaches to pollution control and resource conservation, providing examples of promising technologies or initiatives.",
        opening: "",
      },
      //99
      {
        topic:
          "Investigate the psychological effects of social media use on teenagers and young adults. Discuss potential positive aspects and harmful impacts, with suggestions for mitigating negative effects while enhancing beneficial outcomes.",
        opening: "",
      },
      //100
      {
        topic:
          "The push for smart cities incorporates technology into urban development for efficiency and sustainability. Analyze the potential social implications of living in highly digitized urban environments, focusing on aspects such as privacy, community, and human interaction.",
        opening: "",
      },
      // Additional topics can be added here
    ];

    const randomIndex = Math.floor(Math.random() * topics.length);
    const randomTopic = topics[randomIndex];

    this.props.prompt.prompts[0].value = randomTopic.topic;
    this.props.prompt.prompts[1].value = randomTopic.opening;
  };

  ieltsgeneral1 = () => {
    const papers = [
      //1
      {
        topic:
          "Your child's school is organizing a cultural festival and is looking for parents to help in various capacities. Write a letter to the event coordinator volunteering your services. In your letter:\n- Mention your name and your child’s name and grade.\n- Explain why you are interested in volunteering for the cultural festival.\n- Describe the skills or experiences you have that make you a suitable volunteer for the event.",
        opening: "",
      },
      //2
      {
        topic:
          "You are planning to start a community garden in your neighborhood and are seeking participants. Write a letter to your neighbors inviting them to join the initiative. In your letter:\n- Introduce yourself and the concept of the community garden.\n- Explain the benefits of participating in the community garden.\n- Provide details on how they can join or express interest.",
        opening: "",
      },
      //3
      {
        topic:
          "You have discovered a mistake on a bill you received from a local utility company. Write a letter to the company's customer service department. In your letter:\n- Introduce yourself and provide your customer account number.\n- Explain the mistake you found on the bill.\n- Describe what you believe the correct charge should be and request an adjustment.",
        opening: "",
      },
      //4
      {
        topic:
          "Your local library is organizing a book donation drive for underprivileged schools. Write a letter to the library's coordinator expressing your interest in contributing. Include in your letter:\n- A brief self-introduction and why the initiative matters to you.\n- The kinds of books you are planning to donate.\n- Any other ways you are willing to support the drive (e.g., volunteering time, promoting the event).",
        opening: "",
      },
      //5
      {
        topic:
          "You are organizing a surprise retirement party for a colleague. Write a letter to another colleague asking for their help with the preparations. In your letter:\n- Explain who the retirement party is for and why.\n- Suggest ways they could help with the event.\n- Request their discretion to keep the party a surprise.",
        opening: "",
      },
      //6
      {
        topic:
          "You recently experienced excellent customer service at a local business and wish to commend the employee responsible. Write a letter to the manager of the business. In your letter:\n- Describe the nature of your visit to the business.\n- Detail the service you received and the employee who helped you.\n- Express why you felt the service was exceptional and worthy of commendation.",
        opening: "",
      },
      //7
      {
        topic:
          "A new job opportunity requires you to speak a second language which you are currently learning. Write a letter to the language school requesting information about intensive courses. In your letter:\n- Briefly present your current level in the language and your learning goals.\n- Ask for details regarding course options, schedules, and prices.\n- Inquire about the success rate of students who have taken intensive courses for professional purposes.",
        opening: "",
      },
      //8
      {
        topic:
          "You have decided to run for a position on your local community board. Write a letter to your neighbors to ask for their support. In your letter:\n- Introduce yourself and mention the position you are running for.\n- Explain why you decided to run and what you hope to achieve.\n- Encourage them to support you and explain how they can do so.",
        opening: "",
      },
      //9
      {
        topic:
          "You left a personal item on public transportation and wish to retrieve it. Write a letter to the transportation authority's lost and found department. In your letter:\n- Describe the item you lost and any identifiable features.\n- Explain when and where you believe you left it.\n- Request information on how to retrieve the item if it has been found.",
        opening: "",
      },
      //10
      {
        topic:
          "A friend is considering taking a gap year to travel but is uncertain. Write a letter to your friend advising them on their decision. In your letter:\n- Share your thoughts on the benefits and drawbacks of taking a gap year.\n- Suggest possible destinations or activities if they decide to travel.\n- Offer advice on how to make the most out of a gap year based on your own experiences or those of others.",
        opening: "",
      },
      //11
      {
        topic:
          "You are planning a neighborhood clean-up event. Write a letter to your neighbors to invite them to participate. In your letter:\n- Explain the purpose of the clean-up event.\n- Provide details about the time and meeting place.\n- Suggest items or equipment participants might bring.",
        opening: "",
      },
      //12
      {
        topic:
          "After a recent stay at a hotel, you realized you left a personal item behind. Write a letter to the hotel manager. In your letter:\n- Describe the item and where you think you left it.\n- Explain why the item is important to you.\n- Request assistance in returning the item.",
        opening: "",
      },
      //13
      {
        topic:
          "Your local community center is offering classes for adults. You are interested in signing up for one of them. Write a letter to the community center. In your letter:\n- Introduce yourself.\n- State the class you are interested in and ask for more information about it.\n- Explain why you want to take this class.",
        opening: "",
      },
      //14
      {
        topic:
          "You recently had a positive shopping experience in a local store and want to praise the staff. Write a letter to the store manager. In your letter:\n- Mention the date and time of your visit.\n- Describe the service you received.\n- Explain why you were particularly happy with the experience.",
        opening: "",
      },
      //15
      {
        topic:
          "You want to volunteer at a local animal shelter and need more information. Write a letter to the shelter. In your letter:\n- Express your interest in volunteering.\n- Mention any relevant experience you have.\n- Ask about the process for becoming a volunteer.",
        opening: "",
      },
      //16
      {
        topic:
          "Your friend is moving to your city and looking for advice on finding a job. Write a letter to your friend. In your letter:\n- Offer some general advice about job hunting in your city.\n- Suggest some companies or industries they might consider.\n- Invite them to stay with you temporarily if they need.",
        opening: "",
      },
      //17
      {
        topic:
          "You have been invited to speak at a local community event but are unable to attend. Write a letter to the organizers. In your letter:\n- Thank them for the invitation.\n- Explain why you cannot attend.\n- Suggest someone else who could speak in your place.",
        opening: "",
      },
      //18
      {
        topic:
          "You need to cancel a reservation you had made for a banquet hall due to unforeseen circumstances. Write a letter to the manager of the banquet hall. In your letter:\n- Provide details of the reservation.\n- Explain the reason for the cancellation.\n- Inquire about any possible cancellation fees.",
        opening: "",
      },
      //19
      {
        topic:
          "A friend has agreed to house-sit for you while you’re on vacation. Write a letter to your friend. In your letter:\n- Remind them when you will be leaving and returning.\n- Give them instructions on how to care for your pet.\n- Tell them how to use any appliances or items they might need.",
        opening: "",
      },
      //20
      {
        topic:
          "Your local museum is soliciting volunteer guides for a new exhibition. Write a letter to express your interest. In your letter:\n- Mention your background and any relevant experience.\n- Explain why you are interested in this role.\n- Ask about the next steps in the volunteer selection process.",
        opening: "",
      },
      //21
      {
        topic:
          "The public park in your neighborhood is in need of renovation. Write a letter to the city council. In your letter:\n- Describe the current condition of the park.\n- Explain how the community uses the park.\n- Suggest specific improvements that could be made.",
        opening: "",
      },
      //22
      {
        topic:
          "You’ve recently read a book that you found to be extremely valuable and think your friend would benefit from it as well. Write a letter to this friend. In your letter:\n- Describe the book.\n- Explain why you found it valuable.\n- Suggest why they should read it.",
        opening: "",
      },
      //23
      {
        topic:
          "Your child’s school is asking for volunteers to help with a special after-school program. Write a letter to the school. In your letter:\n- Introduce yourself and your child.\n- Express your interest in volunteering.\n- Highlight any relevant skills or experience you have.",
        opening: "",
      },
      //24
      {
        topic:
          "You're submitting an article for a competition in a magazine. Write a cover letter to accompany your submission. In your letter:\n- Introduce yourself briefly.\n- Provide a summary of the article you are submitting.\n- Explain why your article should be considered for the competition.",
        opening: "",
      },
      //25
      {
        topic:
          "You want to install solar panels on your roof and need to seek permission from the local council. Write a letter to the council. In your letter:\n- Explain why you want to install solar panels.\n- Outline your plan for installation.\n- Request the council’s permission or approval.",
        opening: "",
      },
      //26
      {
        topic:
          "You had a memorable meal at a friend’s house and would like to compliment the cook. Write a letter to your friend. In your letter:\n- Thank them for the invitation.\n- Describe what you enjoyed about the meal.\n- Request the recipe for one of the dishes you particularly liked.",
        opening: "",
      },
      //27
      {
        topic:
          "Your company is considering a new uniform and is seeking suggestions from employees. Write a letter to your HR manager. In your letter:\n- Offer your feedback on the current uniform.\n- Suggest improvements or changes for the new uniform design.\n- Explain why these changes would be beneficial.",
        opening: "",
      },
      //28
      {
        topic:
          "You have noticed an error in your latest bank statement. Write a letter to the bank. In your letter:\n- Detail the error you discovered.\n- Explain how this mistake affects your account.\n- Ask the bank to rectify the error and confirm once it has been fixed.",
        opening: "",
      },
      //29
      {
        topic:
          "A recent storm has caused damage to your property and you need to inform your insurance company. Write a letter to the company. In your letter:\n- Describe the damage to your property.\n- Provide specific details about when the storm occurred.\n- Ask about the next steps in the claims process.",
        opening: "",
      },
      //30
      {
        topic:
          "Your neighborhood is considering implementing a recycling program and is gathering resident input. Write a letter to the community board. In your letter:\n- Express your support for the recycling program.\n- Share ideas on how to encourage participation among residents.\n- Offer to help with the program in some way.",
        opening: "",
      },
      //31
      {
        topic:
          "You are planning a local art exhibition and are looking for artists to participate. Write a letter to an artist you admire. In your letter:\n- Introduce yourself and the exhibition.\n- Explain why you think their work would be a good fit.\n- Invite them to participate and provide details on how they can do so.",
        opening: "",
      },
      //32
      {
        topic:
          "Your favorite local radio station is seeking suggestions for new programs. Write a letter to the station manager. In your letter:\n- Mention why you like the radio station.\n- Suggest a new program idea.\n- Explain why listeners would enjoy your suggested program.",
        opening: "",
      },
      //33
      {
        topic:
          "You have a dispute with a neighbor over a boundary line. Write a letter to your neighbor. In your letter:\n- Politely explain the situation from your perspective.\n- Suggest a meeting to discuss the matter in person.\n- Propose possible solutions to resolve the dispute.",
        opening: "",
      },
      //34
      {
        topic:
          "You lost a bag containing important documents in a taxi and need to report it. Write a letter to the taxi company. In your letter:\n- Describe the bag and its contents.\n- Provide details of your journey.\n- Ask for their assistance in locating your lost bag.",
        opening: "",
      },
      //35
      {
        topic:
          "A friend is thinking about starting a small business and has asked for your advice. Write a letter to your friend. In your letter:\n- Share your thoughts on the idea.\n- Offer practical advice based on your own experience.\n- Encourage them and suggest next steps they could take.",
        opening: "",
      },
      //36
      {
        topic:
          "You were impressed by a seminar you attended and want to thank the organizer. Write a letter to the seminar organizer. In your letter:\n- Mention the seminar you attended.\n- Express what you found valuable.\n- Thank them for their efforts in organizing the event.",
        opening: "",
      },
      //37
      {
        topic:
          "You've found a job listing for a position you're interested in but need more information. Write a letter to the HR department of the company. In your letter:\n- Specify the job you’re interested in.\n- Ask specific questions about the job role.\n- Inquire about the application process.",
        opening: "",
      },
      //38
      {
        topic:
          "Your apartment complex is experiencing frequent power outages. Write a letter to the management company. In your letter:\n- Describe the problem and its frequency.\n- Explain how these outages are affecting you.\n- Request that the problem be addressed immediately.",
        opening: "",
      },
      //39
      {
        topic:
          "You are organizing a charity event and need sponsors. Write a letter to a potential sponsor. In your letter:\n- Introduce yourself and the charity event.\n- Explain why their sponsorship would be valuable.\n- Describe what benefits the sponsor would receive.",
        opening: "",
      },
      //40
      {
        topic:
          "After staying at a bed and breakfast, you realized you were overcharged. Write a letter to the owner. In your letter:\n- Describe the situation and the overcharge.\n- Provide details of your stay.\n- Request a refund for the overcharged amount.",
        opening: "",
      },
      //41
      {
        topic:
          "You recently attended a workshop and were particularly impressed by the speaker. Write a letter to the speaker. In your letter:\n- Introduce yourself.\n- Express what you enjoyed about their presentation.\n- Ask for further information on a specific topic they covered.",
        opening: "",
      },
      //42
      {
        topic:
          "Your neighborhood plans to create a community garden and is looking for design ideas. Write a letter to the planning committee. In your letter:\n- Suggest your ideas for the garden layout.\n- Recommend specific plants or features to include.\n- Offer to help with the garden’s creation.",
        opening: "",
      },
      //43
      {
        topic:
          "You borrowed a book from a friend that you accidentally damaged. Write a letter to your friend. In your letter:\n- Explain how the damage occurred.\n- Apologize for the mishap.\n- Suggest how you intend to make amends.",
        opening: "",
      },
      //44
      {
        topic:
          "Your local council has proposed a new road to be built near your home. You have concerns about this. Write a letter to the council. In your letter:\n- Explain your concerns about the new road.\n- Describe how it could affect the local community.\n- Suggest alternative solutions.",
        opening: "",
      },
      //45
      {
        topic:
          "A company you are interested in working for is offering internships. Write a letter to the HR department. In your letter:\n- Introduce yourself and your field of study.\n- Express your interest in the internship and why you think you are a good fit.\n- Inquire about the application process and any additional information needed.",
        opening: "",
      },
      //46
      {
        topic:
          "After dining at a local restaurant, you left your sunglasses behind. Write a letter to the restaurant manager. In your letter:\n- Describe the sunglasses and where you think you left them.\n- Ask if they have been found.\n- Request information on how you can get them back.",
        opening: "",
      },
      //47
      {
        topic:
          "You are planning a special anniversary event and are looking for a venue. Write a letter to a venue that interests you. In your letter:\n- Introduce yourself and the event you are planning.\n- Inquire about the availability of the venue on specific dates.\n- Ask for information regarding pricing and services provided.",
        opening: "",
      },
      //48
      {
        topic:
          "Your local library is seeking suggestions for new books to add to its collection. Write a letter to the librarian. In your letter:\n- Suggest some titles or genres you think should be added.\n- Explain why these additions would benefit the library’s collection.\n- Offer to assist in a book drive to collect donations.",
        opening: "",
      },
      //49
      {
        topic:
          "You recently had a negative experience with a delivery service. Write a letter to the company. In your letter:\n- Describe the service you received.\n- Explain why it was unsatisfactory.\n- Suggest ways the company could improve its service in the future.",
        opening: "",
      },
      //50
      {
        topic:
          "You are interested in a fitness program advertised at a local gym. Write a letter to the gym manager. In your letter:\n- Introduce yourself.\n- Express your interest in the program and ask for more details.\n- Inquire about membership fees and any promotional offers.",
        opening: "",
      },
      //51
      {
        topic:
          "A friend is considering moving to your country and has asked for your advice. Write a letter to your friend. In your letter:\n- Share why you think moving to your country is a good idea.\n- Offer advice on how they can make the transition easier.\n- Propose ways you could help them settle in.",
        opening: "",
      },
      //52
      {
        topic:
          "You've been selected to give a presentation at a conference but need specific equipment for your talk. Write a letter to the organizers. In your letter:\n- Confirm your participation.\n- Detail the equipment you need for your presentation.\n- Ask about the availability of this equipment at the venue.",
        opening: "",
      },
      //53
      {
        topic:
          "You want to nominate a co-worker for an award being offered in your workplace. Write a letter to the nomination committee. In your letter:\n- Introduce yourself and your co-worker.\n- Explain why you believe your co-worker deserves the award.\n- Provide examples of your co-worker’s achievements.",
        opening: "",
      },
      //54
      {
        topic:
          "Your child’s school is seeking donations for upgraded facilities. Write a letter to local businesses asking for sponsorship. In your letter:\n- Introduce yourself and the purpose of the letter.\n- Detail the field trip and its educational value.\n- Explain how businesses can contribute and the benefits of their sponsorship.",
        opening: "",
      },
      //55
      {
        topic:
          "You are planning a community event and need to borrow equipment from a local business. Write a letter to the business owner. In your letter:\n- Describe the event and its purpose.\n- List the equipment you need to borrow.\n- Propose how you will ensure the equipment’s safe return.",
        opening: "",
      },
      //56
      {
        topic:
          "A discrepancy has appeared in your bank account statement. Write a letter to your bank. In your letter:\n- Detail the discrepancy.\n- Provide account details and any relevant transactions.\n- Request an urgent investigation and resolution.",
        opening: "",
      },
      //57
      {
        topic:
          "You would like to participate in a work exchange program you found advertised online. Write a letter to the program coordinator. In your letter:\n- Introduce yourself and your current occupation.\n- Express your interest in the program.\n- Inquire about the application process and any specific requirements.",
        opening: "",
      },
      //58
      {
        topic:
          "A recent storm has caused damage to your neighborhood's communal areas. Write a letter to the local government. In your letter:\n- Describe the damage caused by the storm.\n- Highlight the areas in urgent need of repair.\n- Request swift action to address the damages.",
        opening: "",
      },
      //59
      {
        topic:
          "Your favorite magazine has requested reader submissions for their next issue. Write a letter to the editor. In your letter:\n- Introduce yourself and mention your favorite sections of the magazine.\n- Propose your idea for a submission.\n- Explain why readers would find your contribution interesting.",
        opening: "",
      },
      //60
      {
        topic:
          "You were unable to attend an important family gathering. Write a letter to your family. In your letter:\n- Apologize for your absence.\n- Explain the reason why you could not attend.\n- Express your regret and ask about the event.",
        opening: "",
      },
      //61
      {
        topic:
          "You wish to enroll your child in an after-school program but require additional information. Write a letter to the program organizer. In your letter:\n- Introduce yourself and your child.\n- Express your interest in the program.\n- Request detailed information about program activities, schedule, and fees.",
        opening: "",
      },
      //62
      {
        topic:
          "Interest in a local historical building has waned, and it is now at risk of being forgotten. Write a letter to the editor of your local newspaper. In your letter:\n- Express your concern about the building’s neglect.\n- Highlight the building’s historical importance.\n- Suggest ways to revive public interest and preserve the building.",
        opening: "",
      },
      //63
      {
        topic:
          "You were impressed by a recent talk at your local library and would like to learn more about the topic. Write a letter to the speaker. In your letter:\n- Comment on what you found interesting in the talk.\n- Pose questions you have on the subject.\n- Request additional resources or readings on the topic.",
        opening: "",
      },
      //64
      {
        topic:
          "There has been a misunderstanding regarding a service charge on your last restaurant bill. Write a letter to the restaurant manager. In your letter:\n- Detail the misunderstanding.\n- Explain what you believe the correct charge should be.\n- Request an adjustment to your bill.",
        opening: "",
      },
      //65
      {
        topic:
          "You and your family are considering adopting a pet from the local shelter. Write a letter to the shelter. In your letter:\n- Introduce your family and living situation.\n- Express your interest in adopting a pet.\n- Inquire about the process, including any home visits or fees.",
        opening: "",
      },
      //66
      {
        topic:
          "A new development is being planned in your neighborhood, and you have concerns about its impact. Write a letter to the development company. In your letter:\n- Share your appreciation for the neighborhood’s current state.\n- Express your concerns about the planned development.\n- Request a meeting to discuss the project in more detail.",
        opening: "",
      },
      //67
      {
        topic:
          "You found a wallet on the street and would like to return it to its owner. Write a letter to your local police station. In your letter:\n- Describe where and when you found the wallet.\n- Mention any attempts you’ve made to find the owner.\n- Ask how you can best return the wallet.",
        opening: "",
      },
      //68
      {
        topic:
          "Your friend's pet often wanders into your yard, causing damage. Write a letter to your friend. In your letter:\n- Politely describe the problem and the damage caused.\n- Suggest solutions to prevent future occurrences.\n- Express your hope for a friendly resolution.",
        opening: "",
      },
      //69
      {
        topic:
          "You are studying a language and would like to practice speaking with a native speaker. Write a letter to a language exchange club. In your letter:\n- Introduce yourself and mention the language you are learning.\n- Explain your current level and your goals for improvement.\n- Ask about opportunities to participate in language exchange.",
        opening: "",
      },
      //70
      {
        topic:
          "A friend gifted you a book that you already own. Write a letter to your friend. In your letter:\n- Thank them for the gift.\n- Mention that you already have a copy of the book.\n- Suggest a solution or an alternative.",
        opening: "",
      },
      //71
      {
        topic:
          "You recently lost an item of sentimental value during a stay at a hotel. Write a letter to the hotel manager. In your letter:\n- Describe the item and its sentimental value to you.\n- Detail where and when you believe you lost it.\n- Request assistance in finding and returning the item.",
        opening: "",
      },
      //72
      {
        topic:
          "Your child’s school is seeking donations for upgraded facilities. Write a letter to local businesses asking for sponsorship. In your letter:\n- Explain the current state of the school’s facilities.\n- Detail what improvements are needed and why.\n- Encourage local businesses to contribute and offer incentives for doing so.",
        opening: "",
      },
      //73
      {
        topic:
          "You are planning a community event and need to borrow equipment from a local business. Write a letter to the business owner. In your letter:\n- Describe the event and its purpose.\n- List the equipment you need to borrow.\n- Propose how you will ensure the equipment’s safe return.",
        opening: "",
      },
      //74
      {
        topic:
          "A discrepancy has appeared in your bank account statement. Write a letter to your bank. In your letter:\n- Detail the discrepancy.\n- Provide account details and any relevant transactions.\n- Request an urgent investigation and resolution.",
        opening: "",
      },
      //75
      {
        topic:
          "You would like to participate in a work exchange program you found advertised online. Write a letter to the program coordinator. In your letter:\n- Introduce yourself and your current occupation.\n- Express your interest in the program.\n- Inquire about the application process and any specific requirements.",
        opening: "",
      },
      //76
      {
        topic:
          "A recent storm has caused damage to your neighborhood's communal areas. Write a letter to the local government. In your letter:\n- Describe the damage caused by the storm.\n- Highlight the areas in urgent need of repair.\n- Request swift action to address the damages.",
        opening: "",
      },
      //77
      {
        topic:
          "Your favorite magazine has requested reader submissions for their next issue. Write a letter to the editor. In your letter:\n- Introduce yourself and mention your favorite sections of the magazine.\n- Propose your idea for a submission.\n- Explain why readers would find your contribution interesting.",
        opening: "",
      },
      //78
      {
        topic:
          "You were unable to attend an important family gathering. Write a letter to your family. In your letter:\n- Apologize for your absence.\n- Explain the reason why you could not attend.\n- Express your regret and ask about the event.",
        opening: "",
      },
      //79
      {
        topic:
          "You wish to enroll your child in an after-school program but require additional information. Write a letter to the program organizer. In your letter:\n- Introduce yourself and your child.\n- Express your interest in the program.\n- Request detailed information about program activities, schedule, and fees.",
        opening: "",
      },
      //80
      {
        topic:
          "Interest in a local historical building has waned, and it is now at risk of being forgotten. Write a letter to the editor of your local newspaper. In your letter:\n- Express your concern about the building’s neglect.\n- Highlight the building’s historical importance.\n- Suggest ways to revive public interest and preserve the building.",
        opening: "",
      },
      //81
      {
        topic:
          "You were unable to attend an important family gathering. Write a letter to your family. In your letter:\n- Apologize for your absence.\n- Explain the reason why you could not attend.\n- Express your regret and ask about the event.",
        opening: "",
      },
      //82
      {
        topic:
          "You wish to enroll your child in an after-school program but require additional information. Write a letter to the program organizer. In your letter:\n- Introduce yourself and your child.\n- Express your interest in the program.\n- Request detailed information about program activities, schedule, and fees.",
        opening: "",
      },
      //83
      {
        topic:
          "Interest in a local historical building has waned, and it is now at risk of being forgotten. Write a letter to the editor of your local newspaper. In your letter:\n- Express your concern about the building’s neglect.\n- Highlight the building’s historical importance.\n- Suggest ways to revive public interest and preserve the building.",
        opening: "",
      },
      //84
      {
        topic:
          "You were unable to attend an important family gathering. Write a letter to your family. In your letter:\n- Apologize for your absence.\n- Explain the reason why you could not attend.\n- Express your regret and ask about the event.",
        opening: "",
      },
      //85
      {
        topic:
          "You wish to enroll your child in an after-school program but require additional information. Write a letter to the program organizer. In your letter:\n- Introduce yourself and your child.\n- Express your interest in the program.\n- Request detailed information about program activities, schedule, and fees.",
        opening: "",
      },
      //86
      {
        topic:
          "Interest in a local historical building has waned, and it is now at risk of being forgotten. Write a letter to the editor of your local newspaper. In your letter:\n- Express your concern about the building’s neglect.\n- Highlight the building’s historical importance.\n- Suggest ways to revive public interest and preserve the building.",
        opening: "",
      },
      //87
      {
        topic:
          "You were unable to attend an important family gathering. Write a letter to your family. In your letter:\n- Apologize for your absence.\n- Explain the reason why you could not attend.\n- Express your regret and ask about the event.",
        opening: "",
      },
      //88
      {
        topic:
          "You wish to enroll your child in an after-school program but require additional information. Write a letter to the program organizer. In your letter:\n- Introduce yourself and your child.\n- Express your interest in the program.\n- Request detailed information about program activities, schedule, and fees.",
        opening: "",
      },
      //89
      {
        topic:
          "Interest in a local historical building has waned, and it is now at risk of being forgotten. Write a letter to the editor of your local newspaper. In your letter:\n- Express your concern about the building’s neglect.\n- Highlight the building’s historical importance.\n- Suggest ways to revive public interest and preserve the building.",
        opening: "",
      },
      //90
      {
        topic:
          "You were unable to attend an important family gathering. Write a letter to your family. In your letter:\n- Apologize for your absence.\n- Explain the reason why you could not attend.\n- Express your regret and ask about the event.",
        opening: "",
      },
      //91
      {
        topic:
          "You wish to enroll your child in an after-school program but require additional information. Write a letter to the program organizer. In your letter:\n- Introduce yourself and your child.\n- Express your interest in the program.\n- Request detailed information about program activities, schedule, and fees.",
        opening: "",
      },
      //92
      {
        topic:
          "Interest in a local historical building has waned, and it is now at risk of being forgotten. Write a letter to the editor of your local newspaper. In your letter:\n- Express your concern about the building’s neglect.\n- Highlight the building’s historical importance.\n- Suggest ways to revive public interest and preserve the building.",
        opening: "",
      },
      //93
      {
        topic:
          "You were unable to attend an important family gathering. Write a letter to your family. In your letter:\n- Apologize for your absence.\n- Explain the reason why you could not attend.\n- Express your regret and ask about the event.",
        opening: "",
      },
      //94
      {
        topic:
          "You wish to enroll your child in an after-school program but require additional information. Write a letter to the program organizer. In your letter:\n- Introduce yourself and your child.\n- Express your interest in the program.\n- Request detailed information about program activities, schedule, and fees.",
        opening: "",
      },
      //95
      {
        topic:
          "Interest in a local historical building has waned, and it is now at risk of being forgotten. Write a letter to the editor of your local newspaper. In your letter:\n- Express your concern about the building’s neglect.\n- Highlight the building’s historical importance.\n- Suggest ways to revive public interest and preserve the building.",
        opening: "",
      },
      //96
      {
        topic:
          "You were unable to attend an important family gathering. Write a letter to your family. In your letter:\n- Apologize for your absence.\n- Explain the reason why you could not attend.\n- Express your regret and ask about the event.",
        opening: "",
      },
      //97
      {
        topic:
          "You wish to enroll your child in an after-school program but require additional information. Write a letter to the program organizer. In your letter:\n- Introduce yourself and your child.\n- Express your interest in the program.\n- Request detailed information about program activities, schedule, and fees.",
        opening: "",
      },
      //98
      {
        topic:
          "Interest in a local historical building has waned, and it is now at risk of being forgotten. Write a letter to the editor of your local newspaper. In your letter:\n- Express your concern about the building’s neglect.\n- Highlight the building’s historical importance.\n- Suggest ways to revive public interest and preserve the building.",
        opening: "",
      },
      //99
      {
        topic:
          "You were unable to attend an important family gathering. Write a letter to your family. In your letter:\n- Apologize for your absence.\n- Explain the reason why you could not attend.\n- Express your regret and ask about the event.",
        opening: "",
      },
      //100
      {
        topic:
          "You wish to enroll your child in an after-school program but require additional information. Write a letter to the program organizer. In your letter:\n- Introduce yourself and your child.\n- Express your interest in the program.\n- Request detailed information about program activities, schedule, and fees.",
        opening: "",
      },
    ];

    const randomIndex = Math.floor(Math.random() * papers.length);
    const randomTopic = papers[randomIndex];

    this.props.prompt.prompts[0].value = randomTopic.topic;
    this.props.prompt.prompts[1].value = randomTopic.opening;
  };

  ieltsgeneral2 = () => {
    const storyPrompts = [
      //1
      {
        topic:
          "With the advance of technology, many jobs traditionally performed by humans are being automated. Discuss the advantages and disadvantages of automation in the workplace. What measures should be taken to mitigate any negative impacts?",
        opening: "",
      },
      //2
      {
        topic:
          "The rise of fast food has led to an increase in obesity rates worldwide. Some argue that governments should impose taxes on fast food to encourage healthier eating habits. To what extent do you agree or disagree with this approach? What alternative strategies might be more effective?",
        opening: "",
      },
      //3
      {
        topic:
          "Climate change is one of the greatest challenges facing the world today. Some people believe that individuals can make a significant contribution to combatting climate change, while others think that only large-scale actions by governments and corporations will make a difference. Discuss both views and give your own opinion.",
        opening: "",
      },
      //4
      {
        topic:
          "In many countries, the gap between the rich and the poor is widening. What do you think are the causes of this trend? What solutions can be offered to reduce income inequality?",
        opening: "",
      },
      //5
      {
        topic:
          "The rise of social media has changed the way we communicate and interact with each other. While some people believe social media has positive effects on our social life, others argue it has led to a decline in real-life social interactions. Discuss both sides and give your own opinion.",
        opening: "",
      },
      //6
      {
        topic:
          "Urbanization is a trend in many parts of the world. While living in cities offers numerous advantages such as access to jobs, education, and healthcare, it also comes with challenges like pollution and overcrowding. What measures should urban planners and governments take to address these challenges?",
        opening: "",
      },
      //7
      {
        topic:
          "The aging population is becoming a concern for many countries. Discuss the social and economic effects of an aging population. What policies can be implemented to manage these effects?",
        opening: "",
      },
      //8
      {
        topic:
          "With the increasing pressure of work and life, many people are experiencing stress-related issues. What do you think are the main causes of stress in modern life? How can individuals and society as a whole work to reduce stress levels?",
        opening: "",
      },
      //9
      {
        topic:
          "Education systems around the world are constantly evolving. Some argue that the education system should focus more on preparing students for the workforce, while others believe the emphasis should remain on providing a well-rounded education. Discuss both views and give your opinion.",
        opening: "",
      },
      //10
      {
        topic:
          "The availability and consumption of genetically modified (GM) foods have been increasing. Discuss the potential benefits and risks associated with GM foods. Should governments regulate the production and sale of GM foods more strictly?",
        opening: "",
      },
      //11
      {
        topic:
          "As cities grow, public transportation becomes increasingly important. Discuss the benefits of investing in public transportation and the consequences if cities fail to do so. What are some innovative solutions for urban transportation problems?",
        opening: "",
      },
      //12
      {
        topic:
          "The global population is estimated to reach 9 billion by 2050. What challenges does this pose for food security, and what strategies can be employed to ensure everyone has access to adequate nutrition?",
        opening: "",
      },
      //13
      {
        topic:
          "International tourism has grown rapidly in recent decades. Discuss the impact of tourism on local communities and the environment. How can sustainable tourism be promoted?",
        opening: "",
      },
      //14
      {
        topic:
          "Digital currencies and blockchain technology are gaining popularity. Discuss the potential impacts of these technologies on the global economy and financial systems. What challenges do they pose for regulators?",
        opening: "",
      },
      //15
      {
        topic:
          "Remote work has become more prevalent due to advances in technology and recent global events. Discuss the advantages and disadvantages of remote work for individuals and businesses. How can businesses adapt to effectively manage a remote workforce?",
        opening: "",
      },
      //16
      {
        topic:
          "Mental health awareness has increased, but many people still do not seek help when they need it. Discuss the barriers to accessing mental health services and suggest ways to improve mental health care.",
        opening: "",
      },
      //17
      {
        topic:
          "The demand for renewable energy sources is rising as the world seeks to reduce carbon emissions. Discuss the benefits and challenges of transitioning to renewable energy. What role can governments and individuals play in this transition?",
        opening: "",
      },
      //18
      {
        topic:
          "Plastic pollution has become a significant environmental issue. Discuss the impact of plastic waste on the environment and marine life. What measures can be taken to reduce plastic pollution?",
        opening: "",
      },
      //19
      {
        topic:
          "Cultural heritage sites around the world are at risk due to various factors including climate change and urbanization. Discuss the importance of preserving cultural heritage and suggest ways to protect these sites for future generations.",
        opening: "",
      },
      //20
      {
        topic:
          "The trend of online learning has accelerated, offering new opportunities and challenges for education. Discuss the advantages and disadvantages of online education. How can it be improved to benefit students worldwide?",
        opening: "",
      },
      //21
      {
        topic:
          "Water scarcity affects billions of people globally. Discuss the causes of water scarcity and its impact on society and agriculture. What solutions can be implemented to ensure sustainable water management?",
        opening: "",
      },
      //22
      {
        topic:
          "Youth unemployment remains a significant challenge in many countries. Discuss the causes of youth unemployment and propose strategies to improve job opportunities for young people.",
        opening: "",
      },
      //23
      {
        topic:
          "Space exploration has made significant progress in recent years. Discuss the benefits and drawbacks of investing in space exploration. Should the focus be on exploring space or solving Earth's problems first?",
        opening: "",
      },
      //24
      {
        topic:
          "The media plays a vital role in society, but concerns about media bias and misinformation are growing. Discuss the effects of media bias on public opinion and democracy. How can media integrity be ensured?",
        opening: "",
      },
      //25
      {
        topic:
          "Artificial Intelligence (AI) is transforming various sectors. Discuss the implications of AI for employment and ethical considerations. How can society prepare for the changes brought about by AI?",
        opening: "",
      },
      //26
      {
        topic:
          "The fashion industry is often criticized for its environmental impact and unsustainable practices. Discuss the importance of sustainable fashion and how consumers can make more environmentally friendly choices.",
        opening: "",
      },
      //27
      {
        topic:
          "The use of surveillance technology by governments and corporations is increasing. Discuss the benefits and privacy concerns associated with surveillance technology. How can a balance between security and privacy be maintained?",
        opening: "",
      },
      //28
      {
        topic:
          "Biodiversity is declining at an unprecedented rate due to human activities. Discuss the importance of biodiversity for ecosystems and humans. What actions are needed to protect global biodiversity?",
        opening: "",
      },
      //29
      {
        topic:
          "The balance between work and life is crucial for well-being. Discuss the factors contributing to work-life balance challenges. What strategies can individuals and employers implement to improve work-life balance?",
        opening: "",
      },
      //30
      {
        topic:
          "The relationship between humans and animals has evolved, with growing concern for animal rights and welfare. Discuss the importance of animal welfare and the ethical considerations in using animals for food, research, and entertainment.",
        opening: "",
      },
      //31
      {
        topic:
          "Sports serve as an important cultural and social phenomenon. Discuss the role of major sporting events in promoting international unity and understanding. How can these events also contribute to economic development?",
        opening: "",
      },
      //32
      {
        topic:
          "The rise in consumerism has significant environmental and social impacts. Discuss the reasons for increased consumerism and how it affects society and the planet. What steps can be taken to promote a more sustainable consumer culture?",
        opening: "",
      },
      //33
      {
        topic:
          "Cybersecurity threats are becoming more sophisticated and widespread. Discuss the importance of cybersecurity for individuals and businesses. What measures should be taken to protect sensitive information from cyber-attacks?",
        opening: "",
      },
      //34
      {
        topic:
          "Language learning has been shown to have numerous cognitive and social benefits. Discuss the advantages of learning a foreign language and the best methods for language acquisition in today's globalized world.",
        opening: "",
      },
      //35
      {
        topic:
          "The arts are often seen as non-essential, especially in times of economic hardship. Discuss the value of the arts in society and the impact of the arts on individuals and communities. Should public funding for the arts be a priority?",
        opening: "",
      },
      //36
      {
        topic:
          "The rise of gig economy jobs offers flexibility but also poses new challenges for workers' rights and job security. Discuss the advantages and disadvantages of gig economy jobs for workers and the economy. What measures should be in place to protect gig workers?",
        opening: "",
      },
      //37
      {
        topic:
          "The global increase in single-person households has significant societal implications. Discuss the reasons for this trend and its potential impact on communities and social structures. How can societies adapt to this demographic change?",
        opening: "",
      },
      //38
      {
        topic:
          "Nutrition and diet play a crucial role in health, but access to healthy food options is not equal for everyone. Discuss the factors contributing to food deserts and their impact on public health. What solutions can be implemented to ensure equitable access to nutritious foods?",
        opening: "",
      },
      //39
      {
        topic:
          "Urban green spaces provide numerous benefits but are often overlooked in city planning. Discuss the importance of green spaces for urban residents and the environment. How can cities ensure the integration and preservation of green spaces?",
        opening: "",
      },
      //40
      {
        topic:
          "The concept of universal basic income (UBI) has gained attention as a potential solution to poverty and the challenges of automation. Discuss the advantages and disadvantages of UBI. How could it be implemented effectively?",
        opening: "",
      },
      //41
      {
        topic:
          "As life expectancy increases, many countries face the challenge of supporting an aging workforce. Discuss the potential impacts on the economy and society, and suggest measures to address these challenges.",
        opening: "",
      },
      //42
      {
        topic:
          "The digital divide separates those who have access to technology and the internet from those who do not. Discuss the consequences of the digital divide and propose solutions to bridge this gap.",
        opening: "",
      },
      //43
      {
        topic:
          "Parenting styles vary widely and can significantly impact child development. Discuss the effects of different parenting styles on children. What approach do you think is most beneficial?",
        opening: "",
      },
      //44
      {
        topic:
          "The rise of artificial meat and plant-based diets has sparked debate. Discuss the environmental and health implications of this trend. Can artificial or plant-based meat substitutes adequately replace traditional meat?",
        opening: "",
      },
      //45
      {
        topic:
          "With the increasing prevalence of social media, privacy concerns have escalated. Discuss the importance of privacy in the digital age and how individuals can safeguard their personal information.",
        opening: "",
      },
      //46
      {
        topic:
          "Volunteerism has social, economic, and individual benefits. Discuss the importance of volunteer work and how societies can encourage more people to volunteer.",
        opening: "",
      },
      //47
      {
        topic:
          "The gig economy is changing the nature of work and employment. Discuss the positives and negatives of this shift for workers and the economy.",
        opening: "",
      },
      //48
      {
        topic:
          "As global travel becomes more accessible, concerns about 'overtourism' and its effects on local environments and communities have grown. Discuss strategies to manage and mitigate the impact of overtourism.",
        opening: "",
      },
      //49
      {
        topic:
          "Financial literacy is increasingly recognized as a key skill. Discuss the importance of financial education and how it can be improved across different populations.",
        opening: "",
      },
      //50
      {
        topic:
          "As the world becomes more interconnected, cross-cultural communication has become essential. Discuss the barriers to effective cross-cultural communication and how they can be overcome.",
        opening: "",
      },
      //51
      {
        topic:
          "The trend towards minimalism and decluttering has gained popularity. Discuss the psychological and environmental benefits of living with less.",
        opening: "",
      },
      //52
      {
        topic:
          "Renewable energy technologies are evolving rapidly. Discuss the latest developments in renewable energy and their potential to transform the global energy landscape.",
        opening: "",
      },
      //53
      {
        topic:
          "The fashion industry faces criticism for its environmental impact and ethical practices. Discuss the shift towards ethical and sustainable fashion.",
        opening: "",
      },
      //54
      {
        topic:
          "Crowdfunding and peer-to-peer lending have changed the way individuals and businesses raise money. Discuss the benefits and risks associated with these financing methods.",
        opening: "",
      },
      //55
      {
        topic:
          "The integration of drones into civilian life presents new opportunities and challenges. Discuss the potential uses of drones and the ethical considerations they raise.",
        opening: "",
      },
      //56
      {
        topic:
          "With a growing emphasis on STEM education, some worry that the arts and humanities are being undervalued. Discuss the importance of a balanced education that includes the arts and humanities.",
        opening: "",
      },
      //57
      {
        topic:
          "The phenomenon of 'cancel culture' has sparked debate about freedom of speech and accountability. Discuss the implications of cancel culture for society and individual behavior.",
        opening: "",
      },
      //58
      {
        topic:
          "Community gardens and urban farming have been touted as solutions to food insecurity and sustainability issues. Discuss the benefits and challenges of urban agriculture.",
        opening: "",
      },
      //59
      {
        topic:
          "As more businesses commit to corporate social responsibility, questions arise about the motivations and impacts of these efforts. Discuss the effectiveness of corporate social responsibility initiatives.",
        opening: "",
      },
      //60
      {
        topic:
          "Telemedicine has expanded rapidly, offering new opportunities for healthcare delivery. Discuss the advantages and limitations of telemedicine, and its potential to reshape healthcare.",
        opening: "",
      },
      //61
      {
        topic:
          "The rise of esports has transformed video gaming into a spectator sport. Discuss the growth of esports and its implications for traditional sports and entertainment.",
        opening: "",
      },
      //62
      {
        topic:
          "With increased attention on mental health, workplaces are beginning to implement wellness programs. Discuss the benefits of workplace wellness programs and potential improvements.",
        opening: "",
      },
      //63
      {
        topic:
          "The pursuit of happiness is considered a fundamental human goal. Discuss how different cultures pursue and perceive happiness, and the impact this has on society.",
        opening: "",
      },
      //64
      {
        topic:
          "The debate over net neutrality raises questions about internet access and fairness. Discuss the importance of net neutrality and the potential consequences of its repeal.",
        opening: "",
      },
      //65
      {
        topic:
          "Mass transit systems face various challenges and opportunities in modern cities. Discuss the future of mass transit and how cities can create efficient, sustainable transportation options.",
        opening: "",
      },
      //66
      {
        topic:
          "The phenomenon of global warming requires urgent action. Discuss innovative solutions that individuals, communities, and governments can implement to combat climate change.",
        opening: "",
      },
      //67
      {
        topic:
          "Microplastics have emerged as a significant environmental threat. Discuss the sources of microplastic pollution and strategies for reducing their impact on ecosystems.",
        opening: "",
      },
      //68
      {
        topic:
          "The rise of 'smart cities' promises to revolutionize urban living. Discuss the potential benefits and risks associated with the development of smart cities.",
        opening: "",
      },
      //69
      {
        topic:
          "The relationship between humans and wildlife is increasingly strained by habitat loss and climate change. Discuss strategies to promote coexistence and protect biodiversity.",
        opening: "",
      },
      //70
      {
        topic:
          "The sharing economy has reshaped how people live, work, and interact. Discuss the impact of the sharing economy on traditional business models and consumer behavior.",
        opening: "",
      },
      //71
      {
        topic:
          "The increase in surveillance technologies has sparked debate about security versus privacy. Discuss the balance between using surveillance for public safety and protecting individual privacy.",
        opening: "",
      },
      //72
      {
        topic:
          "The trend of remote and flexible working is reshaping the concept of the workplace. Discuss the long-term implications of this shift for employees and employers.",
        opening: "",
      },
      //73
      {
        topic:
          "With advancements in genetic engineering, ethical debates have intensified. Discuss the ethical considerations of genetic modifications in humans, plants, and animals.",
        opening: "",
      },
      //74
      {
        topic:
          "Fan culture has expanded rapidly with the rise of social media, affecting entertainment and media industries. Discuss the impact of fan culture on content creation and distribution.",
        opening: "",
      },
      //75
      {
        topic:
          "The quest for renewable and clean energy sources is more vital than ever. Discuss the role of nuclear energy in the transition to a sustainable energy future.",
        opening: "",
      },
      //76
      {
        topic:
          "Educational technology has transformed learning opportunities worldwide. Discuss the benefits and challenges of incorporating technology in education.",
        opening: "",
      },
      //77
      {
        topic:
          "Professional sports face scrutiny regarding athletes' mental health. Discuss the pressures professional athletes face and how sports organizations can support their mental well-being.",
        opening: "",
      },
      //78
      {
        topic:
          "Public art initiatives can transform urban landscapes and communities. Discuss the value of public art and its impact on cities.",
        opening: "",
      },
      //79
      {
        topic:
          "The normalization of diplomatic relations between historically adversarial countries presents both opportunities and challenges. Discuss the impact of such diplomatic efforts on global stability.",
        opening: "",
      },
      //80
      {
        topic:
          "Economic sanctions are a common tool for international diplomacy. Discuss the effectiveness and ethical implications of using sanctions as a means to influence other countries.",
        opening: "",
      },
      //81
      {
        topic:
          "Language preservation efforts are crucial for maintaining cultural diversity. Discuss the challenges and strategies for preserving endangered languages.",
        opening: "",
      },
      //82
      {
        topic:
          "The phenomenon of social media influencers has transformed marketing and brand engagement. Discuss the impact of influencers on consumer behavior and marketing strategies.",
        opening: "",
      },
      //83
      {
        topic:
          "The implementation of universal healthcare systems varies globally. Discuss the challenges and benefits of providing universal healthcare.",
        opening: "",
      },
      //84
      {
        topic:
          "Urban wildlife adaptation offers insights into resilience and cohabitation. Discuss the impact of urbanization on wildlife and how cities can support biodiversity.",
        opening: "",
      },
      //85
      {
        topic:
          "Mass shootings and gun control are contentious topics in many countries. Discuss the relationship between gun control policies and the incidence of mass shootings.",
        opening: "",
      },
      //86
      {
        topic:
          "The global increase in antisemitism and other forms of racial and ethnic hatred poses a significant challenge. Discuss strategies to combat racism and promote inclusiveness.",
        opening: "",
      },
      //87
      {
        topic:
          "International space collaborations bring together diverse countries for scientific exploration. Discuss the benefits and challenges of international cooperation in space.",
        opening: "",
      },
      //88
      {
        topic:
          "The rise of autonomous vehicles promises to revolutionize transportation. Discuss the potential benefits and challenges associated with self-driving cars.",
        opening: "",
      },
      //89
      {
        topic:
          "Ocean acidification poses a significant threat to marine ecosystems. Discuss the causes of ocean acidification and its impact on marine life and human societies.",
        opening: "",
      },
      //90
      {
        topic:
          "The intersection of technology and healthcare, such as wearable health devices, offers new opportunities for health management. Discuss the benefits and potential privacy concerns of health tracking technologies.",
        opening: "",
      },
      //91
      {
        topic:
          "Craftsmanship and traditional skills are at risk of decline in the modern world. Discuss the importance of preserving traditional crafts and the challenges faced.",
        opening: "",
      },
      //92
      {
        topic:
          "The trade-off between economic development and environmental sustainability presents a significant challenge. Discuss strategies for achieving a balance between these two goals.",
        opening: "",
      },
      //93
      {
        topic:
          "The trend towards global vegetarianism and veganism is growing. Discuss the environmental, health, and ethical motivations behind this shift and its potential impact.",
        opening: "",
      },
      //94
      {
        topic:
          "Digital literacy is essential in the modern world. Discuss the importance of digital literacy and how it can be promoted across different age groups.",
        opening: "",
      },
      //95
      {
        topic:
          "The phenomenon of urban heat islands affects cities worldwide. Discuss the causes and impacts of urban heat islands and mitigation strategies.",
        opening: "",
      },
      //96
      {
        topic:
          "Collective memory and historical narratives play a crucial role in shaping national identities. Discuss the impact of collective memory on society and politics.",
        opening: "",
      },
      //97
      {
        topic:
          "The global response to pandemics, such as COVID-19, requires cooperation and coordination. Discuss the lessons learned from global health crises and the way forward.",
        opening: "",
      },
      //98
      {
        topic:
          "The role of women in STEM fields has gained increasing attention. Discuss the importance of gender diversity in STEM and strategies to encourage more women to pursue careers in these fields.",
        opening: "",
      },
      //99
      {
        topic:
          "Affordable housing remains a critical issue in many urban areas. Discuss the causes of the affordable housing crisis and potential solutions to ensure housing accessibility for all.",
        opening: "",
      },
      //100
      {
        topic:
          "The global trend towards cashless societies has accelerated. Discuss the benefits and challenges of moving towards cashless transactions and the impact on different sectors of society.",
        opening: "",
      },
      // Additional story prompts can be added here
    ];

    const randomIndex = Math.floor(Math.random() * storyPrompts.length);
    const randomPrompt = storyPrompts[randomIndex];

    this.props.prompt.prompts[0].value = randomPrompt.topic;
    this.props.prompt.prompts[1].value = randomPrompt.opening;
  };

  hkdse2 = () => {
    const storyPrompts = [
      //1
      {
        topic:
          "Hong Kong's culinary scene is a melting pot where East meets West, offering a unique fusion cuisine that is renowned worldwide. Write an article for a global food magazine where you introduce and explain the concept of fusion cuisine in Hong Kong. Highlight specific dishes that embody this cultural blend and argue why tourists should not leave Hong Kong without trying them.",
        opening: "",
      },
      //2
      {
        topic:
          "During the recent global health crisis, educational institutions in Hong Kong rapidly adjusted by incorporating Virtual Reality (VR) technology to facilitate remote learning. As a tech enthusiast and a student, author a blog post discussing the pros and cons of using VR for educational purposes. Reflect on how this technology could shape the future of education in Hong Kong and beyond.",
        opening: "",
      },
      //3
      {
        topic:
          "With the growing concern over environmental issues, Hong Kong has seen an increase in eco-friendly initiatives aimed at making the city greener. Write an essay for a local environmental newsletter about the impact of urban green spaces, such as parks and roof gardens, on the city's ecology and the well-being of its residents. Discuss the balance between urban development and environmental preservation.",
        opening: "",
      },
      //4
      {
        topic:
          "eSports has rapidly gained popularity in Hong Kong, with many viewing it as both a legitimate form of entertainment and a competitive sport. As a young eSports enthusiast, craft an article for your school's digital newsletter explaining what eSports are, why they are becoming so popular in Hong Kong, and how they are influencing the youth culture. Include insights from interviews with local eSports players or fans.",
        opening: "",
      },
      //5
      {
        topic:
          "Hong Kong is known for its efficient public transportation system. Imagine that a new, innovative form of public transportation has been proposed to make the city even more accessible. As a member of a youth urban planning forum, write a proposal outlining this innovative public transportation idea, its potential benefits for residents and tourists, and how it could position Hong Kong as a leader in urban mobility solutions. This proposal will be presented at the next city council meeting for consideration.",
        opening: "",
      },

      //6
      {
        topic:
          "The tradition of dim sum is one of the hallmarks of Cantonese culture, especially in Hong Kong. Write an article for a cultural heritage magazine exploring the history of dim sum, its significance in family and social gatherings, and how this tradition has evolved in the modern era. Offer suggestions on how tourists can experience authentic dim sum in Hong Kong.",
        opening: "",
      },
      //7
      {
        topic:
          "With the rise of digital media, traditional print newspapers in Hong Kong have seen a significant decline in readership. As an aspiring journalist, write an essay discussing the challenges faced by print media in the digital age. Consider the importance of maintaining journalistic integrity and explore ways print media can innovate to remain relevant in today’s digital landscape.",
        opening: "",
      },
      //8
      {
        topic:
          "Hong Kong's skyline is recognized worldwide, featuring an impressive array of skyscrapers. However, this has also led to concerns about overdevelopment and its impact on the city's natural scenery and biodiversity. Write a letter to the editor of a local newspaper expressing your concern about architectural overdevelopment and suggest sustainable urban planning measures that can help preserve Hong Kong's natural beauty while accommodating its growth.",
        opening: "",
      },
      //9
      {
        topic:
          "In response to the global push for sustainable living, many Hong Kong residents have started adopting zero-waste lifestyles. Write a feature article for an environmental blog sharing stories of individuals or communities in Hong Kong who have embraced this lifestyle. Discuss the challenges they face, the impact of their actions on the environment, and how others can be encouraged to make similar lifestyle changes.",
        opening: "",
      },
      //10
      {
        topic:
          "Hong Kong is famous for its vibrant festivals, which attract tourists from all around the world. Imagine you are organizing a new festival in Hong Kong designed to promote cultural understanding and unity. Write a proposal for the government or potential sponsors, detailing the theme of the festival, the activities planned, how it will attract both locals and tourists, and its expected impact on promoting cultural harmony and boosting tourism.",
        opening: "",
      },
      //11
      {
        topic:
          "The Hong Kong government has announced plans to invest in smart city technologies to improve urban living. As a technology enthusiast, you've been invited to contribute an article to a local tech magazine about the potential benefits and drawbacks of living in a smart city. Discuss how technologies like IoT (Internet of Things), AI (Artificial Intelligence), and Big Data could transform everyday life in Hong Kong, and consider the implications for privacy and security.",
        opening: "",
      },
      //12
      {
        topic:
          "Hong Kong's film industry has historically played a significant role in presenting the city's culture to the world, but it has faced various challenges in recent years. Write an opinion piece for a film critic's blog analyzing the current state of the Hong Kong film industry. Discuss how it can rediscover its glory amidst the competition from Hollywood and other Asian film industries, and the role of digital platforms in this revival.",
        opening: "",
      },
      //13
      {
        topic:
          "Community gardens have started sprouting up in various parts of Hong Kong as more people seek to connect with nature and grow their own food. As a community garden organizer, draft an inspirational story for a community newsletter, detailing the journey of setting up a community garden in an urban area. Highlight the challenges faced, the community involvement, the environmental impact, and the personal fulfillment gained from this initiative.",
        opening: "",
      },
      //14
      {
        topic:
          "Given the high-pressure academic and professional environment in Hong Kong, mental health awareness is increasingly important. You are the founder of a mental health awareness campaign at your school. Write a proposal to the school's administration, suggesting practical steps that could be implemented to support students' mental health, including awareness programs, counseling services, and stress management workshops. Explain how these measures can contribute to a healthier, more supportive school environment.",
        opening: "",
      },
      //15
      {
        topic:
          "Hong Kong's unique blend of Eastern and Western influences is evident in its diverse range of artistic expressions. As an artist or art enthusiast, prepare an article for an art magazine that explores how Hong Kong's unique identity is reflected through its contemporary art scene. Discuss specific artists or art movements within the city that embody this cultural fusion, and consider how the art scene contributes to the ongoing dialogue about Hong Kong's identity.",
        opening: "",
      },
      //16
      {
        topic:
          "With the rapid advancement of technology, Hong Kong has seen an increase in the use of drones for various purposes, ranging from delivery services to aerial photography. However, this rise has also sparked debates over privacy and safety. Write an editorial for a technology magazine, presenting your views on how Hong Kong can balance the benefits of drone technology with the need to protect citizens' privacy and ensure public safety.",
        opening: "",
      },

      //17
      {
        topic:
          "Hong Kong's street markets are famous for their vibrant atmosphere and diverse offerings, from food to fashion. However, modern retail developments and online shopping are threatening their existence. As a cultural analyst, write a feature article for a lifestyle magazine, arguing the importance of preserving these markets. Discuss their cultural significance, economic impact, and role in the community, and propose ways to keep them relevant in the digital age.",
        opening: "",
      },

      //18
      {
        topic:
          "In an effort to promote physical fitness and reduce the city's carbon footprint, the Hong Kong government proposes a city-wide cycling initiative. As a public health advocate, write a proposal to be submitted to the City Council outlining how this initiative could be implemented. Detail the potential health benefits, environmental impact, and the necessary infrastructure improvements to make Hong Kong a bike-friendly city.",
        opening: "",
      },

      //19
      {
        topic:
          "The music scene in Hong Kong is as diverse as its population, offering everything from Cantopop to indie rock. As a young music journalist, you've observed a recent resurgence in interest in local music. Write an article for a youth culture magazine, highlighting the recent trends in Hong Kong's music scene. Focus on emerging artists, the role of live music venues, and how social media is transforming the way music is produced and consumed in the city.",
        opening: "",
      },

      //20
      {
        topic:
          "As a response to global environmental concerns, many cities, including Hong Kong, are striving to increase their green space. However, creating new parks and green areas in a densely populated city presents unique challenges. Draft a report for an urban planning conference discussing these challenges specifically for Hong Kong. Propose innovative solutions that integrate urban development with green space creation, such as vertical gardens, rooftop parks, and the revitalization of underused areas.",
        opening: "",
      },

      //21
      {
        topic:
          "With the growth of the gig economy in Hong Kong, many individuals are choosing freelance work over traditional employment for its flexibility and independence. Write an article for a business magazine analyzing the rise of the gig economy in Hong Kong. Discuss its impact on the workforce, the economy, and how it is reshaping traditional job structures.",
        opening: "",
      },

      //22
      {
        topic:
          "As an environmental club leader at your school, you've noticed an alarming increase in plastic waste on campus. Write a proposal to the school administration suggesting practical ways to reduce plastic usage among students and staff. Include initiatives such as recycling programs, banning single-use plastics, and promoting reusable items. Explain the potential environmental benefits and how these efforts can foster a culture of sustainability in school.",
        opening: "",
      },

      //23
      {
        topic:
          "Hong Kong's rich history and diverse culture have made it a melting pot of traditions and languages. As a cultural studies student, write an essay exploring the significance of preserving linguistic diversity in Hong Kong, particularly focusing on Cantonese and other minority languages. Discuss the role language plays in cultural identity and how it contributes to the city's unique heritage.",
        opening: "",
      },

      //24
      {
        topic:
          "The annual Hong Kong Marathon promotes health, fitness, and community spirit but also brings about road closures and transportation disruptions. As a local journalist, write an article for a community news website weighing the pros and cons of hosting such large-scale events in densely populated urban areas. Suggest ways to balance the benefits of these events with the need to minimize inconvenience for residents.",
        opening: "",
      },

      //25
      {
        topic:
          "Smartphone addiction among teenagers in Hong Kong is becoming a concern for parents and educators alike. As a concerned student, write a letter to a technology magazine editor proposing solutions to mitigate dependency on smartphones. Discuss the importance of digital literacy education, the promotion of alternative leisure activities, and how technology companies can play a role in addressing this issue.",
        opening: "",
      },

      //26
      {
        topic:
          "Hong Kong's iconic Peak Tram is not only a mode of transport but also a tourist attraction offering stunning city views. Imagine you are writing a feature for a travel newsletter aimed at international tourists. Highlight the historical significance, the unique experience it offers compared to other city attractions, and tips for visitors (best times to go, what to expect).",
        opening: "",
      },

      //27
      {
        topic:
          "To celebrate the diversity of its student body, your school is hosting an 'International Day', featuring exhibits from different countries. As part of the organizing committee, write a proposal for an exhibit that showcases traditional and modern aspects of Hong Kong culture. Describe what the exhibit will include (e.g., food, music, art), how it will engage students from other cultures, and what you hope they will learn about Hong Kong.",
        opening: "",
      },

      //28
      {
        topic:
          "The integration of Artificial Intelligence (AI) in everyday life is rapidly advancing. As a science student, write an article for your school's science fair newsletter about the potential roles of AI in Hong Kong's future. Discuss both the benefits, such as efficiency and innovation, and the ethical concerns, such as privacy and job displacement.",
        opening: "",
      },

      //29
      {
        topic:
          "Hong Kong's traditional street markets are facing challenges from modern development and online shopping trends. As a local resident concerned about preserving cultural heritage, write an op-ed for a community newspaper advocating for the conservation of these markets. Argue their value not just as tourist attractions but as vital components of the community's social fabric.",
        opening: "",
      },

      //30
      {
        topic:
          "The concept of 'work-life balance' is gaining attention in Hong Kong, with many seeking a healthier lifestyle amidst the hustle of city life. As a wellness blogger, write a post discussing the importance of achieving work-life balance and share strategies that have helped you or others maintain well-being in a fast-paced urban environment. Include tips on time management, stress reduction, and finding personal fulfillment.",
        opening: "",
      },

      //31
      {
        topic:
          "The use of social media by public figures in Hong Kong has sparked debates about privacy and the blurring of personal and public personas. As a media studies student, write an essay analyzing the impact of social media on the public perception of celebrities and politicians in Hong Kong. Consider the benefits of direct communication with the public against the risks and challenges it poses.",
        opening: "",
      },

      //32
      {
        topic:
          "In recent years, there has been a significant rise in the number of public art installations throughout Hong Kong. As an art enthusiast, write a review for a local cultural magazine about a specific installation that captivated you. Describe the artwork, its location, the intended message or theme as you interpret it, and its impact on the community and visitors.",
        opening: "",
      },

      //33
      {
        topic:
          "Hong Kong's education system has often been criticized for its heavy emphasis on rote learning and examination. As a student experiencing this system firsthand, craft a letter to the Education Bureau proposing reforms you believe would create a more holistic and engaging learning environment. Suggest specific changes in curriculum, teaching methods, and assessment criteria.",
        opening: "",
      },

      //34
      {
        topic:
          "Hong Kong's Victoria Harbour is iconic, known for its stunning skyline and as a symbol of the city's heritage and modernity. Imagine you are tasked with organizing an annual festival that celebrates the harbour. Write a proposal document for potential sponsors, outlining the concept of the festival, the range of events, the expected audience, and how the festival aims to enhance appreciation for Victoria Harbour.",
        opening: "",
      },

      //35
      {
        topic:
          "The MTR (Mass Transit Railway) in Hong Kong is renowned for its efficiency and extends its reach to most parts of the city. However, with the population growing and urban areas expanding, new transportation challenges arise. As an urban planning student, write a report suggesting improvements or expansions to the MTR system that could address future transportation needs of Hong Kong.",
        opening: "",
      },

      //36
      {
        topic:
          "Street food in Hong Kong offers an array of flavors and dishes that reflect the city's cultural diversity. As a food blogger, write a post about your journey discovering street food in various neighborhoods. Describe the foods you tried, the stories of the vendors, and why you think street food is an essential part of Hong Kong's culinary scene.",
        opening: "",
      },

      //37
      {
        topic:
          "Hong Kong's Lion Rock spirit symbolizes resilience, unity, and the hard-working nature of its people. As a young resident inspired by this spirit, write an op-ed for a local newspaper reflecting on how this ethos can guide Hong Kong through current social and economic challenges. Provide examples of how the Lion Rock spirit has been manifest in recent events or initiatives in the community.",
        opening: "",
      },

      //38
      {
        topic:
          "The balance between modernity and tradition presents a unique challenge in the conservation of historical sites in Hong Kong. As a volunteer for a heritage preservation NGO, write a newsletter piece advocating for the preservation of a particular site. Discuss the site’s historical, cultural, and architectural significance, the threats it faces from development, and the potential benefits of its conservation to the community and tourism.",
        opening: "",
      },

      //39
      {
        topic:
          "Hong Kong's film industry has begun embracing virtual reality (VR) technology, offering audiences immersive cinematic experiences. As a film studies student, write an essay evaluating the potential impacts of VR on filmmaking and movie-going in Hong Kong. Discuss the opportunities VR technology offers for storytelling, audience engagement, and the challenges it may present to traditional film formats.",
        opening: "",
      },

      //40
      {
        topic:
          "In response to the global environmental crisis, Hong Kong has set ambitious goals to become carbon neutral. As a member of a school environmental club, write a proposal for a green initiative that students and staff can participate in to contribute to these goals. Outline the objectives, planned activities, and the expected impact of the initiative on reducing the school’s carbon footprint.",
        opening: "",
      },
      //41
      {
        topic:
          "The rise of digital payment systems is changing the way transactions are conducted in Hong Kong, from large businesses to street vendors. Write an article for a financial magazine discussing the impact of digital payment technologies on the traditional cash-based economy. Consider aspects such as convenience, security, and the potential for financial inclusion.",
        opening: "",
      },

      //42
      {
        topic:
          "As a film enthusiast, you've noticed that Hong Kong's unique cityscape often serves as an inspiring backdrop for both local and international films. Write a review for a film festival newsletter, focusing on how Hong Kong's architecture and urban environment enhance the storytelling of a specific movie you admire. Discuss the interplay between the city's visual elements and the film's narrative.",
        opening: "",
      },

      //43
      {
        topic:
          "Hong Kong's public libraries are more than just places to read; they serve as community hubs, educational resources, and cultural archives. As a library volunteer, write a proposal to the city council outlining initiatives to modernize these spaces. Suggest incorporating technology, community programs, and design elements that reinvigorate public libraries as essential civic spaces.",
        opening: "",
      },

      //44
      {
        topic:
          "Climate change poses a significant threat to Hong Kong's coastal areas and urban infrastructure. As an environmental science student, draft a research proposal to study the effects of rising sea levels and extreme weather events on Hong Kong. Outline your research questions, methodologies, and the potential implications of your findings for urban planning and resilience strategies.",
        opening: "",
      },

      //45
      {
        topic:
          "Hong Kong's fashion industry has been known for its dynamic and innovative designs. As an aspiring fashion designer, write a column for a lifestyle magazine exploring the fusion of traditional Chinese elements with modern fashion trends. Highlight examples of your work or others' designs that represent this blend, discussing the cultural significance and appeal to both local and international audiences.",
        opening: "",
      },

      //46
      {
        topic:
          "The concept of ‘smart homes’ is gaining traction in Hong Kong, with many residents seeking to integrate technology into their living spaces for convenience, efficiency, and security. Write an article for a technology review website, detailing your experience with setting up a smart home in Hong Kong. Discuss the challenges, benefits, and your recommendations for those interested in making their homes smarter.",
        opening: "",
      },

      //47
      {
        topic:
          "Hong Kong is renowned for its competitive academic environment. This pressure can have a profound impact on students' mental health. As the president of your school's mental health club, write a letter to the principal suggesting specific initiatives that could be implemented to help reduce stress among students. Propose activities, policy changes, and supports that promote mental wellness.",
        opening: "",
      },

      //48
      {
        topic:
          "Hong Kong's culinary scene is acclaimed for its diversity and innovation. Envision you are hosting a cooking show segment that aims to introduce international audiences to lesser-known Cantonese dishes. Write a script for the episode, including an introduction to the dishes, cultural background, cooking demonstrations, and tips for sourcing ingredients abroad.",
        opening: "",
      },

      //49
      {
        topic:
          "In recent years, Hong Kong has seen an increase in initiatives aimed at preserving local wildlife and habitats, despite its urban density. As an environmental activist, prepare a report for a conservation conference that outlines successful conservation projects in Hong Kong. Focus on challenges, strategies employed, community involvement, and the impact on biodiversity.",
        opening: "",
      },

      //50
      {
        topic:
          "With the expansion of eSports in Hong Kong, there's a growing debate about whether eSports should be recognized as an official sport. As an eSports team leader, write an opinion piece for a popular gaming magazine arguing for the recognition of eSports as a sport. Consider aspects such as skill requirements, training intensity, team dynamics, and the positive impact on the gaming community.",
        opening: "",
      },
      //51
      {
        topic:
          "Amidst rapid urbanization, Hong Kong has witnessed a significant loss of its historical architecture. As a local history enthusiast and blogger, craft a post advocating for the preservation of a specific historic building or district. Include a discussion of the site's historical relevance, the cultural loss its demolition would represent, and potential adaptive reuses that could satisfy both preservation and modernization needs.",
        opening: "",
      },

      //52
      {
        topic:
          "In response to the global trend towards sustainability, Hong Kong's fashion scene is starting to see a shift towards eco-friendly practices. As an aspiring eco-conscious fashion designer, write a proposal for a sustainable fashion line that aims to minimize environmental impact while still capturing the vibrant style Hong Kong is known for. Describe the materials, production processes, and marketing strategies you would employ.",
        opening: "",
      },

      //53
      {
        topic:
          "Given the increasing problem of electronic waste (e-waste) in Hong Kong, propose a community-based recycling program aimed at reducing e-waste. As an environmental advocate, your proposal should outline the program's objectives, collection methods, potential challenges, and the anticipated impact on the community and environment.",
        opening: "",
      },

      //54
      {
        topic:
          "Hong Kong's nightlife, famous for its vitality and diversity, has faced significant challenges due to recent global events. Write an article for a lifestyle magazine exploring how nightlife venues have adapted to these challenges. Focus on innovative strategies they've implemented to survive and how these changes might redefine the city's nightlife scene in the long term.",
        opening: "",
      },

      //55
      {
        topic:
          "The integration of art in public spaces has the potential to transform urban environments and community interactions. Propose a public art project for Hong Kong that aims to engage the community, enhance the city's aesthetic appeal, and reflect its unique cultural identity. Your proposal should detail the concept, intended locations, artist collaborations, and how it will benefit both locals and tourists.",
        opening: "",
      },

      //56
      {
        topic:
          "Hong Kong's youth are increasingly interested in entrepreneurship, looking to bring innovative solutions to the city's challenges. As a young entrepreneur, write a business plan outline for a startup that addresses a specific issue in Hong Kong. Include the problem you're solving, your solution, target market, and how your business could contribute positively to the community.",
        opening: "",
      },

      //57
      {
        topic:
          "The concept of 'smart cities' is gaining traction globally, with technologies being utilized to improve city living. Imagine Hong Kong is in the process of further transforming into a smart city. Draft a report discussing potential technologies that can be integrated into various sectors such as transportation, healthcare, and energy, and their potential impact on residents' quality of life.",
        opening: "",
      },

      //58
      {
        topic:
          "Hong Kong's traditional festivals are an integral part of its cultural heritage. Write an article for a cultural magazine that focuses on one lesser-known festival, exploring its origins, traditions, and the role it plays in the community today. Discuss how such traditional events can be preserved and promoted among younger generations.",
        opening: "",
      },

      //59
      {
        topic:
          "The rise in global sea levels poses a threat to coastal cities worldwide, including Hong Kong. As a climate change advocate, propose a city-wide initiative aimed at increasing public awareness and community engagement regarding the impacts of climate change on Hong Kong's coastline. Detail activities, educational components, and collaboration with local NGOs or government bodies.",
        opening: "",
      },

      //60
      {
        topic:
          "The balance between technological advancements and privacy concerns has become a hot topic in Hong Kong, especially with the increase in surveillance and data collection. Write an op-ed piece for a local newspaper expressing your views on how the city should navigate the fine line between leveraging technology for public safety and maintaining citizens' privacy rights. Include suggestions for policies or practices that could satisfy both objectives.",
        opening: "",
      },
      //61
      {
        topic:
          "The concept of 'Tiny Homes' is emerging as a solution to urban housing crises in cities worldwide, including Hong Kong. Write an analytical essay discussing the feasibility, advantages, and challenges of adopting the Tiny Homes movement in Hong Kong's unique urban landscape. Address potential social, economic, and environmental impacts.",
        opening: "",
      },

      //62
      {
        topic:
          "Hong Kong's public art installations not only beautify the city but also provoke thought and conversation among its residents. Propose a new public art initiative for a specific location in Hong Kong, outlining the concept, intended message, and materials to be used. Explain how this installation would engage the community and enhance their connection to the city.",
        opening: "",
      },

      //63
      {
        topic:
          "With an aging population, Hong Kong faces challenges in providing adequate care and services for its elderly citizens. As a social entrepreneur, draft a proposal for a startup that offers innovative solutions to improve the quality of life for the elderly in Hong Kong. Include a business model, potential services, and how technology can be utilized.",
        opening: "",
      },

      //64
      {
        topic:
          "Hong Kong is known for its rapid pace and high-stress lifestyle, which can impact mental health. Write a feature article for a health magazine about the rise of mindfulness and meditation practices in Hong Kong. Explore how individuals and corporations are incorporating these practices to combat stress and improve overall well-being.",
        opening: "",
      },

      //65
      {
        topic:
          "The popularity of hiking in Hong Kong has surged, but this increase in foot traffic has raised concerns about environmental degradation and trail erosion. As an environmental blogger, create a guide promoting responsible hiking practices. Detail the importance of leaving no trace, respecting wildlife, and preserving Hong Kong's natural landscapes for future generations.",
        opening: "",
      },

      //66
      {
        topic:
          "Hong Kong's film industry faces the challenge of balancing commercial success with artistic integrity. As an aspiring filmmaker, write an open letter to the Hong Kong Film Development Council proposing initiatives or policies that would support independent filmmakers in Hong Kong, ensuring the industry's creativity and diversity flourish.",
        opening: "",
      },

      //67
      {
        topic:
          "Urban farming is becoming an innovative solution to food security and sustainability in cities. Imagine you are part of a community project initiating an urban farm in the heart of Hong Kong. Write a proposal to the local government detailing the plan, expected benefits like reducing carbon footprint, and community engagement strategies.",
        opening: "",
      },

      //68
      {
        topic:
          "The incorporation of green buildings is essential for sustainable urban development. As a student architect, draft a design proposal for a green mixed-use building in Hong Kong, incorporating innovative features such as renewable energy sources, vertical gardens, and water recycling systems. Discuss how it could serve as a model for future developments.",
        opening: "",
      },

      //69
      {
        topic:
          "Hong Kong's unique East-meets-West culture is reflected in its diverse culinary scene. As a food critic, write a review of a recent culinary festival that celebrated this fusion cuisine. Describe the atmosphere, the dishes that stood out, and how the event showcased the integration of different culinary traditions.",
        opening: "",
      },

      //70
      {
        topic:
          "The growing trend of digital nomadism opens new possibilities for how and where people work. Envision a co-working space in Hong Kong specifically designed for digital nomads. Write a business proposal including the concept, facilities, and services offered, focusing on how it caters to the needs of remote workers from various sectors and cultures.",
        opening: "",
      },

      //71
      {
        topic:
          "As the global push towards renewable energy gathers momentum, Hong Kong faces unique challenges due to its dense urban environment and limited space. Write an essay discussing innovative strategies that could be employed in Hong Kong to increase the use of renewable energy sources. Consider technologies like solar PV facades on skyscrapers or offshore wind farms near its waters.",
        opening: "",
      },

      //72
      {
        topic:
          "Hong Kong's street art scene has exploded, turning the city into a vibrant canvas that tells stories of its cultural identity and current societal issues. Write an article for an art magazine exploring the significance of street art in Hong Kong, highlighting notable artists and their works, and how this form of expression contributes to dialogues within the community.",
        opening: "",
      },

      //73
      {
        topic:
          "The preservation of intangible cultural heritage, such as Cantonese opera and traditional dragon boat racing, is crucial for maintaining Hong Kong's unique cultural identity amidst modernization. As a cultural enthusiast, write a proposal for a community project aimed at educating the youth about these traditions, detailing the activities, learning outcomes, and potential community impact.",
        opening: "",
      },

      //74
      {
        topic:
          "Hong Kong's geography includes a variety of outlying islands, each with its own unique charm and challenges related to conservation and development. Write a travelogue piece for an eco-tourism website about a recent visit to one of these islands, focusing on how the local community balances tourism and the preservation of their natural and cultural heritage.",
        opening: "",
      },

      //75
      {
        topic:
          "In an effort to combat urban heat islands and improve air quality, Hong Kong has started to develop more green roofs and walls within its dense urban areas. As an environmental science student, write a research paper on the benefits and challenges of implementing these green infrastructures in Hong Kong, including any scientific evidence of their impact on urban environments.",
        opening: "",
      },

      //76
      {
        topic:
          "Mindfulness and traditional Eastern practices are becoming increasingly popular as methods for managing stress in Hong Kong's fast-paced lifestyle. Write a feature article for a wellness magazine on how these practices are being integrated into the modern lifestyle of Hong Kong's residents, including interviews with practitioners and experts in the field.",
        opening: "",
      },

      //77
      {
        topic:
          "With the growing trend of pet ownership in Hong Kong, the city faces challenges in ensuring the welfare of pets in urban apartments and public spaces. As an animal welfare advocate, write a letter to the editor of a local newspaper calling for enhanced regulations and facilities for pets, such as pet-friendly parks and better access to veterinary services.",
        opening: "",
      },

      //78
      {
        topic:
          "The impact of climate change on coastal cities like Hong Kong is a pressing concern, with issues like rising sea levels and increased typhoon activity. Write a position paper for a climate change symposium, arguing for specific policies and infrastructure projects that Hong Kong should prioritize to mitigate and adapt to these environmental changes.",
        opening: "",
      },

      //79
      {
        topic:
          "Hong Kong's education system is renowned for its competitive nature, which some argue prepares students well for university and careers but may also contribute to high levels of stress and anxiety. Write a comparative analysis essay examining the educational approaches of Hong Kong versus another country, exploring the potential psychological impacts on students and the balance between academic excellence and well-being.",
        opening: "",
      },

      //80
      {
        topic:
          "The integration of smart city technologies offers promising solutions to urban challenges in Hong Kong, from traffic management to sustainable energy use. Write a report proposing a smart city initiative for Hong Kong, outlining the technology involved, potential benefits, and how it addresses specific urban challenges. Include considerations for data security and privacy.",
        opening: "",
      },
      //81
      {
        topic:
          "Hong Kong’s youth are increasingly drawn to social entrepreneurship as a means to solve societal issues. Imagine you are organizing a youth summit on social entrepreneurship. Write a proposal detailing the event's purpose, the types of workshops and speakers you plan to include, and how this summit will inspire young people to take action for social change.",
        opening: "",
      },

      //82
      {
        topic:
          "The practice of 'plogging'—jogging while picking up litter—has started gaining momentum in Hong Kong as a fitness trend that also benefits the environment. Write an article for a community health and wellness newsletter explaining the concept of plogging, its dual benefits, and how residents can get involved in making their neighborhoods cleaner and greener.",
        opening: "",
      },

      //83
      {
        topic:
          "Hong Kong's architectural heritage is at risk due to rapid urbanization and redevelopment. As a member of a local heritage preservation society, write a feature article highlighting a specific historical building or district in danger of being demolished. Discuss the significance of this site and argue why preservation should take precedence over new development.",
        opening: "",
      },

      //84
      {
        topic:
          "Virtual and Augmented Reality (VR/AR) technologies are being used in Hong Kong for more than just entertainment; they’re transforming education, healthcare, and even retail. Write an essay discussing the various applications of VR/AR technology across these sectors in Hong Kong, examining both the innovative benefits and any potential drawbacks or ethical concerns.",
        opening: "",
      },

      //85
      {
        topic:
          "Hong Kong is a city defined by its bustling streets and vibrant night markets, each offering a unique look into the city’s diverse culture and culinary specialties. Imagine you are a travel blogger; write a blog post detailing a nocturnal culinary adventure through one of Hong Kong's most famous night markets, the sights, smells, tastes, and interactions that define the experience.",
        opening: "",
      },

      //86
      {
        topic:
          "The recent global focus on sustainability has led to increased interest in upcycling in Hong Kong, with individuals and businesses finding creative ways to give old materials new life. Write an article for an eco-conscious lifestyle magazine about the upcycling movement in Hong Kong, featuring case studies of successful upcycle projects and how they contribute to a more sustainable future.",
        opening: "",
      },

      //87
      {
        topic:
          "Amid growing concerns over mental health, Hong Kong’s schools have started implementing mindfulness programs for students. As a student who has participated in these programs, write a reflective essay on your experience. Discuss the impact of mindfulness practices on your academic performance, stress levels, and overall well-being.",
        opening: "",
      },

      //88
      {
        topic:
          "As Hong Kong aims to establish itself as a regional technology hub, there is a growing need for cybersecurity professionals to protect data and infrastructure. Write a report for a career guidance newsletter explaining the importance of the cybersecurity field in Hong Kong, the skills required to enter this profession, and the potential career paths available.",
        opening: "",
      },

      //89
      {
        topic:
          "Hong Kong's public spaces are often hailed as oases in the bustling city, offering respite and recreation to its residents. Write a proposal to the local government suggesting innovative ideas to enhance Hong Kong’s public spaces, such as eco-friendly playgrounds, community gardens, or art installations, detailing how these improvements could benefit the community.",
        opening: "",
      },

      //90
      {
        topic:
          "The traditional craft of lantern making, once a thriving industry in Hong Kong, is facing decline as younger generations show less interest. As a cultural preservation advocate, write an op-ed piece for a local newspaper on the importance of saving this intangible cultural heritage. Propose ways to revitalise the craft, such as incorporating modern designs or using the craft in community events.",
        opening: "",
      },

      //91
      {
        topic:
          "Hong Kong's iconic junk boats are a symbol of the city's maritime heritage. Write an article for a maritime history magazine exploring the history of junk boats, their significance in Hong Kong's development as a trading port, and the efforts to preserve these vessels in the face of modernization.",
        opening: "",
      },

      //92
      {
        topic:
          "The culture of 'Kai Fong' (neighborhood associations) plays a crucial role in maintaining community spirit and cohesion in Hong Kong's densely populated neighborhoods. As a sociology student, write an essay examining the role of Kai Fong in contemporary Hong Kong society, highlighting how these associations contribute to social welfare, community events, and conflict resolution.",
        opening: "",
      },

      //93
      {
        topic:
          "Hong Kong's fashion scene is a vibrant mix of traditional influences and modern styles. As an aspiring fashion designer, create a mood board and write a concept pitch for a fashion collection that melds Hong Kong's traditional elements with contemporary fashion trends. Describe your vision, the materials you would use, and how your collection pays homage to Hong Kong's cultural diversity.",
        opening: "",
      },

      //94
      {
        topic:
          "The concept of 'Small House Policy' in Hong Kong has sparked debates on land use and rural conservation. Write a detailed analysis for an urban planning journal, discussing the impacts of this policy on Hong Kong's rural landscapes, indigenous villages, and the broader implications for urban development and environmental sustainability.",
        opening: "",
      },

      //95
      {
        topic:
          "With an increasing number of digital nomads, co-working spaces have become hotspots for innovation and collaboration in Hong Kong. As a digital nomad, draft a blog post reviewing the top co-working spaces in Hong Kong, focusing on their facilities, community events, and how they cater to the needs of remote workers from various industries.",
        opening: "",
      },

      //96
      {
        topic:
          "Public interest in Hong Kong's local indie music scene is growing, with many young artists seeking to express their unique identities and views. Write an article for a music magazine featuring an up-and-coming indie band in Hong Kong. Discuss their musical influences, the challenges they face in the local industry, and their contributions to the diversity of Hong Kong's music scene.",
        opening: "",
      },

      //97
      {
        topic:
          "The annual migration of the black-faced spoonbill to Hong Kong's wetlands is a vital event for local biodiversity. As an environmental journalist, write a feature article highlighting the importance of conservation efforts for these wetlands. Include interviews with conservationists and discuss the balance between eco-tourism and protecting these critical habitats.",
        opening: "",
      },

      //98
      {
        topic:
          "Hong Kong's dense urban environment presents unique challenges for pet owners. Propose a comprehensive plan for a 'Pet-Friendly Community Initiative' in a residential area of Hong Kong. Outline strategies for integrating pet-friendly facilities, such as dog parks and pet cafes, and consider policies for responsible pet ownership and animal welfare.",
        opening: "",
      },

      //99
      {
        topic:
          "The 'ding ding' tram, an iconic mode of transportation in Hong Kong, offers a unique perspective of the city. Write a creative narrative from the viewpoint of an elderly Hong Kong resident reflecting on how the city has transformed over the decades, as seen through the windows of the tram. Incorporate historical events, cultural changes, and personal memories intertwined with the tram's journey.",
        opening: "",
      },

      //100
      {
        topic:
          "Hong Kong's street food vendors, known as 'Dai Pai Dongs', are essential to the city's culinary culture but face challenges in modern times. As a cultural anthropologist, write a research paper examining the social and economic significance of Dai Pai Dongs in Hong Kong. Discuss the impact of urban development on these vendors and suggest measures to preserve this culinary heritage.",
        opening: "",
      },
    ];

    const randomIndex = Math.floor(Math.random() * storyPrompts.length);
    const randomPrompt = storyPrompts[randomIndex];

    this.props.prompt.prompts[0].value = randomPrompt.topic;
    this.props.prompt.prompts[1].value = randomPrompt.opening;
  };

  render() {
    const { currentPrompt, disabled, index, location } = this.props;
    const { imageUrl, time, isTimerActive } = this.state;
    let hidden = currentPrompt !== index;

    return (
      <div
        className={`align-bottom bg-white md:rounded-md text-left overflow-hidden transform transition-all sm:align-middle transition hover:shadow-md shadow-2xl focus:shadow-2xl md:mb-8 ${
          hidden ? "hidden" : ""
        }`}
      >
        <div className="px-6 py-6">
          <div className="flex items-center">
            <div
              className={`flex-shrink-0 inline-flex items-center justify-center md:h-12 md:w-12 h-6 w-6 rounded-full bg-${
                !disabled ? "green" : "gray"
              }-300 sm:mx-0 sm:h-10 sm:w-10 ${
                !disabled ? "bg-green-300" : "bg-gray-300"
              } `}
            >
              <CheckIcon
                className={`h-3 w-3 md:h-6 md:w-6 ${
                  !disabled ? "text-green-700" : "text-gray-500"
                } text-${!disabled ? "green-700" : "gray-500"}`}
                aria-hidden="true"
              />
            </div>

            <div className="mt-0 ml-4 text-left">
              <div
                as="h3"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                {this.props.prompt.title || "Entry Text"}
              </div>

              <p className="text-sm text-gray-500">
                {this.props.prompt.desc || "Write a small bit of text"}
              </p>
            </div>
          </div>
          {/* Conditionally render buttons based on the current pathname */}
          <div className="flex justify-center mt-4">
            {/* Existing component structure */}

            {/* Additional button for paths including 'zip' */}
            {location.pathname.includes("zip") && (
              <button
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-full bg-gradient-to-r from-green-400 to-green-400 hover:from-green-400 hover:to-green-600 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={this.downloadFile}
              >
                Download Barcode
              </button>
            )}

            {/* Rest of the component */}
          </div>{" "}
          <div className="flex justify-center mt-4">
            {location.pathname.includes("/ai/writing/ieltsacademic2") && (
              <button
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-full bg-gradient-to-r from-green-400 to-green-400 hover:from-green-400 hover:to-green-600 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={this.ieltsacademic2}
              >
                Generate a Practice Paper
              </button>
            )}
            {location.pathname.includes("/ai/writing/ieltsgeneral2") && (
              <button
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-full bg-gradient-to-r from-green-400 to-green-400 hover:from-green-400 hover:to-green-600 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={this.ieltsgeneral2}
              >
                Generate a Practice Paper
              </button>
            )}
            <div>
              {/* Other component content */}
              {location.pathname.includes("/ai/writing/ieltsacademic1") && (
                <>
                  <button
                    className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-full bg-gradient-to-r from-green-400 to-green-400 hover:from-green-400 hover:to-green-600 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mr-2"
                    onClick={this.generateIeltsAcademic1PracticePaper}
                  >
                    Generate a Practice Paper
                  </button>
                  <button
                    className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-full bg-gradient-to-r from-blue-400 to-blue-600 hover:from-blue-600 hover:to-blue-800 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={this.startTimer}
                  >
                    Start Timer
                  </button>
                  {isTimerActive && (
                    <div className="text-xl font-semibold mt-4">
                      {this.formatTime(time)}
                    </div>
                  )}
                  {imageUrl && (
                    <img src={imageUrl} alt="Practice Paper Image" />
                  )}
                </>
              )}

              {/* Other component content */}
            </div>
            {location.pathname.includes("/ai/writing/ieltsgeneral1") && (
              <button
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-full bg-gradient-to-r from-green-400 to-green-400 hover:from-green-400 hover:to-green-600 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={this.ieltsgeneral1}
              >
                Generate a Practice Paper
              </button>
            )}

            {location.pathname.includes("/ai/writing/hkdsetwo") && (
              <button
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-full bg-gradient-to-r from-green-400 to-green-400 hover:from-green-400 hover:to-green-600 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={this.hkdse2}
              >
                Generate a Practice Paper
              </button>
            )}
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}

// Wrap the EntryPromptBase with withRouter to inject router props
const EntryPrompt = withRouter(EntryPromptBase);

export default EntryPrompt;
