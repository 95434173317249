import { useLocation } from "react-router-dom";

function Header({
  title,
  category,
  desc,
  Icon,
  options,
  currentOption,
  fromColor,
  children,
}) {
  const location = useLocation();

  return (
    <div className="bg-white md:px-8 pt-4 shadow-lg mb-px border-b border-gray-300">
      <div className="container mx-auto px-4 md:px-28 flex items-center">
        {Icon && (
          <div
            className={`mr-4 hidden md:inline-block text-${
              fromColor ? fromColor : "green-500"
            }`}
          >
            <Icon className="h-16 w-16 mb-4 mt-4" />
          </div>
        )}
        <div>
          <div
            className={`text-sm font-medium text-${
              fromColor ? fromColor : "green-500"
            } -mb-1`}
          >
            {category}
          </div>
          <h2 className="text-2xl sm:text-3xl md:text-4xl text-gray-700 flex align-center -mb-1">
            {title}
          </h2>
          <p className="text-base text-gray-500 text-sm md:text-md lg:text-lg md:mt-2  lg:mx-0 ">
            {desc}
          </p>
        </div>

        {/* Conditionally render the buttons based on the path */}
        {location.pathname === "/ai/writing/ocrshakespeare" && (
          <>
            <a
              href="/ai/writing/ocrshakespearehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrshakespearebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrshakespeareword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/ocrshakespearemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrshakespearehand" && (
          <>
            <a
              href="/ai/writing/ocrshakespeare"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrshakespearebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrshakespeareword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrshakespearemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrshakespearebatch" && (
          <>
            <a
              href="/ai/writing/ocrshakespeare"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrshakespearehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrshakespeareword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrshakespearemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrshakespeareword" && (
          <>
            <a
              href="/ai/writing/ocrshakespeare"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrshakespearehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrshakespearebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrshakespearemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrshakespearemulti" && (
          <>
            <a
              href="/ai/writing/ocrshakespeare"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrshakespearehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrshakespearebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrshakespeareword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrshakespeareextract" && (
          <>
            <a
              href="/ai/writing/ocrshakespeareextracthand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrshakespeareextractbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrshakespeareextractword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrshakespeareextractmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrshakespeareextracthand" && (
          <>
            <a
              href="/ai/writing/ocrshakespeareextract"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrshakespeareextractbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrshakespeareextractword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrshakespeareextractmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrshakespeareextractbatch" && (
          <>
            <a
              href="/ai/writing/ocrshakespeareextract"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrshakespeareextracthand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrshakespeareextractword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrshakespeareextractmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrshakespeareextractword" && (
          <>
            <a
              href="/ai/writing/ocrshakespeareextract"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrshakespeareextracthand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrshakespeareextractbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrshakespeareextractmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrshakespeareextractmulti" && (
          <>
            <a
              href="/ai/writing/ocrshakespeareextract"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrshakespeareextracthand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrshakespeareextractbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrshakespeareextractword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrpoetrycompare" && (
          <>
            <a
              href="/ai/writing/ocrpoetrycomparehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrpoetrycomparebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrpoetrycompareword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrpoetrycomparemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrpoetrycomparehand" && (
          <>
            <a
              href="/ai/writing/ocrpoetrycompare"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrpoetrycomparebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrpoetrycompareword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrpoetrycomparemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrpoetrycomparebatch" && (
          <>
            <a
              href="/ai/writing/ocrpoetrycompare"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrpoetrycomparehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrpoetrycompareword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrpoetrycomparemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrpoetrycompareword" && (
          <>
            <a
              href="/ai/writing/ocrpoetrycompare"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrpoetrycomparehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrpoetrycomparebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrpoetrycomparemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrpoetrycomparemulti" && (
          <>
            <a
              href="/ai/writing/ocrpoetrycompare"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrpoetrycomparehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrpoetrycomparebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrpoetrycompareword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrpoetryb" && (
          <>
            <a
              href="/ai/writing/ocrpoetrybhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrpoetrybbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrpoetrybword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrpoetrybmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrpoetrybhand" && (
          <>
            <a
              href="/ai/writing/ocrpoetryb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrpoetrybbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrpoetrybword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrpoetrybmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrpoetrybbatch" && (
          <>
            <a
              href="/ai/writing/ocrpoetryb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrpoetrybhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrpoetrybword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrpoetrybmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrpoetrybword" && (
          <>
            <a
              href="/ai/writing/ocrpoetryb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrpoetrybhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrpoetrybbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrpoetrybmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}
        {location.pathname === "/ai/writing/ocrpoetrybmulti" && (
          <>
            <a
              href="/ai/writing/ocrpoetryb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrpoetrybhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrpoetrybbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrpoetrybword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrmoderna" && (
          <>
            <a
              href="/ai/writing/ocrmodernahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrmodernabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrmodernaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrmodernamulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrmodernahand" && (
          <>
            <a
              href="/ai/writing/ocrmoderna"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrmodernabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrmodernaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrmodernamulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrmodernabatch" && (
          <>
            <a
              href="/ai/writing/ocrmoderna"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrmodernahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrmodernaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrmodernamulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrmodernaword" && (
          <>
            <a
              href="/ai/writing/ocrmoderna"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrmodernahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrmodernabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrmodernamulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}
        {location.pathname === "/ai/writing/ocrmodernamulti" && (
          <>
            <a
              href="/ai/writing/ocrmoderna"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrmodernahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrmodernabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrmodernaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrmodernb" && (
          <>
            <a
              href="/ai/writing/ocrmodernbhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrmodernbbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrmodernbword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrmodernbmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrmodernbhand" && (
          <>
            <a
              href="/ai/writing/ocrmodernb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrmodernbbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrmodernbword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrmodernbmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrmodernbbatch" && (
          <>
            <a
              href="/ai/writing/ocrmodernb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrmodernbhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrmodernbword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrmodernbmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrmodernbword" && (
          <>
            <a
              href="/ai/writing/ocrmodernb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrmodernbhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrmodernbbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrmodernbmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}
        {location.pathname === "/ai/writing/ocrmodernbmulti" && (
          <>
            <a
              href="/ai/writing/ocrmodernb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrmodernbhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrmodernbbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrmodernbword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrnineteentha" && (
          <>
            <a
              href="/ai/writing/ocrnineteenthahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrnineteenthabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrnineteenthaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrnineteenthamulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrnineteenthahand" && (
          <>
            <a
              href="/ai/writing/ocrnineteentha"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrnineteenthabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrnineteenthaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrnineteenthamulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrnineteenthabatch" && (
          <>
            <a
              href="/ai/writing/ocrnineteentha"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrnineteenthahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrnineteenthaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrnineteenthamulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrnineteenthaword" && (
          <>
            <a
              href="/ai/writing/ocrnineteentha"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrnineteenthahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrnineteenthabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrnineteenthamulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}
        {location.pathname === "/ai/writing/ocrnineteenthamulti" && (
          <>
            <a
              href="/ai/writing/ocrnineteentha"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrnineteenthahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrnineteenthabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrnineteenthaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrnineteenthb" && (
          <>
            <a
              href="/ai/writing/ocrnineteenthbhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrnineteenthbbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrnineteenthbword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrnineteenthbmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrnineteenthbhand" && (
          <>
            <a
              href="/ai/writing/ocrnineteenthb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrnineteenthbbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrnineteenthbword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrnineteenthbmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrnineteenthbbatch" && (
          <>
            <a
              href="/ai/writing/ocrnineteenthb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrnineteenthbhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrnineteenthbword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrnineteenthbmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrnineteenthbword" && (
          <>
            <a
              href="/ai/writing/ocrnineteenthb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrnineteenthbhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrnineteenthbbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrnineteenthbmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}
        {location.pathname === "/ai/writing/ocrnineteenthbmulti" && (
          <>
            <a
              href="/ai/writing/ocrnineteenthb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrnineteenthbhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrnineteenthbbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrnineteenthbword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangatwo" && (
          <>
            <a
              href="/ai/writing/ocrlangatwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangatwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangatwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrlangatwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangatwohand" && (
          <>
            <a
              href="/ai/writing/ocrlangatwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangatwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangatwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrlangatwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangatwobatch" && (
          <>
            <a
              href="/ai/writing/ocrlangatwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangatwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangatwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrlangatwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangatwoword" && (
          <>
            <a
              href="/ai/writing/ocrlangatwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangatwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangatwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangatwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangatwomulti" && (
          <>
            <a
              href="/ai/writing/ocrlangatwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangatwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangatwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangatwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangathree" && (
          <>
            <a
              href="/ai/writing/ocrlangathreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangathreebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangathreeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrlangathreemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangathreehand" && (
          <>
            <a
              href="/ai/writing/ocrlangathree"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangathreebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangathreeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrlangathreemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangathreebatch" && (
          <>
            <a
              href="/ai/writing/ocrlangathree"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangathreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangathreeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrlangathreemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangathreeword" && (
          <>
            <a
              href="/ai/writing/ocrlangathree"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangathreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangathreebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangathreemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangathreemulti" && (
          <>
            <a
              href="/ai/writing/ocrlangathree"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangathreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangathreebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangathreeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangafour" && (
          <>
            <a
              href="/ai/writing/ocrlangafourhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangafourbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangafourword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrlangafourmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangafourhand" && (
          <>
            <a
              href="/ai/writing/ocrlangafour"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangafourbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangafourword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrlangafourmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangafourbatch" && (
          <>
            <a
              href="/ai/writing/ocrlangafour"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangafourhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangafourword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrlangafourmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangafourword" && (
          <>
            <a
              href="/ai/writing/ocrlangafour"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangafourhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangafourbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangafourmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangafourmulti" && (
          <>
            <a
              href="/ai/writing/ocrlangafour"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangafourhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangafourbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangafourword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangafive" && (
          <>
            <a
              href="/ai/writing/ocrlangafivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangafivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangafiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrlangafivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangafivehand" && (
          <>
            <a
              href="/ai/writing/ocrlangafive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangafivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangafiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrlangafivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangafivebatch" && (
          <>
            <a
              href="/ai/writing/ocrlangafive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangafivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangafiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrlangafivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangafiveword" && (
          <>
            <a
              href="/ai/writing/ocrlangafive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangafivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangafivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangafivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}
        {location.pathname === "/ai/writing/ocrlangafivemulti" && (
          <>
            <a
              href="/ai/writing/ocrlangafive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangafivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangafivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangafiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangbtwo" && (
          <>
            <a
              href="/ai/writing/ocrlangbtwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangbtwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangbtwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrlangbtwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangbtwohand" && (
          <>
            <a
              href="/ai/writing/ocrlangbtwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangbtwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangbtwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrlangbtwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangbtwobatch" && (
          <>
            <a
              href="/ai/writing/ocrlangbtwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangbtwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangbtwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrlangbtwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangbtwoword" && (
          <>
            <a
              href="/ai/writing/ocrlangbtwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangbtwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangbtwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangbtwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangbtwomulti" && (
          <>
            <a
              href="/ai/writing/ocrlangbtwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangbtwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangbtwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangbtwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangbthree" && (
          <>
            <a
              href="/ai/writing/ocrlangbthreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangbthreebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangbthreeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrlangbthreemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangbthreehand" && (
          <>
            <a
              href="/ai/writing/ocrlangbthree"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangbthreebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangbthreeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrlangbthreemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangbthreebatch" && (
          <>
            <a
              href="/ai/writing/ocrlangbthree"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangbthreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangbthreeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrlangbthreemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangbthreeword" && (
          <>
            <a
              href="/ai/writing/ocrlangbthree"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangbthreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangbthreebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangbthreemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangbthreemulti" && (
          <>
            <a
              href="/ai/writing/ocrlangbthree"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangbthreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangbthreebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangbthreeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangbfour" && (
          <>
            <a
              href="/ai/writing/ocrlangbfourhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangbfourbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangbfourword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrlangbfourmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangbfourhand" && (
          <>
            <a
              href="/ai/writing/ocrlangbfour"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangbfourbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangbfourword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrlangbfourmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangbfourbatch" && (
          <>
            <a
              href="/ai/writing/ocrlangbfour"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangbfourhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangbfourword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrlangbfourmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangbfourword" && (
          <>
            <a
              href="/ai/writing/ocrlangbfour"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangbfourhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangbfourbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangbfourmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}
        {location.pathname === "/ai/writing/ocrlangbfourmulti" && (
          <>
            <a
              href="/ai/writing/ocrlangbfour"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangbfourhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangbfourbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangbfourword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangbfive" && (
          <>
            <a
              href="/ai/writing/ocrlangbfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangbfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangbfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrlangbfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangbfivehand" && (
          <>
            <a
              href="/ai/writing/ocrlangbfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangbfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangbfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrlangbfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangbfivebatch" && (
          <>
            <a
              href="/ai/writing/ocrlangbfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangbfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangbfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrlangbfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrlangbfiveword" && (
          <>
            <a
              href="/ai/writing/ocrlangbfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangbfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangbfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangbfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}
        {location.pathname === "/ai/writing/ocrlangbfivemulti" && (
          <>
            <a
              href="/ai/writing/ocrlangbfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrlangbfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrlangbfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrlangbfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aocroneonea" && (
          <>
            <a
              href="/ai/writing/aocroneoneahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aocroneoneabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aocroneoneaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aocroneoneahand" && (
          <>
            <a
              href="/ai/writing/aocroneonea"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aocroneoneabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aocroneoneaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aocroneoneabatch" && (
          <>
            <a
              href="/ai/writing/aocroneonea"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aocroneoneahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aocroneoneaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aocroneoneaword" && (
          <>
            <a
              href="/ai/writing/aocroneonea"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aocroneoneahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aocroneoneabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aocroneoneb" && (
          <>
            <a
              href="/ai/writing/aocroneonebhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aocroneonebbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aocroneonebword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aocroneonebhand" && (
          <>
            <a
              href="/ai/writing/aocroneoneb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aocroneonebbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aocroneonebword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aocroneonebbatch" && (
          <>
            <a
              href="/ai/writing/aocroneoneb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aocroneonebhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aocroneonebword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aocroneonebword" && (
          <>
            <a
              href="/ai/writing/aocroneoneb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aocroneonebhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aocroneonebbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aocroneb" && (
          <>
            <a
              href="/ai/writing/aocronebhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aocronebbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aocronebword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aocronebhand" && (
          <>
            <a
              href="/ai/writing/aocroneb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aocronebbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aocronebword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aocronebbatch" && (
          <>
            <a
              href="/ai/writing/aocroneb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aocronebhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aocronebword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aocronebword" && (
          <>
            <a
              href="/ai/writing/aocroneb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aocronebhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aocronebbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aocrtwoone" && (
          <>
            <a
              href="/ai/writing/aocrtwoonehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aocrtwoonebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aocrtwooneword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aocrtwoonehand" && (
          <>
            <a
              href="/ai/writing/aocrtwoone"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aocrtwoonebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aocrtwooneword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aocrtwoonebatch" && (
          <>
            <a
              href="/ai/writing/aocrtwoone"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aocrtwoonehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aocrtwooneword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aocrtwooneword" && (
          <>
            <a
              href="/ai/writing/aocrtwoone"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aocrtwoonehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aaocrtwoonebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aocrtwotwo" && (
          <>
            <a
              href="/ai/writing/aocrtwotwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aocrtwotwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aocrtwotwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aocrtwotwohand" && (
          <>
            <a
              href="/ai/writing/aocrtwotwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aocrtwotwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aocrtwotwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aocrtwotwobatch" && (
          <>
            <a
              href="/ai/writing/aocrtwotwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aocrtwotwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aocrtwotwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aocrtwotwoword" && (
          <>
            <a
              href="/ai/writing/aocrtwotwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aocrtwotwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aocrtwotwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/modernplays" && (
          <>
            <a
              href="/ai/writing/modernplayshand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/modernplaysbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/modernplaysword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/modernplaysmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/modernplayszip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/modernplayshand" && (
          <>
            <a
              href="/ai/writing/modernplays"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/modernplaysbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/modernplaysword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/modernplaysmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/modernplayszip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/modernplaysbatch" && (
          <>
            <a
              href="/ai/writing/modernplays"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/modernplayshand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/modernplaysword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/modernplaysmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/modernplayszip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/modernplaysword" && (
          <>
            <a
              href="/ai/writing/modernplays"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/modernplayshand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/modernplaysbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/modernplaysmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/modernplayszip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/modernplaysmulti" && (
          <>
            <a
              href="/ai/writing/modernplays"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/modernplayshand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/modernplaysbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/modernplaysword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/modernplayszip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/modernplayszip" && (
          <>
            <a
              href="/ai/writing/modernplays"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/modernplayshand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/modernplaysbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/modernplaysword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/modernplaysmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/modernnovels" && (
          <>
            <a
              href="/ai/writing/modernnovelshand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/modernnovelsbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/modernnovelsword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/modernnovelsmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/modernnovelszip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/modernnovelshand" && (
          <>
            <a
              href="/ai/writing/modernnovels"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/modernnovelsbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/modernnovelsword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/modernnovelsmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/modernnovelszip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/modernnovelsbatch" && (
          <>
            <a
              href="/ai/writing/modernnovels"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/modernnovelshand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/modernnovelsword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/modernnovelsmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/modernnovelszip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/modernnovelsword" && (
          <>
            <a
              href="/ai/writing/modernnovels"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/modernnovelshand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/modernnovelsbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/modernnovelsmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/modernnovelszip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/modernnovelsmulti" && (
          <>
            <a
              href="/ai/writing/modernnovels"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/modernnovelshand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/modernnovelsbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/modernnovelsword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/modernnovelszip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/modernnovelszip" && (
          <>
            <a
              href="/ai/writing/modernnovels"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/modernnovelshand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/modernnovelsbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/modernnovelsword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/modernnovelsmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/shakespearemarking" && (
          <>
            <a
              href="/ai/writing/shakespearemarkinghand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/shakespearemarkingbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/shakespearemarkingword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/shakespearemarkingmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/shakespearemarkingzip"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/shakespearemarkinghand" && (
          <>
            <a
              href="/ai/writing/shakespearemarking"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/shakespearemarkingbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/shakespearemarkingword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/shakespearemarkingmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/shakespearemarkingzip"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/shakespearemarkingbatch" && (
          <>
            <a
              href="/ai/writing/shakespearemarking"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/shakespearemarkinghand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/shakespearemarkingword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/shakespearemarkingmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/shakespearemarkingzip"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/shakespearemarkingword" && (
          <>
            <a
              href="/ai/writing/shakespearemarking"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/shakespearemarkinghand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/shakespearemarkingbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/shakespearemarkingmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/shakespearemarkingzip"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/shakespearemarkingmulti" && (
          <>
            <a
              href="/ai/writing/shakespearemarking"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/shakespearemarkinghand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/shakespearemarkingbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/shakespearemarkingword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/shakespearemarkingzip"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/shakespearemarkingzip" && (
          <>
            <a
              href="/ai/writing/shakespearemarking"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/shakespearemarkinghand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/shakespearemarkingbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/shakespearemarkingword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/shakespearemarkingmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/nineteenthcentury" && (
          <>
            <a
              href="/ai/writing/nineteenthcenturyhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/nineteenthcenturybatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/nineteenthcenturyword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/nineteenthcenturymulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/nineteenthcenturyzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/nineteenthcenturyhand" && (
          <>
            <a
              href="/ai/writing/nineteenthcentury"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/nineteenthcenturybatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/nineteenthcenturyword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/nineteenthcenturymulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/nineteenthcenturyzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/nineteenthcenturybatch" && (
          <>
            <a
              href="/ai/writing/nineteenthcentury"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/nineteenthcenturyhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/nineteenthcenturyword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/nineteenthcenturymulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/nineteenthcenturyzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/nineteenthcenturyword" && (
          <>
            <a
              href="/ai/writing/nineteenthcentury"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/nineteenthcenturyhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/nineteenthcenturybatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/nineteenthcenturymulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/nineteenthcenturyzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/nineteenthcenturymulti" && (
          <>
            <a
              href="/ai/writing/nineteenthcentury"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/nineteenthcenturyhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/nineteenthcenturybatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/nineteenthcenturyword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/nineteenthcenturyzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/nineteenthcenturyzip" && (
          <>
            <a
              href="/ai/writing/nineteenthcentury"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/nineteenthcenturyhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/nineteenthcenturybatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/nineteenthcenturyword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/nineteenthcenturymulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/powerandconflict" && (
          <>
            <a
              href="/ai/writing/powerandconflicthand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/powerandconflictbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/powerandconflicttword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/powerandconflictmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/powerandconflictzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/powerandconflicthand" && (
          <>
            <a
              href="/ai/writing/powerandconflict"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/powerandconflictbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/powerandconflicttword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/powerandconflictmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/powerandconflictzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/powerandconflictbatch" && (
          <>
            <a
              href="/ai/writing/powerandconflict"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/powerandconflicthand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/powerandconflicttword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/powerandconflictmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/powerandconflictzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/powerandconflicttword" && (
          <>
            <a
              href="/ai/writing/powerandconflict"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/powerandconflicthand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/powerandconflictbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/powerandconflictmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/powerandconflictzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/powerandconflictmulti" && (
          <>
            <a
              href="/ai/writing/powerandconflict"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/powerandconflicthand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/powerandconflictbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/powerandconflicttword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/powerandconflictzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/powerandconflictzip" && (
          <>
            <a
              href="/ai/writing/powerandconflict"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/powerandconflicthand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/powerandconflictbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/powerandconflicttword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/powerandconflictmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/loveandrelationships" && (
          <>
            <a
              href="/ai/writing/loveandrelationshipshand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/loveandrelationshipsbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/loveandrelationshipsword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/loveandrelationshipsmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/loveandrelationshipzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/loveandrelationshipshand" && (
          <>
            <a
              href="/ai/writing/loveandrelationships"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/loveandrelationshipsbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/loveandrelationshipsword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/loveandrelationshipsmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/loveandrelationshipzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/loveandrelationshipsbatch" && (
          <>
            <a
              href="/ai/writing/loveandrelationships"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/loveandrelationshipshand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/loveandrelationshipsword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/loveandrelationshipsmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/loveandrelationshipzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/loveandrelationshipsword" && (
          <>
            <a
              href="/ai/writing/loveandrelationships"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/loveandrelationshipshand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/loveandrelationshipsbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/loveandrelationshipsmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/loveandrelationshipzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/loveandrelationshipsmulti" && (
          <>
            <a
              href="/ai/writing/loveandrelationships"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/loveandrelationshipshand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/loveandrelationshipsbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/loveandrelationshipsword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/loveandrelationshipzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/loveandrelationshipzip" && (
          <>
            <a
              href="/ai/writing/loveandrelationships"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/loveandrelationshipshand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/loveandrelationshipsbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/loveandrelationshipsword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/loveandrelationshipsmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/worldsandlives" && (
          <>
            <a
              href="/ai/writing/worldsandliveshand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/worldsandlivesbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/worldsandlivesword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/worldsandlivesmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/worldsandliveszip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/worldsandliveshand" && (
          <>
            <a
              href="/ai/writing/worldsandlives"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/worldsandlivesbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/worldsandlivesword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/worldsandlivesmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/worldsandliveszip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/worldsandlivesbatch" && (
          <>
            <a
              href="/ai/writing/worldsandlives"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/worldsandliveshand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/worldsandlivesword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/worldsandlivesmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/worldsandliveszip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/worldsandlivesword" && (
          <>
            <a
              href="/ai/writing/worldsandlives"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/worldsandliveshand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/worldsandlivesbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/worldsandlivesmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/worldsandliveszip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/worldsandlivesmulti" && (
          <>
            <a
              href="/ai/writing/worldsandlives"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/worldsandliveshand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/worldsandlivesbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/worldsandlivesword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/worldsandliveszip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/worldsandliveszip" && (
          <>
            <a
              href="/ai/writing/worldsandlives"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/worldsandliveshand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/worldsandlivesbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/worldsandlivesword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/worldsandlivesmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/unseenpoetry" && (
          <>
            <a
              href="/ai/writing/unseenpoetryhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/unseenpoetrybatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/unseenpoetryword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/unseenpoetrymulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/unseenpoetryzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/unseenpoetryhand" && (
          <>
            <a
              href="/ai/writing/unseenpoetry"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/unseenpoetrybatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/unseenpoetryword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/unseenpoetrymulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/unseenpoetryzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/unseenpoetrybatch" && (
          <>
            <a
              href="/ai/writing/unseenpoetry"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/unseenpoetryhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/unseenpoetryword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/unseenpoetrymulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/unseenpoetryzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/unseenpoetryword" && (
          <>
            <a
              href="/ai/writing/unseenpoetry"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/unseenpoetryhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/unseenpoetrybatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/unseenpoetrymulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/unseenpoetryzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/unseenpoetrymulti" && (
          <>
            <a
              href="/ai/writing/unseenpoetry"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/unseenpoetryhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/unseenpoetrybatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/unseenpoetryword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/unseenpoetryzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/unseenpoetryzip" && (
          <>
            <a
              href="/ai/writing/unseenpoetry"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/unseenpoetryhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/unseenpoetrybatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/unseenpoetryword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/unseenpoetrymulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguageonetwo" && (
          <>
            <a
              href="/ai/writing/englishlanguageonetwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguageonetwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguageonetwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguageonetwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguageonetwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguageonetwohand" && (
          <>
            <a
              href="/ai/writing/englishlanguageonetwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguageonetwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguageonetwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguageonetwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguageonetwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguageonetwobatch" && (
          <>
            <a
              href="/ai/writing/englishlanguageonetwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguageonetwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguageonetwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguageonetwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguageonetwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguageonetwoword" && (
          <>
            <a
              href="/ai/writing/englishlanguageonetwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguageonetwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguageonetwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguageonetwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguageonetwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguageonetwomulti" && (
          <>
            <a
              href="/ai/writing/englishlanguageonetwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguageonetwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguageonetwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguageonetwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguageonetwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguageonetwozip" && (
          <>
            <a
              href="/ai/writing/englishlanguageonetwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguageonetwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguageonetwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguageonetwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguageonetwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguageonethree" && (
          <>
            <a
              href="/ai/writing/englishlanguageonethreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguageonethreebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguageonethreeword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguageonethreemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguageonethreezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguageonethreehand" && (
          <>
            <a
              href="/ai/writing/englishlanguageonethree"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguageonethreebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguageonethreeword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguageonethreemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguageonethreezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguageonethreebatch" && (
          <>
            <a
              href="/ai/writing/englishlanguageonethree"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguageonethreehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguageonethreeword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguageonethreemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguageonethreezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguageonethreeword" && (
          <>
            <a
              href="/ai/writing/englishlanguageonethree"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguageonethreehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguageonethreebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguageonethreemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguageonethreezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguageonethreemulti" && (
          <>
            <a
              href="/ai/writing/englishlanguageonethree"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguageonethreehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguageonethreebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguageonethreeword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguageonethreezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguageonethreezip" && (
          <>
            <a
              href="/ai/writing/englishlanguageonethree"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguageonethreehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguageonethreebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguageonethreeword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguageonethreemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguageonefour" && (
          <>
            <a
              href="/ai/writing/englishlanguageonefourhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguageonefourbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguageonefourword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguageonefourmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguageonefourzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguageonefourhand" && (
          <>
            <a
              href="/ai/writing/englishlanguageonefour"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguageonefourbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguageonefourword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguageonefourmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguageonefourzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguageonefourbatch" && (
          <>
            <a
              href="/ai/writing/englishlanguageonefour"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguageonefourhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguageonefourword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguageonefourmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguageonefourzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguageonefourword" && (
          <>
            <a
              href="/ai/writing/englishlanguageonefour"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguageonefourhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguageonefourbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguageonefourmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguageonefourzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguageonefourmulti" && (
          <>
            <a
              href="/ai/writing/englishlanguageonefour"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguageonefourhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguageonefourbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguageonefourword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguageonefourzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguageonefourzip" && (
          <>
            <a
              href="/ai/writing/englishlanguageonefour"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguageonefourhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguageonefourbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguageonefourword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguageonefourmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguageonefive" && (
          <>
            <a
              href="/ai/writing/englishlanguageonefivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguageonefivebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguageonefiveword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguageonefivemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguageonefivezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguageonefivehand" && (
          <>
            <a
              href="/ai/writing/englishlanguageonefive"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguageonefivebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguageonefiveword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguageonefivemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguageonefivezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguageonefivebatch" && (
          <>
            <a
              href="/ai/writing/englishlanguageonefive"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguageonefivehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguageonefiveword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguageonefivemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguageonefivezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguageonefiveword" && (
          <>
            <a
              href="/ai/writing/englishlanguageonefive"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguageonefivehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguageonefivebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguageonefivemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguageonefivezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguageonefivemulti" && (
          <>
            <a
              href="/ai/writing/englishlanguageonefive"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguageonefivehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguageonefivebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguageonefiveword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguageonefivezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguageonefivezip" && (
          <>
            <a
              href="/ai/writing/englishlanguageonefive"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguageonefivehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguageonefivebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguageonefiveword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguageonefivemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguagetwotwo" && (
          <>
            <a
              href="/ai/writing/englishlanguagetwotwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguagetwotwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguagetwotwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguagetwotwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguagetwotwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguagetwotwohand" && (
          <>
            <a
              href="/ai/writing/englishlanguagetwotwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguagetwotwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguagetwotwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguagetwotwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguagetwotwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguagetwotwobatch" && (
          <>
            <a
              href="/ai/writing/englishlanguagetwotwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguagetwotwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguagetwotwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguagetwotwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguagetwotwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguagetwotwoword" && (
          <>
            <a
              href="/ai/writing/englishlanguagetwotwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguagetwotwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguagetwotwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguagetwotwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguagetwotwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguagetwotwomulti" && (
          <>
            <a
              href="/ai/writing/englishlanguagetwotwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguagetwotwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguagetwotwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguagetwotwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguagetwotwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguagetwotwozip" && (
          <>
            <a
              href="/ai/writing/englishlanguagetwotwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguagetwotwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguagetwotwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguagetwotwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguagetwotwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguagetwothree" && (
          <>
            <a
              href="/ai/writing/englishlanguagetwothreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguagetwothreebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguagetwothreeword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguagetwothreemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguagetwothreezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguagetwothreehand" && (
          <>
            <a
              href="/ai/writing/englishlanguagetwothree"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguagetwothreebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguagetwothreeword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguagetwothreemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguagetwothreezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguagetwothreebatch" && (
          <>
            <a
              href="/ai/writing/englishlanguagetwothree"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguagetwothreehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguagetwothreeword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguagetwothreemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguagetwothreezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguagetwothreeword" && (
          <>
            <a
              href="/ai/writing/englishlanguagetwothree"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguagetwothreehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguagetwothreebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguagetwothreemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguagetwothreezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguagetwothreemulti" && (
          <>
            <a
              href="/ai/writing/englishlanguagetwothree"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguagetwothreehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguagetwothreebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguagetwothreeword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguagetwothreezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguagetwothreezip" && (
          <>
            <a
              href="/ai/writing/englishlanguagetwothree"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguagetwothreehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguagetwothreebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguagetwothreeword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguagetwothreemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguagetwofour" && (
          <>
            <a
              href="/ai/writing/englishlanguagetwofourhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguagetwofourbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguagetwofourword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguagetwofourmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguagetwofourzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguagetwofourhand" && (
          <>
            <a
              href="/ai/writing/englishlanguagetwofour"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguagetwofourbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguagetwofourword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguagetwofourmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguagetwofourzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguagetwofourbatch" && (
          <>
            <a
              href="/ai/writing/englishlanguagetwofour"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguagetwofourhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguagetwofourword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguagetwofourmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguagetwofourzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguagetwofourword" && (
          <>
            <a
              href="/ai/writing/englishlanguagetwofour"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguagetwofourhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguagetwofourbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguagetwofourmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguagetwofourzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguagetwofourmulti" && (
          <>
            <a
              href="/ai/writing/englishlanguagetwofour"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguagetwofourhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguagetwofourbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguagetwofourword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguagetwofourzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguagetwofourzip" && (
          <>
            <a
              href="/ai/writing/englishlanguagetwofour"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguagetwofourhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguagetwofourbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguagetwofourword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguagetwofourmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguagetwofive" && (
          <>
            <a
              href="/ai/writing/englishlanguagetwofivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguagetwofivebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguagetwofiveword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguagetwofivemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguagetwofivezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguagetwofivehand" && (
          <>
            <a
              href="/ai/writing/englishlanguagetwofive"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguagetwofivebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguagetwofiveword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguagetwofivemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguagetwofivezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguagetwofivebatch" && (
          <>
            <a
              href="/ai/writing/englishlanguagetwofive"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguagetwofivehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguagetwofiveword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguagetwofivemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguagetwofivezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguagetwofiveword" && (
          <>
            <a
              href="/ai/writing/englishlanguagetwofive"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguagetwofivehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguagetwofivebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguagetwofivemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/englishlanguagetwofivezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguagetwofivemulti" && (
          <>
            <a
              href="/ai/writing/englishlanguagetwofive"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguagetwofivehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguagetwofivebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguagetwofiveword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguagetwofivezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/englishlanguagetwofivezip" && (
          <>
            <a
              href="/ai/writing/englishlanguagetwofive"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/englishlanguagetwofivehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/englishlanguagetwofivebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/englishlanguagetwofiveword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/englishlanguagetwofivemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aeduqascomponetwenty" && (
          <>
            <a
              href="/ai/writing/aeduqascomponetwentyhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aeduqascomponetwentybatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aeduqascomponetwentyword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aeduqascomponetwentyhand" && (
          <>
            <a
              href="/ai/writing/aeduqascomponetwenty"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aeduqascomponetwentybatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aeduqascomponetwentyword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aeduqascomponetwentybatch" && (
          <>
            <a
              href="/ai/writing/aeduqascomponetwenty"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aeduqascomponetwentyhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aeduqascomponetwentyword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aeduqascomponetwentyword" && (
          <>
            <a
              href="/ai/writing/aeduqascomponetwenty"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aeduqascomponetwentyhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aeduqascomponetwentybatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aeduqascomponeforty" && (
          <>
            <a
              href="/ai/writing/aeduqascomponefortyhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aeduqascomponefortybatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aeduqascomponefortyword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aeduqascomponefortyhand" && (
          <>
            <a
              href="/ai/writing/aeduqascomponeforty"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aeduqascomponefortybatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aeduqascomponefortyword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aeduqascomponefortybatch" && (
          <>
            <a
              href="/ai/writing/aeduqascomponeforty"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aeduqascomponefortyhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aeduqascomponefortyword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aeduqascomponefortyword" && (
          <>
            <a
              href="/ai/writing/aeduqascomponeforty"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aeduqascomponefortyhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aeduqascomponefortybatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aeduqascomponesixty" && (
          <>
            <a
              href="/ai/writing/aeduqascomponesixtyhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aeduqascomponesixtybatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aeduqascomponesixtyword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aeduqascomponesixtyhand" && (
          <>
            <a
              href="/ai/writing/aeduqascomponesixty"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aeduqascomponesixtybatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aeduqascomponesixtyword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aeduqascomponesixtybatch" && (
          <>
            <a
              href="/ai/writing/aeduqascomponesixty"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aeduqascomponesixtyhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aeduqascomponesixtyword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aeduqascomponesixtyword" && (
          <>
            <a
              href="/ai/writing/aeduqascomponesixty"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aeduqascomponesixtyhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aeduqascomponesixtybatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangone" && (
          <>
            <a
              href="/ai/writing/cceaenglangonehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangonebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangoneword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/cceaenglangonemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangonehand" && (
          <>
            <a
              href="/ai/writing/cceaenglangone"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangonebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangoneword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/cceaenglangonemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangonebatch" && (
          <>
            <a
              href="/ai/writing/cceaenglangone"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangonehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangoneword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/cceaenglangonemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangoneword" && (
          <>
            <a
              href="/ai/writing/cceaenglangone"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangonehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangonebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangonemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}
        {location.pathname === "/ai/writing/cceaenglangonemulti" && (
          <>
            <a
              href="/ai/writing/cceaenglangone"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangonehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangonebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangoneword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangtwo" && (
          <>
            <a
              href="/ai/writing/cceaenglangtwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangtwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangtwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/cceaenglangtwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangtwohand" && (
          <>
            <a
              href="/ai/writing/cceaenglangtwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangtwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangtwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/cceaenglangtwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangtwobatch" && (
          <>
            <a
              href="/ai/writing/cceaenglangtwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangtwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangtwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/cceaenglangtwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangtwoword" && (
          <>
            <a
              href="/ai/writing/cceaenglangtwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangtwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangtwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangtwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}
        {location.pathname === "/ai/writing/cceaenglangtwomulti" && (
          <>
            <a
              href="/ai/writing/cceaenglangtwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangtwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangtwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangtwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangfour" && (
          <>
            <a
              href="/ai/writing/cceaenglangfourhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangfourbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangfourword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/cceaenglangfourmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangfourhand" && (
          <>
            <a
              href="/ai/writing/cceaenglangfour"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangfourbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangfourword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/cceaenglangfourmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangfourbatch" && (
          <>
            <a
              href="/ai/writing/cceaenglangfour"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangfourhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangfourword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/cceaenglangfourmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangfourword" && (
          <>
            <a
              href="/ai/writing/cceaenglangfour"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangfourhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangfourbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangfourmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}
        {location.pathname === "/ai/writing/cceaenglangfourmulti" && (
          <>
            <a
              href="/ai/writing/cceaenglangfour"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangfourhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangfourbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangfourword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangbpersonal" && (
          <>
            <a
              href="/ai/writing/cceaenglangbpersonalhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangbpersonalbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangbpersonalword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/cceaenglangbpersonalmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangbpersonalhand" && (
          <>
            <a
              href="/ai/writing/cceaenglangbpersonal"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangbpersonalbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangbpersonalword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/cceaenglangbpersonalmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangbpersonalbatch" && (
          <>
            <a
              href="/ai/writing/cceaenglangbpersonal"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangbpersonalhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangbpersonalword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/cceaenglangbpersonalmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangbpersonalword" && (
          <>
            <a
              href="/ai/writing/cceaenglangbpersonal"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangbpersonalhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangbpersonalbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangbpersonalmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangbpersonalmulti" && (
          <>
            <a
              href="/ai/writing/cceaenglangbpersonal"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangbpersonalhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangbpersonalbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangbpersonalword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangbtwo" && (
          <>
            <a
              href="/ai/writing/cceaenglangbtwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangbtwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangbtwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/cceaenglangbtwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangbtwohand" && (
          <>
            <a
              href="/ai/writing/cceaenglangbtwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangbtwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangbtwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/cceaenglangbtwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangbtwobatch" && (
          <>
            <a
              href="/ai/writing/cceaenglangbtwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangbtwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangbtwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/cceaenglangbtwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangbtwoword" && (
          <>
            <a
              href="/ai/writing/cceaenglangbtwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangbtwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangbtwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangbtwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangbtwomulti" && (
          <>
            <a
              href="/ai/writing/cceaenglangbtwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangbtwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangbtwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangbtwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangbthree" && (
          <>
            <a
              href="/ai/writing/cceaenglangbthreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangbthreebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangbthreeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/cceaenglangbthreemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangbthreehand" && (
          <>
            <a
              href="/ai/writing/cceaenglangbthree"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangbthreebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangbthreeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/cceaenglangbthreemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangbthreebatch" && (
          <>
            <a
              href="/ai/writing/cceaenglangbthree"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangbthreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangbthreeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/cceaenglangbthreemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangbthreeword" && (
          <>
            <a
              href="/ai/writing/cceaenglangbthree"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangbthreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangbthreebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangbthreemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangbthreemulti" && (
          <>
            <a
              href="/ai/writing/cceaenglangbthree"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangbthreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangbthreebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangbthreeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangatwo" && (
          <>
            <a
              href="/ai/writing/edexlangatwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangatwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangatwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/edexlangatwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangatwohand" && (
          <>
            <a
              href="/ai/writing/edexlangatwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangatwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangatwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexlangatwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangatwobatch" && (
          <>
            <a
              href="/ai/writing/edexlangatwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangatwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangatwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexlangatwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangatwoword" && (
          <>
            <a
              href="/ai/writing/edexlangatwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangatwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangatwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangatwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangatwomulti" && (
          <>
            <a
              href="/ai/writing/edexlangatwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangatwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangatwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangatwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangbfour" && (
          <>
            <a
              href="/ai/writing/cceaenglangbfourhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangbfourbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangbfourword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/cceaenglangbfourmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangbfourhand" && (
          <>
            <a
              href="/ai/writing/cceaenglangbfour"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangbfourbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangbfourword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/cceaenglangbfourmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangbfourbatch" && (
          <>
            <a
              href="/ai/writing/cceaenglangbfour"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangbfourhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangbfourword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/cceaenglangbfourmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangbfourword" && (
          <>
            <a
              href="/ai/writing/cceaenglangbfour"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangbfourhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangbfourbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangbfourmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cceaenglangbfourmulti" && (
          <>
            <a
              href="/ai/writing/cceaenglangbfour"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cceaenglangbfourhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cceaenglangbfourbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cceaenglangbfourword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambprose" && (
          <>
            <a
              href="/ai/writing/cambprosehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambprosebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cambproseword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambprosehand" && (
          <>
            <a
              href="/ai/writing/cambprose"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambprosebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cambproseword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambprosebatch" && (
          <>
            <a
              href="/ai/writing/cambprose"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambprosehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambproseword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambproseword" && (
          <>
            <a
              href="/ai/writing/cambprose"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambprosehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambprosebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambproseextract" && (
          <>
            <a
              href="/ai/writing/cambproseextracthand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambproseextractbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cambproseextractword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambproseextracthand" && (
          <>
            <a
              href="/ai/writing/cambproseextract"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambproseextractbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cambproseextractword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambproseextractbatch" && (
          <>
            <a
              href="/ai/writing/cambproseextract"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambproseextracthand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambproseextractword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambproseextractword" && (
          <>
            <a
              href="/ai/writing/cambproseextract"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambproseextracthand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambproseextractbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambpoetry" && (
          <>
            <a
              href="/ai/writing/cambpoetryhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambpoetrybatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cambpoetryword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambpoetryhand" && (
          <>
            <a
              href="/ai/writing/cambpoetry"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambpoetrybatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cambpoetryword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambpoetrybatch" && (
          <>
            <a
              href="/ai/writing/cambpoetry"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambpoetryhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambpoetryword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambpoetryword" && (
          <>
            <a
              href="/ai/writing/cambpoetry"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambpoetryhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambpoetrybatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambdrama" && (
          <>
            <a
              href="/ai/writing/cambdramahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambdramabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cambdramaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambdramahand" && (
          <>
            <a
              href="/ai/writing/cambdrama"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambdramabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cambdramaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambdramabatch" && (
          <>
            <a
              href="/ai/writing/cambdrama"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambdramahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambdramaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambdramaword" && (
          <>
            <a
              href="/ai/writing/cambdrama"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambdramahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambdramabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambdramaextract" && (
          <>
            <a
              href="/ai/writing/cambdramaextracthand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambdramaextractbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cambdramaextractword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambdramaextracthand" && (
          <>
            <a
              href="/ai/writing/cambdramaextract"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambdramaextractbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cambdramaextractword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambdramaextractbatch" && (
          <>
            <a
              href="/ai/writing/cambdramaextract"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambdramaextracthand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambdramaextractword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambdramaextractword" && (
          <>
            <a
              href="/ai/writing/cambdramaextract"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambdramaextracthand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambdramaextractbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambunseenpoetry" && (
          <>
            <a
              href="/ai/writing/cambunseenpoetryhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambunseenpoetrybatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cambunseenpoetryword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambunseenpoetryhand" && (
          <>
            <a
              href="/ai/writing/cambunseenpoetry"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambunseenpoetrybatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cambunseenpoetryword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambunseenpoetrybatch" && (
          <>
            <a
              href="/ai/writing/cambunseenpoetry"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambunseenpoetryhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambunseenpoetryword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambunseenpoetryword" && (
          <>
            <a
              href="/ai/writing/cambunseenpoetry"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambunseenpoetryhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambunseenpoetrybatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambunseenprose" && (
          <>
            <a
              href="/ai/writing/cambunseenprosehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambunseenprosebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cambunseenproseword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambunseenprosehand" && (
          <>
            <a
              href="/ai/writing/cambunseenprose"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambunseenprosebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cambunseenproseword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambunseenprosebatch" && (
          <>
            <a
              href="/ai/writing/cambunseenprose"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambunseenprosehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambunseenproseword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambunseenproseword" && (
          <>
            <a
              href="/ai/writing/cambunseenprose"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambunseenprosehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambunseenprosebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambengonef" && (
          <>
            <a
              href="/ai/writing/cambengonefhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambengonefbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cambengonefword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambengonefhand" && (
          <>
            <a
              href="/ai/writing/cambengonef"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambengonefbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cambengonefword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambengonefbatch" && (
          <>
            <a
              href="/ai/writing/cambengonef"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambengonefhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambengonefword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambengonefword" && (
          <>
            <a
              href="/ai/writing/cambengonef"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambengonefhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambengonefbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambengonethree" && (
          <>
            <a
              href="/ai/writing/cambengonethreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambengonethreebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cambengonethreeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambengonethreehand" && (
          <>
            <a
              href="/ai/writing/cambengonethree"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambengonethreebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cambengonethreeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambengonethreebatch" && (
          <>
            <a
              href="/ai/writing/cambengonethree"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambengonethreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambengonethreeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cambengonethreeword" && (
          <>
            <a
              href="/ai/writing/cambengonethree"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cambengonethreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cambengonethreebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ks2creative" && (
          <>
            <a
              href="/ai/writing/ks2creativehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ks2creativebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ks2creativeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/ks2creativemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ks2creativehand" && (
          <>
            <a
              href="/ai/writing/ks2creative"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ks2creativebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ks2creativeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ks2creativemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ks2creativebatch" && (
          <>
            <a
              href="/ai/writing/ks2creative"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ks2creativehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ks2creativeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ks2creativemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ks2creativeword" && (
          <>
            <a
              href="/ai/writing/ks2creative"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ks2creativehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ks2creativebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ks2creativemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ks2creativemulti" && (
          <>
            <a
              href="/ai/writing/ks2creative"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ks2creativehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ks2creativebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ks2creativeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ks2transaction" && (
          <>
            <a
              href="/ai/writing/ks2transactionhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ks2transactionbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ks2transactionword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/ks2transactionmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ks2transactionhand" && (
          <>
            <a
              href="/ai/writing/ks2transaction"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ks2transactionbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ks2transactionword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ks2transactionmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ks2transactionbatch" && (
          <>
            <a
              href="/ai/writing/ks2transaction"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ks2transactionhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ks2transactionword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ks2transactionmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ks2transactionword" && (
          <>
            <a
              href="/ai/writing/ks2transaction"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ks2transactionhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ks2transactionbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ks2transactionmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ks2transactionmulti" && (
          <>
            <a
              href="/ai/writing/ks2transaction"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ks2transactionhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ks2transactionbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ks2transactionword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/modernprose" && (
          <>
            <a
              href="/ai/writing/modernprosehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/modernprosebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/modernproseword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/modernprosemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/modernprosehand" && (
          <>
            <a
              href="/ai/modernprose"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/modernprosebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/modernproseword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/modernprosemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/modernprosebatch" && (
          <>
            <a
              href="/ai/modernprose"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/modernprosehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/modernproseword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/modernprosemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/modernproseword" && (
          <>
            <a
              href="/ai/modernprose"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/modernprosehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/modernprosebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/modernprosemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}
        {location.pathname === "/ai/writing/modernprosemulti" && (
          <>
            <a
              href="/ai/modernprose"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/modernprosehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/modernprosebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/modernproseword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/unseenedexcel" && (
          <>
            <a
              href="/ai/writing/unseenedexcelhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/unseenedexcelbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/unseenedexcelword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/unseenedexcelmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/unseenedexcelhand" && (
          <>
            <a
              href="/ai/unseenedexcel"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/unseenedexcelbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/unseenedexcelword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/unseenedexcelmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/unseenedexcelbatch" && (
          <>
            <a
              href="/ai/unseenedexcel"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/unseenedexcelhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/unseenedexcelword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/unseenedexcelmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/unseenedexcelword" && (
          <>
            <a
              href="/ai/unseenedexcel"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/unseenedexcelhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/unseenedexcelbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/unseenedexcelmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}
        {location.pathname === "/ai/writing/unseenedexcelmulti" && (
          <>
            <a
              href="/ai/unseenedexcel"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/unseenedexcelhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/unseenedexcelbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/unseenedexcelword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/anthology" && (
          <>
            <a
              href="/ai/writing/anthologyhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/anthologybatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/anthologyword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/anthologymulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/anthologyhand" && (
          <>
            <a
              href="/ai/anthology"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/anthologybatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/anthologyword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/anthologymulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/anthologybatch" && (
          <>
            <a
              href="/ai/anthology"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/anthologyhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/anthologyword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/anthologymulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/anthologyword" && (
          <>
            <a
              href="/ai/anthology"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/anthologyhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/anthologybatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/anthologymulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}
        {location.pathname === "/ai/writing/anthologymulti" && (
          <>
            <a
              href="/ai/anthology"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/anthologyhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/anthologybatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/anthologyword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/moderndrama" && (
          <>
            <a
              href="/ai/writing/moderndramahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/moderndramabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/moderndramaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/moderndramamulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/moderndramahand" && (
          <>
            <a
              href="/ai/writing/moderndrama"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/moderndramabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/moderndramaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/moderndramamulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/moderndramabatch" && (
          <>
            <a
              href="/ai/writing/moderndrama"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/moderndramahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/moderndramaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/moderndramamulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/moderndramaword" && (
          <>
            <a
              href="/ai/writing/moderndrama"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/moderndramahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/moderndramabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/moderndramamulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/moderndramamulti" && (
          <>
            <a
              href="/ai/writing/moderndrama"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/moderndramahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/moderndramabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/moderndramaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/heritage" && (
          <>
            <a
              href="/ai/writing/heritagehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/heritagebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/heritageword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/heritagemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/heritagehand" && (
          <>
            <a
              href="/ai/writing/heritage"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/heritagebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/heritageword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/heritagemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/heritagebatch" && (
          <>
            <a
              href="/ai/writing/heritage"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/heritagehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/heritageword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/heritagemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/heritageword" && (
          <>
            <a
              href="/ai/writing/heritage"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/heritagehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/heritagebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/heritagemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/heritagemulti" && (
          <>
            <a
              href="/ai/writing/heritage"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/heritagehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/heritagebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/heritageword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangathree" && (
          <>
            <a
              href="/ai/writing/edexlangathreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangathreebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangathreeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/edexlangathreemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangathreehand" && (
          <>
            <a
              href="/ai/writing/edexlangathree"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangathreebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangathreeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexlangathreemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangathreebatch" && (
          <>
            <a
              href="/ai/writing/edexlangathree"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangathreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangathreeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexlangathreemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangathreeword" && (
          <>
            <a
              href="/ai/writing/edexlangathree"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangathreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangathreebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangathreemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangathreemulti" && (
          <>
            <a
              href="/ai/writing/edexlangathree"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangathreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangathreebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangathreeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangafour" && (
          <>
            <a
              href="/ai/writing/edexlangafourhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangafourbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangafourword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/edexlangafourmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangafourhand" && (
          <>
            <a
              href="/ai/writing/edexlangafour"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangafourbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangafourword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexlangafourmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangafourbatch" && (
          <>
            <a
              href="/ai/writing/edexlangafour"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangafourhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangafourword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexlangafourmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangafourword" && (
          <>
            <a
              href="/ai/writing/edexlangafour"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangafourhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangafourbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangafourmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangafourmulti" && (
          <>
            <a
              href="/ai/writing/edexlangafour"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangafourhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangafourbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangafourword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangafive" && (
          <>
            <a
              href="/ai/writing/edexlangafivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangafivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangafiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/edexlangafivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangafivehand" && (
          <>
            <a
              href="/ai/writing/edexlangafive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangafivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangafiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexlangafivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangafivebatch" && (
          <>
            <a
              href="/ai/writing/edexlangafive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangafivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangafiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexlangafivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangafiveword" && (
          <>
            <a
              href="/ai/writing/edexlangafive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangafivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangafivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangafivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangafivemulti" && (
          <>
            <a
              href="/ai/writing/edexlangafive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangafivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangafivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangafiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangasix" && (
          <>
            <a
              href="/ai/writing/edexlangasixhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangasixbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangasixword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/edexlangasixmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangasixhand" && (
          <>
            <a
              href="/ai/writing/edexlangasix"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangasixbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangasixword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexlangasixmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangasixbatch" && (
          <>
            <a
              href="/ai/writing/edexlangasix"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangasixhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangasixword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexlangasixmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangasixword" && (
          <>
            <a
              href="/ai/writing/edexlangasix"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangasixhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangasixbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangasixmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangasixmulti" && (
          <>
            <a
              href="/ai/writing/edexlangasix"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangasixhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangasixbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangasixword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangbone" && (
          <>
            <a
              href="/ai/writing/edexlangbonehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangbonebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangboneword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/edexlangbonemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangbonehand" && (
          <>
            <a
              href="/ai/writing/edexlangbone"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangbonebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangboneword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexlangbonemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangbonebatch" && (
          <>
            <a
              href="/ai/writing/edexlangbone"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangbonehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangboneword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexlangbonemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangboneword" && (
          <>
            <a
              href="/ai/writing/edexlangbone"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangbonehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangbonebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangbonemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangbonemulti" && (
          <>
            <a
              href="/ai/writing/edexlangbone"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangbonehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangbonebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangboneword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangbtwo" && (
          <>
            <a
              href="/ai/writing/edexlangbtwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangbtwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangbtwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/edexlangbtwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangbtwohand" && (
          <>
            <a
              href="/ai/writing/edexlangbtwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangbtwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangbtwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexlangbtwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangbtwobatch" && (
          <>
            <a
              href="/ai/writing/edexlangbtwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangbtwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangbtwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexlangbtwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangbtwoword" && (
          <>
            <a
              href="/ai/writing/edexlangbtwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangbtwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangbtwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangbtwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexlangbtwomulti" && (
          <>
            <a
              href="/ai/writing/edexlangbtwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexlangbtwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexlangbtwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexlangbtwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist1one" && (
          <>
            <a
              href="/ai/writing/aedexhist1onehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aedexhist1onebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aedexhist1oneword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/aedexhist1onemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist1onehand" && (
          <>
            <a
              href="/ai/writing/aedexhist1one"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aedexhist1onebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aedexhist1oneword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aedexhist1onemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist1onebatch" && (
          <>
            <a
              href="/ai/writing/aedexhist1one"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aedexhist1onehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aedexhist1oneword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aedexhist1onemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist1oneword" && (
          <>
            <a
              href="/ai/writing/aedexhist1one"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aedexhist1onehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aedexhist1onebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aedexhist1onemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist1onemulti" && (
          <>
            <a
              href="/ai/writing/aedexhist1one"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aedexhist1onehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aedexhist1onebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aedexhist1oneword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist1two" && (
          <>
            <a
              href="/ai/writing/aedexhist1twohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aedexhist1twobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aedexhist1twoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/aedexhist1twomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist1twohand" && (
          <>
            <a
              href="/ai/writing/aedexhist1two"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aedexhist1twobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aedexhist1twoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aedexhist1twomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist1twobatch" && (
          <>
            <a
              href="/ai/writing/aedexhist1two"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aedexhist1twohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aedexhist1twoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aedexhist1twomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist1twoword" && (
          <>
            <a
              href="/ai/writing/aedexhist1two"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aedexhist1twohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aedexhist1twobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aedexhist1twomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist1twomulti" && (
          <>
            <a
              href="/ai/writing/aedexhist1two"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aedexhist1twohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aedexhist1twobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aedexhist1twoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist2one" && (
          <>
            <a
              href="/ai/writing/aedexhist2onehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aedexhist2onebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aedexhist2oneword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/aedexhist2onemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist2onehand" && (
          <>
            <a
              href="/ai/writing/aedexhist2one"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aedexhist2onebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aedexhist2oneword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aedexhist2onemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist2onebatch" && (
          <>
            <a
              href="/ai/writing/aedexhist2one"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aedexhist2onehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aedexhist2oneword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aedexhist2onemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist2oneword" && (
          <>
            <a
              href="/ai/writing/aedexhist2one"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aedexhist2onehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aedexhist2onebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aedexhist2onemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist2onemulti" && (
          <>
            <a
              href="/ai/writing/aedexhist2one"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aedexhist2onehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aedexhist2onebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aedexhist2oneword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist2two" && (
          <>
            <a
              href="/ai/writing/aedexhist2twohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aedexhist2twobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aedexhist2twoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aedexhist2twomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist2twohand" && (
          <>
            <a
              href="/ai/writing/aedexhist2two"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aedexhist2twobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aedexhist2twoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aedexhist2twomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist2twobatch" && (
          <>
            <a
              href="/ai/writing/aedexhist2two"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aedexhist2twohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aedexhist2twoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aedexhist2twomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist2twoword" && (
          <>
            <a
              href="/ai/writing/aedexhist2two"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aedexhist2twohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aedexhist2twobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aedexhist2twomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist2twomulti" && (
          <>
            <a
              href="/ai/writing/aedexhist2two"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aedexhist2twohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aedexhist2twobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aedexhist2twoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist3one" && (
          <>
            <a
              href="/ai/writing/aedexhist3onehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aedexhist3onebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aedexhist3oneword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/aedexhist3onemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist3onehand" && (
          <>
            <a
              href="/ai/writing/aedexhist3one"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aedexhist3onebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aedexhist3oneword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aedexhist3onemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist3onebatch" && (
          <>
            <a
              href="/ai/writing/aedexhist3one"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aedexhist3onehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aedexhist3oneword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aedexhist3onemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist3oneword" && (
          <>
            <a
              href="/ai/writing/aedexhist3one"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aedexhist3onehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aedexhist3onebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aedexhist3onemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist3onemulti" && (
          <>
            <a
              href="/ai/writing/aedexhist3one"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aedexhist3onehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aedexhist3onebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aedexhist3oneword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist3two" && (
          <>
            <a
              href="/ai/writing/aedexhist3twohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aedexhist3twobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aedexhist3twoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/aedexhist3twomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist3twohand" && (
          <>
            <a
              href="/ai/writing/aedexhist3two"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aedexhist3twobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aedexhist3twoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aedexhist3twomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist3twobatch" && (
          <>
            <a
              href="/ai/writing/aedexhist3two"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aedexhist3twohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aedexhist3twoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aedexhist3twomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist3twoword" && (
          <>
            <a
              href="/ai/writing/aedexhist3two"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aedexhist3twohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aedexhist3twobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aedexhist3twomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aedexhist3twomulti" && (
          <>
            <a
              href="/ai/writing/aedexhist3two"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aedexhist3twohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aedexhist3twobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aedexhist3twoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cantabhist1six" && (
          <>
            <a
              href="/ai/writing/cantabhist1sixhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cantabhist1sixbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cantabhist1sixword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/cantabhist1sixmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cantabhist1sixhand" && (
          <>
            <a
              href="/ai/writing/cantabhist1six"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cantabhist1sixbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cantabhist1sixword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/cantabhist1sixmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cantabhist1sixbatch" && (
          <>
            <a
              href="/ai/writing/cantabhist1six"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cantabhist1sixhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cantabhist1sixword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/cantabhist1sixmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cantabhist1sixword" && (
          <>
            <a
              href="/ai/writing/cantabhist1six"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cantabhist1sixhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cantabhist1sixbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cantabhist1sixmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cantabhist1sixmulti" && (
          <>
            <a
              href="/ai/writing/cantabhist1six"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cantabhist1sixhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cantabhist1sixbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cantabhist1sixword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cantabhist1ten" && (
          <>
            <a
              href="/ai/writing/cantabhist1tenhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cantabhist1tenbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cantabhist1tenword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/cantabhist1tenmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cantabhist1tenhand" && (
          <>
            <a
              href="/ai/writing/cantabhist1ten"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cantabhist1tenbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cantabhist1tenword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/cantabhist1tenmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cantabhist1tenbatch" && (
          <>
            <a
              href="/ai/writing/cantabhist1ten"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cantabhist1tenhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cantabhist1tenword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/cantabhist1tenmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cantabhist1tenword" && (
          <>
            <a
              href="/ai/writing/cantabhist1ten"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cantabhist1tenhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cantabhist1tenbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cantabhist1tenmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cantabhist1tenmulti" && (
          <>
            <a
              href="/ai/writing/cantabhist1ten"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cantabhist1tenhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cantabhist1tenbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cantabhist1tenword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cantabhist2seven" && (
          <>
            <a
              href="/ai/writing/cantabhist2sevenhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cantabhist2sevenbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cantabhist2sevenword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/cantabhist2sevenmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cantabhist2sevenhand" && (
          <>
            <a
              href="/ai/writing/cantabhist2seven"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cantabhist2sevenbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cantabhist2sevenword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/cantabhist2sevenmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cantabhist2sevenbatch" && (
          <>
            <a
              href="/ai/writing/cantabhist2seven"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cantabhist2sevenhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cantabhist2sevenword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/cantabhist2sevenmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cantabhist2sevenword" && (
          <>
            <a
              href="/ai/writing/cantabhist2seven"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cantabhist2sevenhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cantabhist2sevenbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cantabhist2sevenmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cantabhist2sevenmulti" && (
          <>
            <a
              href="/ai/writing/cantabhist2seven"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cantabhist2sevenhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cantabhist2sevenbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cantabhist2sevenword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqshakespeare" && (
          <>
            <a
              href="/ai/writing/eduqshakespearehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqshakespearebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqshakespeareword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/eduqshakespearemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cantabhist2source8" && (
          <>
            <a
              href="/ai/writing/cantabhist2source8hand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cantabhist2source8batch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cantabhist2source8word"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/cantabhist2source8multi"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cantabhist2source8hand" && (
          <>
            <a
              href="/ai/writing/cantabhist2source8"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cantabhist2source8batch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cantabhist2source8word"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/cantabhist2source8multi"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cantabhist2source8batch" && (
          <>
            <a
              href="/ai/writing/cantabhist2source8"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cantabhist2source8hand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cantabhist2source8word"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/cantabhist2source8multi"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cantabhist2source8word" && (
          <>
            <a
              href="/ai/writing/cantabhist2source8"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cantabhist2source8hand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cantabhist2source8batch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cantabhist2source8multi"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/cantabhist2source8multi" && (
          <>
            <a
              href="/ai/writing/cantabhist2source8"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/cantabhist2source8hand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/cantabhist2source8batch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/cantabhist2source8word"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqshakespearehand" && (
          <>
            <a
              href="/ai/writing/eduqshakespeare"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqshakespearebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqshakespeareword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqshakespearemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqshakespearebatch" && (
          <>
            <a
              href="/ai/writing/eduqshakespeare"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqshakespearehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqshakespeareword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqshakespearemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqshakespeareword" && (
          <>
            <a
              href="/ai/writing/eduqshakespeare"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqshakespearehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqshakespearebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqshakespearemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqshakespearemulti" && (
          <>
            <a
              href="/ai/writing/eduqshakespeare"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqshakespearehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqshakespearebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqshakespeareword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqshakespeareb" && (
          <>
            <a
              href="/ai/writing/eduqshakespearebhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqshakespearebbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqshakespearebword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqshakespearebmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqshakespearebhand" && (
          <>
            <a
              href="/ai/writing/eduqshakespeareb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqshakespearebbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqshakespearebword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqshakespearebmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqshakespearebbatch" && (
          <>
            <a
              href="/ai/writing/eduqshakespeareb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqshakespearebhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqshakespearebword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqshakespearebmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqshakespearebword" && (
          <>
            <a
              href="/ai/writing/eduqshakespeareb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqshakespearebhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqshakespearebbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqshakespearebmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqshakespearebmulti" && (
          <>
            <a
              href="/ai/writing/eduqshakespeareb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqshakespearebhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqshakespearebbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqshakespearebword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqpoetry" && (
          <>
            <a
              href="/ai/writing/eduqpoetryhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqpoetrybatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqpoetryword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqpoetrymulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqpoetryhand" && (
          <>
            <a
              href="/ai/writing/eduqpoetry"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqpoetrybatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqpoetryword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqpoetrymulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqpoetrybatch" && (
          <>
            <a
              href="/ai/writing/eduqpoetry"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqpoetryhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqpoetryword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqpoetrymulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqpoetryword" && (
          <>
            <a
              href="/ai/writing/eduqpoetry"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqpoetryhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqpoetrybatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqpoetrymulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqpoetrymulti" && (
          <>
            <a
              href="/ai/writing/eduqpoetry"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqpoetryhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqpoetrybatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqpoetryword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqpoetryb" && (
          <>
            <a
              href="/ai/writing/eduqpoetrybhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqpoetrybbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqpoetrybword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/eduqpoetrybmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqpoetrybhand" && (
          <>
            <a
              href="/ai/writing/eduqpoetryb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqpoetrybbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqpoetrybword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqpoetrybmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqpoetrybbatch" && (
          <>
            <a
              href="/ai/writing/eduqpoetryb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqpoetrybhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqpoetrybword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqpoetrybmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqpoetrybword" && (
          <>
            <a
              href="/ai/writing/eduqpoetryb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqpoetrybhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqpoetrybbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqpoetrybmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqpoetrybmulti" && (
          <>
            <a
              href="/ai/writing/eduqpoetryb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqpoetrybhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqpoetrybbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqpoetrybword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqaspost1914prose" && (
          <>
            <a
              href="/ai/writing/eduqaspost1914prosehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqaspost1914prosebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqaspost1914proseword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqaspost1914prosemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqaspost1914prosehand" && (
          <>
            <a
              href="/ai/writing/eduqaspost1914prose"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqaspost1914prosebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqaspost1914proseword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqaspost1914prosemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqaspost1914prosebatch" && (
          <>
            <a
              href="/ai/writing/eduqaspost1914prose"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqaspost1914prosehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqaspost1914proseword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqaspost1914prosemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqaspost1914proseword" && (
          <>
            <a
              href="/ai/writing/eduqaspost1914prose"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqaspost1914prosehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqaspost1914prosebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqaspost1914prosemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqaspost1914prosemulti" && (
          <>
            <a
              href="/ai/writing/eduqaspost1914prose"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqaspost1914prosehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqaspost1914prosebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqaspost1914proseword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqaspost1914drama" && (
          <>
            <a
              href="/ai/writing/eduqaspost1914dramahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqaspost1914dramabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqaspost1914dramaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqaspost1914dramamulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqaspost1914dramahand" && (
          <>
            <a
              href="/ai/writing/eduqaspost1914drama"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqaspost1914dramabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqaspost1914dramaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqaspost1914dramamulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqaspost1914dramabatch" && (
          <>
            <a
              href="/ai/writing/eduqaspost1914drama"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqaspost1914dramahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqaspost1914dramaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqaspost1914dramamulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqaspost1914dramaword" && (
          <>
            <a
              href="/ai/writing/eduqaspost1914drama"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqaspost1914dramahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqaspost1914dramabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqaspost1914dramamulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqaspost1914dramamulti" && (
          <>
            <a
              href="/ai/writing/eduqaspost1914drama"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqaspost1914dramahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqaspost1914dramabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqaspost1914dramaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqasnovel" && (
          <>
            <a
              href="/ai/writing/eduqasnovelhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqasnovelbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqasnovelword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/eduqasnovelmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqasnovelhand" && (
          <>
            <a
              href="/ai/writing/eduqasnovel"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqasnovelbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqasnovelword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqasnovelmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqasnovelbatch" && (
          <>
            <a
              href="/ai/writing/eduqasnovel"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqasnovelhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqasnovelword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqasnovelmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqasnovelword" && (
          <>
            <a
              href="/ai/writing/eduqasnovel"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqasnovelhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqasnovelbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqasnovelmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqasnovelmulti" && (
          <>
            <a
              href="/ai/writing/eduqasnovel"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqasnovelhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqasnovelbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqasnovelword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqasunseena" && (
          <>
            <a
              href="/ai/writing/eduqasunseenahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqasunseenabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqasunseenaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqasunseenamulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqasunseenahand" && (
          <>
            <a
              href="/ai/writing/eduqasunseena"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqasunseenabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqasunseenaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqasunseenamulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqasunseenabatch" && (
          <>
            <a
              href="/ai/writing/eduqasunseena"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqasunseenahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqasunseenaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqasunseenamulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqasunseenaword" && (
          <>
            <a
              href="/ai/writing/eduqasunseena"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqasunseenahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqasunseenabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqasunseenamulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqasunseenamulti" && (
          <>
            <a
              href="/ai/writing/eduqasunseena"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqasunseenahand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqasunseenabatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqasunseenaword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqasunseenb" && (
          <>
            <a
              href="/ai/writing/eduqasunseenbhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqasunseenbbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqasunseenbword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqasunseenbmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqasunseenbhand" && (
          <>
            <a
              href="/ai/writing/eduqasunseenb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqasunseenbbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqasunseenbword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqasunseenbmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqasunseenbbatch" && (
          <>
            <a
              href="/ai/writing/eduqasunseenb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqasunseenbhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqasunseenbword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqasunseenbmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqasunseenbword" && (
          <>
            <a
              href="/ai/writing/eduqasunseenb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqasunseenbhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqasunseenbbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqasunseenbmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqasunseenbmulti" && (
          <>
            <a
              href="/ai/writing/eduqasunseenb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqasunseenbhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqasunseenbbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqasunseenbword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomponetwo" && (
          <>
            <a
              href="/ai/writing/eduqascomponetwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomponetwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomponetwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqascomponetwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomponetwohand" && (
          <>
            <a
              href="/ai/writing/eduqascomponetwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomponetwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomponetwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqascomponetwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomponetwobatch" && (
          <>
            <a
              href="/ai/writing/eduqascomponetwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomponetwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomponetwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqascomponetwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomponetwoword" && (
          <>
            <a
              href="/ai/writing/eduqascomponetwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomponetwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomponetwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomponetwomulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomponetwomulti" && (
          <>
            <a
              href="/ai/writing/eduqascomponetwo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomponetwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomponetwobatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomponetwoword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomponehow" && (
          <>
            <a
              href="/ai/writing/eduqascomponehowhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomponehowbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomponehowword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/eduqascomponehowmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomponehowhand" && (
          <>
            <a
              href="/ai/writing/eduqascomponehow"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomponehowbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomponehowword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqascomponehowmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomponehowbatch" && (
          <>
            <a
              href="/ai/writing/eduqascomponehow"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomponehowhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomponehowword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqascomponehowmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomponehowword" && (
          <>
            <a
              href="/ai/writing/eduqascomponehow"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomponehowhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomponehowbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomponehowmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomponehowmulti" && (
          <>
            <a
              href="/ai/writing/eduqascomponehow"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomponehowhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomponehowbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomponehowword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomponewhathow" && (
          <>
            <a
              href="/ai/writing/eduqascomponewhathowhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomponewhathowbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomponewhathowword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqascomponewhathowmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomponewhathowhand" && (
          <>
            <a
              href="/ai/writing/eduqascomponewhathow"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomponewhathowbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomponewhathowword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqascomponewhathowmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomponewhathowbatch" && (
          <>
            <a
              href="/ai/writing/eduqascomponewhathow"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomponewhathowhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomponewhathowword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqascomponewhathowmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomponewhathowword" && (
          <>
            <a
              href="/ai/writing/eduqascomponewhathow"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomponewhathowhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomponewhathowbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomponewhathowmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomponewhathowmulti" && (
          <>
            <a
              href="/ai/writing/eduqascomponewhathow"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomponewhathowhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomponewhathowbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomponewhathowword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomponefive" && (
          <>
            <a
              href="/ai/writing/eduqascomponefivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomponefivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomponefiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/eduqascomponefivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomponefivehand" && (
          <>
            <a
              href="/ai/writing/eduqascomponefive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomponefivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomponefiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqascomponefivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomponefivebatch" && (
          <>
            <a
              href="/ai/writing/eduqascomponefive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomponefivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomponefiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqascomponefivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomponefiveword" && (
          <>
            <a
              href="/ai/writing/eduqascomponefive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomponefivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomponefivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomponefivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomponefivemulti" && (
          <>
            <a
              href="/ai/writing/eduqascomponefive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomponefivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomponefivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomponefiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomponeb" && (
          <>
            <a
              href="/ai/writing/eduqascomponebhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomponebbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomponebword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqascomponebmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomponebhand" && (
          <>
            <a
              href="/ai/writing/eduqascomponeb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomponebbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomponebword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqascomponebmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomponebbatch" && (
          <>
            <a
              href="/ai/writing/eduqascomponeb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomponebhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomponebword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqascomponebmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomponebword" && (
          <>
            <a
              href="/ai/writing/eduqascomponeb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomponebhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomponebbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomponebmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomponebmulti" && (
          <>
            <a
              href="/ai/writing/eduqascomponeb"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomponebhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomponebbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomponebword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomptwohow" && (
          <>
            <a
              href="/ai/writing/eduqascomptwohowhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomptwohowbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomptwohowword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqascomptwohowmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomptwohowhand" && (
          <>
            <a
              href="/ai/writing/eduqascomptwohow"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomptwohowbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomptwohowword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqascomptwohowmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomptwohowbatch" && (
          <>
            <a
              href="/ai/writing/eduqascomptwohow"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomptwohowhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomptwohowword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqascomptwohowmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomptwohowword" && (
          <>
            <a
              href="/ai/writing/eduqascomptwohow"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomptwohowhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomptwohowbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomptwohowmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomptwohowmulti" && (
          <>
            <a
              href="/ai/writing/eduqascomptwohow"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomptwohowhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomptwohowbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomptwohowword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomptwodegree" && (
          <>
            <a
              href="/ai/writing/eduqascomptwodegreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomptwodegreebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomptwodegreeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/eduqascomptwodegreemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomptwodegreehand" && (
          <>
            <a
              href="/ai/writing/eduqascomptwodegree"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomptwodegreebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomptwodegreeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqascomptwodegreemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomptwodegreebatch" && (
          <>
            <a
              href="/ai/writing/eduqascomptwodegree"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomptwodegreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomptwodegreeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqascomptwodegreemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomptwodegreeword" && (
          <>
            <a
              href="/ai/writing/eduqascomptwodegree"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomptwodegreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomptwodegreebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomptwodegreemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomptwodegreemulti" && (
          <>
            <a
              href="/ai/writing/eduqascomptwodegree"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomptwodegreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomptwodegreebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomptwodegreeword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomptwocompare" && (
          <>
            <a
              href="/ai/writing/eduqascomptwocomparehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomptwocomparebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomptwocompareword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/eduqascomptwocomparemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomptwocomparehand" && (
          <>
            <a
              href="/ai/writing/eduqascomptwocompare"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomptwocomparebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomptwocompareword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqascomptwocomparemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomptwocomparebatch" && (
          <>
            <a
              href="/ai/writing/eduqascomptwocompare"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomptwocomparehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomptwocompareword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqascomptwocomparemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomptwocompareword" && (
          <>
            <a
              href="/ai/writing/eduqascomptwocompare"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomptwocomparehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomptwocomparebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomptwocomparemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomptwocomparemulti" && (
          <>
            <a
              href="/ai/writing/eduqascomptwocompare"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomptwocomparehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomptwocomparebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomptwocompareword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomptwob" && (
          <>
            <a
              href="/ai/writing/eduqascomptwobhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomptwobbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomptwobword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>

            <a
              href="/ai/writing/eduqascomptwobmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomptwobhand" && (
          <>
            <a
              href="/ai/writing/eduqascomptwob"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomptwobbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomptwobword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqascomptwobmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomptwobbatch" && (
          <>
            <a
              href="/ai/writing/eduqascomptwob"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomptwobhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomptwobword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/eduqascomptwobmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomptwobword" && (
          <>
            <a
              href="/ai/writing/eduqascomptwob"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomptwobhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomptwobbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomptwobmulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eduqascomptwobmulti" && (
          <>
            <a
              href="/ai/writing/eduqascomptwob"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/eduqascomptwobhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/eduqascomptwobbatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/eduqascomptwobword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphiloepifive" && (
          <>
            <a
              href="/ai/writing/aqaphiloepifivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphiloepifivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphiloepifiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphiloepifivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphiloepifivehand" && (
          <>
            <a
              href="/ai/writing/aqaphiloepifive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphiloepifivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphiloepifiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphiloepifivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphiloepifivebatch" && (
          <>
            <a
              href="/ai/writing/aqaphiloepifive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphiloepifivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphiloepifiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphiloepifivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphiloepifiveword" && (
          <>
            <a
              href="/ai/writing/aqaphiloepifive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphiloepifivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphiloepifivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphiloepifivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphiloepifivemulti" && (
          <>
            <a
              href="/ai/writing/aqaphiloepifive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphiloepifivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphiloepifivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphiloepifiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphiloepitwelve" && (
          <>
            <a
              href="/ai/writing/aqaphiloepitwelvehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphiloepitwelvebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphiloepitwelveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphiloepitwelvemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphiloepitwelvehand" && (
          <>
            <a
              href="/ai/writing/aqaphiloepitwelve"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphiloepitwelvebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphiloepitwelveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphiloepitwelvemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphiloepitwelvebatch" && (
          <>
            <a
              href="/ai/writing/aqaphiloepitwelve"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphiloepitwelvehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphiloepitwelveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphiloepitwelvemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphiloepitwelveword" && (
          <>
            <a
              href="/ai/writing/aqaphiloepitwelve"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphiloepitwelvehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphiloepitwelvebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphiloepitwelvemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphiloepitwelvemulti" && (
          <>
            <a
              href="/ai/writing/aqaphiloepitwelve"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphiloepitwelvehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphiloepitwelvebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphiloepitwelveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphiloepitwentyfive" && (
          <>
            <a
              href="/ai/writing/aqaphiloepitwentyfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphiloepitwentyfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphiloepitwentyfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphiloepitwentyfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphiloepitwentyfivehand" && (
          <>
            <a
              href="/ai/writing/aqaphiloepitwentyfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphiloepitwentyfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphiloepitwentyfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphiloepitwentyfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphiloepitwentyfivebatch" && (
          <>
            <a
              href="/ai/writing/aqaphiloepitwentyfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphiloepitwentyfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphiloepitwentyfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphiloepitwentyfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphiloepitwentyfiveword" && (
          <>
            <a
              href="/ai/writing/aqaphiloepitwentyfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphiloepitwentyfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphiloepitwentyfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphiloepitwentyfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphiloepitwentyfivemulti" && (
          <>
            <a
              href="/ai/writing/aqaphiloepitwentyfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphiloepitwentyfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphiloepitwentyfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphiloepitwentyfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomoralfive" && (
          <>
            <a
              href="/ai/writing/aqaphilomoralfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilomoralfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilomoralfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilomoralfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomoralfivehand" && (
          <>
            <a
              href="/ai/writing/aqaphilomoralfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilomoralfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilomoralfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilomoralfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomoralfivebatch" && (
          <>
            <a
              href="/ai/writing/aqaphilomoralfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilomoralfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilomoralfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilomoralfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomoralfiveword" && (
          <>
            <a
              href="/ai/writing/aqaphilomoralfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilomoralfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilomoralfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilomoralfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomoralfivemulti" && (
          <>
            <a
              href="/ai/writing/aqaphilomoralfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilomoralfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify
-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilomoralfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilomoralfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomoraltwelve" && (
          <>
            <a
              href="/ai/writing/aqaphilomoraltwelvehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwelvebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwelveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwelvemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomoraltwelvehand" && (
          <>
            <a
              href="/ai/writing/aqaphilomoraltwelve"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwelvebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwelveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwelvemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomoraltwelvebatch" && (
          <>
            <a
              href="/ai/writing/aqaphilomoraltwelve"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwelvehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwelveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwelvemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomoraltwelveword" && (
          <>
            <a
              href="/ai/writing/aqaphilomoraltwelve"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwelvehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwelvebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwelvemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomoraltwelvemulti" && (
          <>
            <a
              href="/ai/writing/aqaphilomoraltwelve"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwelvehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwelvebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwelveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomoraltwentyfive" && (
          <>
            <a
              href="/ai/writing/aqaphilomoraltwentyfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwentyfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwentyfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwentyfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomoraltwentyfivehand" && (
          <>
            <a
              href="/ai/writing/aqaphilomoraltwentyfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwentyfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwentyfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwentyfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomoraltwentyfivebatch" && (
          <>
            <a
              href="/ai/writing/aqaphilomoraltwentyfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwentyfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwentyfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwentyfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomoraltwentyfiveword" && (
          <>
            <a
              href="/ai/writing/aqaphilomoraltwentyfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwentyfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwentyfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwentyfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomoraltwentyfivemulti" && (
          <>
            <a
              href="/ai/writing/aqaphilomoraltwentyfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwentyfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwentyfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilomoraltwentyfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilogodfive" && (
          <>
            <a
              href="/ai/writing/aqaphilogodfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilogodfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilogodfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilogodfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilogodfivehand" && (
          <>
            <a
              href="/ai/writing/aqaphilogodfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilogodfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilogodfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilogodfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilogodfivebatch" && (
          <>
            <a
              href="/ai/writing/aqaphilogodfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilogodfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilogodfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilogodfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilogodfiveword" && (
          <>
            <a
              href="/ai/writing/aqaphilogodfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilogodfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilogodfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilogodfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilogodfivemulti" && (
          <>
            <a
              href="/ai/writing/aqaphilogodfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilogodfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilogodfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilogodfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilogodtwelve" && (
          <>
            <a
              href="/ai/writing/aqaphilogodtwelvehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilogodtwelvebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilogodtwelveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilogodtwelvemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilogodtwelvehand" && (
          <>
            <a
              href="/ai/writing/aqaphilogodtwelve"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilogodtwelvebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilogodtwelveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilogodtwelvemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilogodtwelvebatch" && (
          <>
            <a
              href="/ai/writing/aqaphilogodtwelve"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilogodtwelvehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilogodtwelveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilogodtwelvemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilogodtwelveword" && (
          <>
            <a
              href="/ai/writing/aqaphilogodtwelve"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilogodtwelvehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilogodtwelvebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilogodtwelvemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilogodtwelvemulti" && (
          <>
            <a
              href="/ai/writing/aqaphilogodtwelve"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilogodtwelvehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify
      -center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilogodtwelvebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilogodtwelveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilogodtwentyfive" && (
          <>
            <a
              href="/ai/writing/aqaphilogodtwentyfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilogodtwentyfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilogodtwentyfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilogodtwentyfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilogodtwentyfivehand" && (
          <>
            <a
              href="/ai/writing/aqaphilogodtwentyfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilogodtwentyfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilogodtwentyfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilogodtwentyfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilogodtwentyfivebatch" && (
          <>
            <a
              href="/ai/writing/aqaphilogodtwentyfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilogodtwentyfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilogodtwentyfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilogodtwentyfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilogodtwentyfiveword" && (
          <>
            <a
              href="/ai/writing/aqaphilogodtwentyfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilogodtwentyfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilogodtwentyfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilogodtwentyfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilogodtwentyfivemulti" && (
          <>
            <a
              href="/ai/writing/aqaphilogodtwentyfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilogodtwentyfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilogodtwentyfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilogodtwentyfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomindfive" && (
          <>
            <a
              href="/ai/writing/aqaphilomindfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilomindfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilomindfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilomindfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomindfivehand" && (
          <>
            <a
              href="/ai/writing/aqaphilomindfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilomindfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilomindfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilomindfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomindfivebatch" && (
          <>
            <a
              href="/ai/writing/aqaphilomindfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilomindfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilomindfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilomindfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomindfiveword" && (
          <>
            <a
              href="/ai/writing/aqaphilomindfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilomindfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilomindfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilomindfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomindfivemulti" && (
          <>
            <a
              href="/ai/writing/aqaphilomindfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilomindfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilomindfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilomindfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomindtwelve" && (
          <>
            <a
              href="/ai/writing/aqaphilomindtwelvehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilomindtwelvebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilomindtwelveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilomindtwelvemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomindtwelvehand" && (
          <>
            <a
              href="/ai/writing/aqaphilomindtwelve"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilomindtwelvebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilomindtwelveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilomindtwelvemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomindtwelvebatch" && (
          <>
            <a
              href="/ai/writing/aqaphilomindtwelve"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilomindtwelvehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilomindtwelveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilomindtwelvemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomindtwelveword" && (
          <>
            <a
              href="/ai/writing/aqaphilomindtwelve"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilomindtwelvehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilomindtwelvebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilomindtwelvemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomindtwelvemulti" && (
          <>
            <a
              href="/ai/writing/aqaphilomindtwelve"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilomindtwelvehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilomindtwelvebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilomindtwelveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomindtwentyfive" && (
          <>
            <a
              href="/ai/writing/aqaphilomindtwentyfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilomindtwentyfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilomindtwentyfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilomindtwentyfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomindtwentyfivehand" && (
          <>
            <a
              href="/ai/writing/aqaphilomindtwentyfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilomindtwentyfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilomindtwentyfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilomindtwentyfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomindtwentyfivebatch" && (
          <>
            <a
              href="/ai/writing/aqaphilomindtwentyfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilomindtwentyfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilomindtwentyfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/aqaphilomindtwentyfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomindtwentyfiveword" && (
          <>
            <a
              href="/ai/writing/aqaphilomindtwentyfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilomindtwentyfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilomindtwentyfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilomindtwentyfivemulti"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/aqaphilomindtwentyfivemulti" && (
          <>
            <a
              href="/ai/writing/aqaphilomindtwentyfive"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/aqaphilomindtwentyfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/aqaphilomindtwentyfivebatch"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/aqaphilomindtwentyfiveword"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexhisttheme12" && (
          <>
            <a
              href="/ai/writing/edexhisttheme12hand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexhisttheme12batch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexhisttheme12word"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexhisttheme12multi"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexhisttheme12zip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexhisttheme12hand" && (
          <>
            <a
              href="/ai/writing/edexhisttheme12"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexhisttheme12batch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexhisttheme12word"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexhisttheme12multi"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexhisttheme12zip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexhisttheme12batch" && (
          <>
            <a
              href="/ai/writing/edexhisttheme12"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexhisttheme12hand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexhisttheme12word"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexhisttheme12multi"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexhisttheme12zip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexhisttheme12word" && (
          <>
            <a
              href="/ai/writing/edexhisttheme12"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexhisttheme12hand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexhisttheme12batch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexhisttheme12multi"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexhisttheme12zip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexhisttheme12multi" && (
          <>
            <a
              href="/ai/writing/edexhisttheme12"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexhisttheme12hand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexhisttheme12batch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexhisttheme12word"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexhisttheme12zip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexhisttheme12zip" && (
          <>
            <a
              href="/ai/writing/edexhisttheme12"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexhisttheme12hand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexhisttheme12batch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexhisttheme12word"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexhisttheme12multi"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexhisttheme20" && (
          <>
            <a
              href="/ai/writing/edexhisttheme20hand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexhisttheme20batch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexhisttheme20word"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexhisttheme20multi"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexhisttheme20zip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexhisttheme20hand" && (
          <>
            <a
              href="/ai/writing/edexhisttheme20"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexhisttheme20batch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexhisttheme20word"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexhisttheme20multi"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexhisttheme20zip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexhisttheme20batch" && (
          <>
            <a
              href="/ai/writing/edexhisttheme20"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexhisttheme20hand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexhisttheme20word"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexhisttheme20multi"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexhisttheme20zip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexhisttheme20word" && (
          <>
            <a
              href="/ai/writing/edexhisttheme20"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexhisttheme20hand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexhisttheme20batch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexhisttheme20multi"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexhisttheme20zip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexhisttheme20multi" && (
          <>
            <a
              href="/ai/writing/edexhisttheme20"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexhisttheme20hand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexhisttheme20batch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexhisttheme20word"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexhisttheme20zip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexhisttheme20zip" && (
          <>
            <a
              href="/ai/writing/edexhisttheme20"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexhisttheme20hand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexhisttheme20batch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexhisttheme20word"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexhisttheme20multi"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexhistperiod1" && (
          <>
            <a
              href="/ai/writing/edexhistperiod1hand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexhistperiod1batch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexhistperiod1word"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexhistperiod1multi"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexhistperiod1zip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexhistperiod1hand" && (
          <>
            <a
              href="/ai/writing/edexhistperiod1"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexhistperiod1batch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexhistperiod1word"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexhistperiod1multi"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexhistperiod1zip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexhistperiod1batch" && (
          <>
            <a
              href="/ai/writing/edexhistperiod1"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexhistperiod1hand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexhistperiod1word"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexhistperiod1multi"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexhistperiod1zip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexhistperiod1word" && (
          <>
            <a
              href="/ai/writing/edexhistperiod1"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexhistperiod1hand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexhistperiod1batch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexhistperiod1multi"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexhistperiod1zip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexhistperiod1multi" && (
          <>
            <a
              href="/ai/writing/edexhistperiod1"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexhistperiod1hand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexhistperiod1batch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexhistperiod1word"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexhistperiod1zip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexhistperiod1zip" && (
          <>
            <a
              href="/ai/writing/edexhistperiod1"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexhistperiod1hand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexhistperiod1batch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexhistperiod1word"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexhistperiod1multi"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/britishdepth" && (
          <>
            <a
              href="/ai/writing/britishdepthhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/britishdepthbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/britishdepthword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/britishdepthmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/britishdepthzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/britishdepthhand" && (
          <>
            <a
              href="/ai/britishdepth"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/britishdepthbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/britishdepthword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/britishdepthmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/britishdepthzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/britishdepthbatch" && (
          <>
            <a
              href="/ai/britishdepth"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/britishdepthhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/britishdepthword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/britishdepthmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/britishdepthzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/britishdepthword" && (
          <>
            <a
              href="/ai/britishdepth"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/britishdepthhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/britishdepthbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/britishdepthmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/britishdepthzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/britishdepthmulti" && (
          <>
            <a
              href="/ai/britishdepth"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/britishdepthhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/britishdepthbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/britishdepthword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/britishdepthzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/britishdepthzip" && (
          <>
            <a
              href="/ai/britishdepth"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/britishdepthhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/britishdepthbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/britishdepthword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/britishdepthmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/britishstatement" && (
          <>
            <a
              href="/ai/writing/britishstatementhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/britishstatementbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/britishstatementword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/britishstatementmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/britishstatementzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/britishstatementhand" && (
          <>
            <a
              href="/ai/britishstatement"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/britishstatementbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/britishstatementword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/britishstatementmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/britishstatementzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/britishstatementbatch" && (
          <>
            <a
              href="/ai/britishstatement"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/britishstatementhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/britishstatementword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/britishstatementmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/britishstatementzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/britishstatementword" && (
          <>
            <a
              href="/ai/britishstatement"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/britishstatementhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/britishstatementbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/britishstatementmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/britishstatementzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/britishstatementmulti" && (
          <>
            <a
              href="/ai/britishstatement"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/britishstatementhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/britishstatementbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/britishstatementword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/britishstatementzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/britishstatementzip" && (
          <>
            <a
              href="/ai/britishstatement"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/britishstatementhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/britishstatementbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/britishstatementword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/britishstatementmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/moderndepth" && (
          <>
            <a
              href="/ai/writing/moderndepthhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/moderndepthbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/moderndepthword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/moderndepthmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/moderndepthzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/moderndepthhand" && (
          <>
            <a
              href="/ai/moderndepth"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/moderndepthbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/moderndepthword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/moderndepthmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/moderndepthzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/moderndepthbatch" && (
          <>
            <a
              href="/ai/moderndepth"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/moderndepthhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/moderndepthword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/moderndepthmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/moderndepthzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/moderndepthword" && (
          <>
            <a
              href="/ai/moderndepth"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/moderndepthhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/moderndepthbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/moderndepthmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/moderndepthzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/moderndepthmulti" && (
          <>
            <a
              href="/ai/moderndepth"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/moderndepthhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/moderndepthbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/moderndepthword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/moderndepthzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/moderndepthzip" && (
          <>
            <a
              href="/ai/moderndepth"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/moderndepthhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/moderndepthbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/moderndepthword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/moderndepthmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/moderntwenty" && (
          <>
            <a
              href="/ai/writing/moderntwentyhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/moderntwentybatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/moderntwentyword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/moderntwentymulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/moderntwentyzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/moderntwentyhand" && (
          <>
            <a
              href="/ai/moderntwenty"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/moderntwentybatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/moderntwentyword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/moderntwentymulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/moderntwentyzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/moderntwentybatch" && (
          <>
            <a
              href="/ai/moderntwenty"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/moderntwentyhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/moderntwentyword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/moderntwentymulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/moderntwentyzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/moderntwentyword" && (
          <>
            <a
              href="/ai/moderntwenty"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/moderntwentyhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/moderntwentybatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/moderntwentymulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/moderntwentyzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/moderntwentymulti" && (
          <>
            <a
              href="/ai/moderntwenty"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/moderntwentyhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/moderntwentybatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/moderntwentyword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/moderntwentyzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/moderntwentyzip" && (
          <>
            <a
              href="/ai/moderntwenty"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/moderntwentyhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/moderntwentybatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/moderntwentyword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/moderntwentymulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/hkdseone" && (
          <>
            <a
              href="/ai/writing/hkdseonehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/hkdseonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/hkdseoneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/hkdseonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/hkdseonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/hkdseonehand" && (
          <>
            <a
              href="/ai/writing/hkdseone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/hkdseonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/hkdseoneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/hkdseonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/hkdseonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/hkdseonebatch" && (
          <>
            <a
              href="/ai/writing/hkdseone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/hkdseonehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/hkdseoneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/hkdseonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/hkdseonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/hkdseoneword" && (
          <>
            <a
              href="/ai/writing/hkdseone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/hkdseonehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/hkdseonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/hkdseonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/hkdseonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/hkdseonemulti" && (
          <>
            <a
              href="/ai/writing/hkdseone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/hkdseonehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/hkdseonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/hkdseoneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/hkdseonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/hkdseonezip" && (
          <>
            <a
              href="/ai/writing/hkdseone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/hkdseonehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/hkdseonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/hkdseoneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/hkdseonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/hkdsetwo" && (
          <>
            <a
              href="/ai/writing/hkdsetwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/hkdsetwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/hkdsetwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/hkdsetwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/hkdsetwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/hkdsetwohand" && (
          <>
            <a
              href="/ai/writing/hkdsetwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/hkdsetwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/hkdsetwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/hkdsetwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/hkdsetwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/hkdsetwobatch" && (
          <>
            <a
              href="/ai/writing/hkdsetwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/hkdsetwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/hkdsetwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/hkdsetwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/hkdsetwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/hkdsetwoword" && (
          <>
            <a
              href="/ai/writing/hkdsetwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/hkdsetwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/hkdsetwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/hkdsetwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/hkdsetwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/hkdsetwomulti" && (
          <>
            <a
              href="/ai/writing/hkdsetwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/hkdsetwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/hkdsetwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/hkdsetwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/hkdsetwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/hkdsetwozip" && (
          <>
            <a
              href="/ai/writing/hkdsetwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/hkdsetwohand"
              className="ml-4 bg-green-500 hover-bg-green-600text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/hkdsetwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/hkdsetwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/hkdsetwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocronethree" && (
          <>
            <a
              href="/ai/writing/histocronethreehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocronethreebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocronethreeword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocronethreemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocronethreezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocronethreehand" && (
          <>
            <a
              href="/ai/writing/histocronethree"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocronethreebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocronethreeword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocronethreemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocronethreezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocronethreebatch" && (
          <>
            <a
              href="/ai/writing/histocronethree"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocronethreehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocronethreeword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocronethreemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocronethreezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocronethreeword" && (
          <>
            <a
              href="/ai/writing/histocronethree"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocronethreehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocronethreebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocronethreemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocronethreezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocronethreemulti" && (
          <>
            <a
              href="/ai/writing/histocronethree"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocronethreehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocronethreebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocronethreeword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocronethreezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocronethreezip" && (
          <>
            <a
              href="/ai/writing/histocronethree"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocronethreehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocronethreebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocronethreeword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocronethreemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhistonetwo" && (
          <>
            <a
              href="/ai/writing/ocrhistonetwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhistonetwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhistonetwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhistonetwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhistonetwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhistonetwohand" && (
          <>
            <a
              href="/ai/writing/ocrhistonetwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhistonetwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhistonetwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhistonetwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhistonetwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhistonetwobatch" && (
          <>
            <a
              href="/ai/writing/ocrhistonetwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhistonetwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhistonetwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhistonetwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhistonetwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhistonetwoword" && (
          <>
            <a
              href="/ai/writing/ocrhistonetwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhistonetwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhistonetwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhistonetwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhistonetwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhistonetwomulti" && (
          <>
            <a
              href="/ai/writing/ocrhistonetwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhistonetwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhistonetwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhistonetwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhistonetwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhistonetwozip" && (
          <>
            <a
              href="/ai/writing/ocrhistonetwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhistonetwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhistonetwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhistonetwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhistonetwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocroneimage" && (
          <>
            <a
              href="/ai/writing/histocroneimagehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocroneimagebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocroneimageword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocroneimagemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocroneimagezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocroneimagehand" && (
          <>
            <a
              href="/ai/writing/histocroneimage"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocroneimagebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocroneimageword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocroneimagemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocroneimagezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocroneimagebatch" && (
          <>
            <a
              href="/ai/writing/histocroneimage"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocroneimagehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocroneimageword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocroneimagemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocroneimagezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocroneimageword" && (
          <>
            <a
              href="/ai/writing/histocroneimage"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocroneimagehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocroneimagebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocroneimagemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocroneimagezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocroneimagemulti" && (
          <>
            <a
              href="/ai/writing/histocroneimage"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocroneimagehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocroneimagebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocroneimageword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocroneimagezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocroneimagezip" && (
          <>
            <a
              href="/ai/writing/histocroneimage"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocroneimagehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocroneimagebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocroneimageword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocroneimagemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhisttwoone" && (
          <>
            <a
              href="/ai/writing/ocrhisttwoonehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhisttwoonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhisttwooneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhisttwoonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhisttwoonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhisttwoonehand" && (
          <>
            <a
              href="/ai/writing/ocrhisttwoone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhisttwoonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhisttwooneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhisttwoonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhisttwoonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhisttwoonebatch" && (
          <>
            <a
              href="/ai/writing/ocrhisttwoone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhisttwoonehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhisttwooneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhisttwoonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhisttwoonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhisttwooneword" && (
          <>
            <a
              href="/ai/writing/ocrhisttwoone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhisttwoonehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhisttwoonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhisttwoonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhisttwoonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhisttwoonemulti" && (
          <>
            <a
              href="/ai/writing/ocrhisttwoone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhisttwoonehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhisttwoonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhisttwooneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhisttwoonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhisttwoonezip" && (
          <>
            <a
              href="/ai/writing/ocrhisttwoone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhisttwoonehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhisttwoonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhisttwooneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhisttwoonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhistoneone" && (
          <>
            <a
              href="/ai/writing/ocrhistoneonehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhistoneonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhistoneoneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhistoneonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhistoneonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhistoneonehand" && (
          <>
            <a
              href="/ai/writing/ocrhistoneone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhistoneonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhistoneoneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhistoneonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhistoneonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhistoneonebatch" && (
          <>
            <a
              href="/ai/writing/ocrhistoneone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhistoneonehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhistoneoneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhistoneonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhistoneonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhistoneoneword" && (
          <>
            <a
              href="/ai/writing/ocrhistoneone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhistoneonehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhistoneonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhistoneonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhistoneonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhistoneonemulti" && (
          <>
            <a
              href="/ai/writing/ocrhistoneone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhistoneonehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhistoneonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhistoneoneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhistoneonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhistoneonezip" && (
          <>
            <a
              href="/ai/writing/ocrhistoneone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhistoneonehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhistoneonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhistoneoneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhistoneonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhisttwotwo" && (
          <>
            <a
              href="/ai/writing/ocrhisttwotwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhisttwotwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhisttwotwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhisttwotwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhisttwotwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhisttwotwohand" && (
          <>
            <a
              href="/ai/writing/ocrhisttwotwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhisttwotwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhisttwotwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhisttwotwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhisttwotwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhisttwotwobatch" && (
          <>
            <a
              href="/ai/writing/ocrhisttwotwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhisttwotwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhisttwotwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhisttwotwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhisttwotwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhisttwotwoword" && (
          <>
            <a
              href="/ai/writing/ocrhisttwotwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhisttwotwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhisttwotwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhisttwotwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhisttwotwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhisttwotwomulti" && (
          <>
            <a
              href="/ai/writing/ocrhisttwotwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhisttwotwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhisttwotwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhisttwotwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhisttwotwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhisttwotwozip" && (
          <>
            <a
              href="/ai/writing/ocrhisttwotwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhisttwotwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhisttwotwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhisttwotwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhisttwotwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhistthreeone" && (
          <>
            <a
              href="/ai/writing/ocrhistthreeonehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhistthreeonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhistthreeoneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhistthreeonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhistthreeonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhistthreeonehand" && (
          <>
            <a
              href="/ai/writing/ocrhistthreeone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhistthreeonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhistthreeoneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhistthreeonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhistthreeonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhistthreeonebatch" && (
          <>
            <a
              href="/ai/writing/ocrhistthreeone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhistthreeonehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhistthreeoneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhistthreeonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhistthreeonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhistthreeoneword" && (
          <>
            <a
              href="/ai/writing/ocrhistthreeone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhistthreeonehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhistthreeonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhistthreeonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhistthreeonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhistthreeonemulti" && (
          <>
            <a
              href="/ai/writing/ocrhistthreeone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhistthreeonehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhistthreeonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhistthreeoneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhistthreeonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhistthreeonezip" && (
          <>
            <a
              href="/ai/writing/ocrhistthreeone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhistthreeonehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhistthreeonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhistthreeoneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhistthreeonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhistthreetwo" && (
          <>
            <a
              href="/ai/writing/ocrhistthreetwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhistthreetwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhistthreetwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhistthreetwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhistthreetwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhistthreetwohand" && (
          <>
            <a
              href="/ai/writing/ocrhistthreetwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhistthreetwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhistthreetwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhistthreetwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhistthreetwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhistthreetwobatch" && (
          <>
            <a
              href="/ai/writing/ocrhistthreetwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhistthreetwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhistthreetwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhistthreetwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhistthreetwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhistthreetwoword" && (
          <>
            <a
              href="/ai/writing/ocrhistthreetwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhistthreetwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhistthreetwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhistthreetwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhistthreetwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhistthreetwomulti" && (
          <>
            <a
              href="/ai/writing/ocrhistthreetwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhistthreetwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhistthreetwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhistthreetwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhistthreetwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhistthreetwozip" && (
          <>
            <a
              href="/ai/writing/ocrhistthreetwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhistthreetwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhistthreetwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhistthreetwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhistthreetwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychevaleight" && (
          <>
            <a
              href="/ai/writing/edexpsychevaleighthand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychevaleightbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychevaleightword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychevaleightmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychevaleightzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychevaleighthand" && (
          <>
            <a
              href="/ai/writing/edexpsychevaleight"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychevaleightbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychevaleightword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychevaleightmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychevaleightzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychevaleightbatch" && (
          <>
            <a
              href="/ai/writing/edexpsychevaleight"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychevaleighthand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychevaleightword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychevaleightmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychevaleightzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychevaleightword" && (
          <>
            <a
              href="/ai/writing/edexpsychevaleight"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychevaleighthand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychevaleightbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychevaleightmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychevaleightzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychevaleightmulti" && (
          <>
            <a
              href="/ai/writing/edexpsychevaleight"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychevaleighthand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychevaleightbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychevaleightword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychevaleightzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychevaleightzip" && (
          <>
            <a
              href="/ai/writing/edexpsychevaleight"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychevaleighthand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychevaleightbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychevaleightword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychevaleightmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychanalyseeight" && (
          <>
            <a
              href="/ai/writing/edexpsychanalyseeighthand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychanalyseeightbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychanalyseeightword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychanalyseeightmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychanalyseeightzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychanalyseeighthand" && (
          <>
            <a
              href="/ai/writing/edexpsychanalyseeight"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychanalyseeightbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychanalyseeightword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychanalyseeightmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychanalyseeightzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychanalyseeightbatch" && (
          <>
            <a
              href="/ai/writing/edexpsychanalyseeight"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychanalyseeighthand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychanalyseeightword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychanalyseeightmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychanalyseeightzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychanalyseeightword" && (
          <>
            <a
              href="/ai/writing/edexpsychanalyseeight"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychanalyseeighthand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychanalyseeightbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychanalyseeightmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychanalyseeightzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychanalyseeightmulti" && (
          <>
            <a
              href="/ai/writing/edexpsychanalyseeight"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychanalyseeighthand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychanalyseeightbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychanalyseeightword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychanalyseeightzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychanalyseeightzip" && (
          <>
            <a
              href="/ai/writing/edexpsychanalyseeight"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychanalyseeighthand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychanalyseeightbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychanalyseeightword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychanalyseeightmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychdiscusseight" && (
          <>
            <a
              href="/ai/writing/edexpsychdiscusseighthand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychdiscusseightbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychdiscusseightword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychdiscusseightmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychdiscusseightzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychdiscusseighthand" && (
          <>
            <a
              href="/ai/writing/edexpsychdiscusseight"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychdiscusseightbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychdiscusseightword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychdiscusseightmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychdiscusseightzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychdiscusseightbatch" && (
          <>
            <a
              href="/ai/writing/edexpsychdiscusseight"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychdiscusseighthand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychdiscusseightword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychdiscusseightmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychdiscusseightzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychdiscusseightword" && (
          <>
            <a
              href="/ai/writing/edexpsychdiscusseight"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychdiscusseighthand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychdiscusseightbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychdiscusseightmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychdiscusseightzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychdiscusseightmulti" && (
          <>
            <a
              href="/ai/writing/edexpsychdiscusseight"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychdiscusseighthand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychdiscusseightbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychdiscusseightword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychdiscusseightzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychdiscusseightzip" && (
          <>
            <a
              href="/ai/writing/edexpsychdiscusseight"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychdiscusseighthand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychdiscusseightbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychdiscusseightword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychdiscusseightmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychevaltwenty" && (
          <>
            <a
              href="/ai/writing/edexpsychevaltwentyhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychevaltwentybatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychevaltwentyword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychevaltwentymulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychevaltwentyzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychevaltwentyhand" && (
          <>
            <a
              href="/ai/writing/edexpsychevaltwenty"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychevaltwentybatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychevaltwentyword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychevaltwentymulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychevaltwentyzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychevaltwentybatch" && (
          <>
            <a
              href="/ai/writing/edexpsychevaltwenty"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychevaltwentyhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychevaltwentyword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychevaltwentymulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychevaltwentyzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychevaltwentyword" && (
          <>
            <a
              href="/ai/writing/edexpsychevaltwenty"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychevaltwentyhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychevaltwentybatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychevaltwentymulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychevaltwentyzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychevaltwentymulti" && (
          <>
            <a
              href="/ai/writing/edexpsychevaltwenty"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychevaltwentyhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychevaltwentybatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychevaltwentyword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychevaltwentyzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychevaltwentyzip" && (
          <>
            <a
              href="/ai/writing/edexpsychevaltwenty"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychevaltwentyhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychevaltwentybatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychevaltwentyword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychevaltwentymulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocronetwo" && (
          <>
            <a
              href="/ai/writing/histocronetwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocronetwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocronetwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocronetwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocronetwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocronetwohand" && (
          <>
            <a
              href="/ai/writing/histocronetwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocronetwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocronetwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocronetwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocronetwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocronetwobatch" && (
          <>
            <a
              href="/ai/writing/histocronetwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocronetwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocronetwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocronetwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocronetwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocronetwoword" && (
          <>
            <a
              href="/ai/writing/histocronetwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocronetwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocronetwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocronetwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocronetwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocronetwomulti" && (
          <>
            <a
              href="/ai/writing/histocronetwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocronetwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocronetwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocronetwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocronetwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocronetwozip" && (
          <>
            <a
              href="/ai/writing/histocronetwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocronetwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocronetwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocronetwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocronetwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/hisocrnonbritten" && (
          <>
            <a
              href="/ai/writing/hisocrnonbrittenhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/hisocrnonbrittenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/hisocrnonbrittenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/hisocrnonbrittenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/hisocrnonbrittenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/hisocrnonbrittenhand" && (
          <>
            <a
              href="/ai/writing/hisocrnonbritten"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/hisocrnonbrittenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/hisocrnonbrittenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/hisocrnonbrittenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/hisocrnonbrittenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/hisocrnonbrittenbatch" && (
          <>
            <a
              href="/ai/writing/hisocrnonbritten"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/hisocrnonbrittenhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/hisocrnonbrittenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/hisocrnonbrittenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/hisocrnonbrittenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/hisocrnonbrittenword" && (
          <>
            <a
              href="/ai/writing/hisocrnonbritten"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/hisocrnonbrittenhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/hisocrnonbrittenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/hisocrnonbrittenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/hisocrnonbrittenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/hisocrnonbrittenmulti" && (
          <>
            <a
              href="/ai/writing/hisocrnonbritten"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/hisocrnonbrittenhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/hisocrnonbrittenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/hisocrnonbrittenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/hisocrnonbrittenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/hisocrnonbrittenzip" && (
          <>
            <a
              href="/ai/writing/hisocrnonbritten"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/hisocrnonbrittenhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/hisocrnonbrittenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/hisocrnonbrittenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/hisocrnonbrittenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/hostocroneone" && (
          <>
            <a
              href="/ai/writing/hostocroneonehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/hostocroneonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/hostocroneoneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/hostocroneonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/hostocroneonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/hostocroneonehand" && (
          <>
            <a
              href="/ai/writing/hostocroneone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/hostocroneonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/hostocroneoneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/hostocroneonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/hostocroneonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/hostocroneonebatch" && (
          <>
            <a
              href="/ai/writing/hostocroneone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/hostocroneonehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/hostocroneoneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/hostocroneonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/hostocroneonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/hostocroneoneword" && (
          <>
            <a
              href="/ai/writing/hostocroneone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/hostocroneonehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/hostocroneonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/hostocroneonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/hostocroneonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/hostocroneonemulti" && (
          <>
            <a
              href="/ai/writing/hostocroneone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/hostocroneonehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/hostocroneonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/hostocroneoneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/hostocroneonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/hostocroneonezip" && (
          <>
            <a
              href="/ai/writing/hostocroneone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/hostocroneonehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/hostocroneonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/hostocroneoneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/hostocroneonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocreighteen" && (
          <>
            <a
              href="/ai/writing/histocreighteenhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocreighteenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocreighteenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocreighteenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocreighteenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocreighteenhand" && (
          <>
            <a
              href="/ai/writing/histocreighteen"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocreighteenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocreighteenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocreighteenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocreighteenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocreighteenbatch" && (
          <>
            <a
              href="/ai/writing/histocreighteen"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocreighteenhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocreighteenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocreighteenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocreighteenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocreighteenword" && (
          <>
            <a
              href="/ai/writing/histocreighteen"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocreighteenhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocreighteenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocreighteenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocreighteenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocreighteenmulti" && (
          <>
            <a
              href="/ai/writing/histocreighteen"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocreighteenhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocreighteenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocreighteenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocreighteenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocreighteenzip" && (
          <>
            <a
              href="/ai/writing/histocreighteen"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocreighteenhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocreighteenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocreighteenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocreighteenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthematiceight" && (
          <>
            <a
              href="/ai/writing/histocrthematiceighthand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocrthematiceightbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocrthematiceightword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocrthematiceightmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocrthematiceightzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthematiceighthand" && (
          <>
            <a
              href="/ai/writing/histocrthematiceight"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocrthematiceightbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocrthematiceightword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocrthematiceightmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocrthematiceightzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthematiceightbatch" && (
          <>
            <a
              href="/ai/writing/histocrthematiceight"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocrthematiceighthand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocrthematiceightword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocrthematiceightmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocrthematiceightzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthematiceightword" && (
          <>
            <a
              href="/ai/writing/histocrthematiceight"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocrthematiceighthand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocrthematiceightbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocrthematiceightmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocrthematiceightzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthematiceightmulti" && (
          <>
            <a
              href="/ai/writing/histocrthematiceight"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocrthematiceighthand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocrthematiceightbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocrthematiceightword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocrthematiceightzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthematiceightzip" && (
          <>
            <a
              href="/ai/writing/histocrthematiceight"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocrthematiceighthand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocrthematiceightbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocrthematiceightword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocrthematiceightmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthematicfourteen" && (
          <>
            <a
              href="/ai/writing/histocrthematicfourteenhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocrthematicfourteenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocrthematicfourteenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocrthematicfourteenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocrthematicfourteenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthematicfourteenhand" && (
          <>
            <a
              href="/ai/writing/histocrthematicfourteen"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocrthematicfourteenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocrthematicfourteenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocrthematicfourteenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocrthematicfourteenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthematicfourteenbatch" && (
          <>
            <a
              href="/ai/writing/histocrthematicfourteen"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocrthematicfourteenhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocrthematicfourteenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocrthematicfourteenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocrthematicfourteenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthematicfourteenword" && (
          <>
            <a
              href="/ai/writing/histocrthematicfourteen"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocrthematicfourteenhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocrthematicfourteenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocrthematicfourteenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocrthematicfourteenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthematicfourteenmulti" && (
          <>
            <a
              href="/ai/writing/histocrthematicfourteen"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocrthematicfourteenhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocrthematicfourteenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocrthematicfourteenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocrthematicfourteenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthematicfourteenzip" && (
          <>
            <a
              href="/ai/writing/histocrthematicfourteen"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocrthematicfourteenhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocrthematicfourteenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocrthematicfourteenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocrthematicfourteenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocronefour" && (
          <>
            <a
              href="/ai/writing/histocronefourhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocronefourbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocronefourword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocronefourmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocronefourzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocronefourhand" && (
          <>
            <a
              href="/ai/writing/histocronefour"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocronefourbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocronefourword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocronefourmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocronefourzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocronefourbatch" && (
          <>
            <a
              href="/ai/writing/histocronefour"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocronefourhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocronefourword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocronefourmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocronefourzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocronefourword" && (
          <>
            <a
              href="/ai/writing/histocronefour"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocronefourhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocronefourbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocronefourmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocronefourzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocronefourmulti" && (
          <>
            <a
              href="/ai/writing/histocronefour"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocronefourhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocronefourbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocronefourword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocronefourzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocronefourzip" && (
          <>
            <a
              href="/ai/writing/histocronefour"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocronefourhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocronefourbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocronefourword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocronefourmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhisnonbritishfive" && (
          <>
            <a
              href="/ai/writing/ocrhisnonbritishfivehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhisnonbritishfivebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhisnonbritishfiveword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhisnonbritishfivemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhisnonbritishfivezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhisnonbritishfivehand" && (
          <>
            <a
              href="/ai/writing/ocrhisnonbritishfive"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhisnonbritishfivebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhisnonbritishfiveword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhisnonbritishfivemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhisnonbritishfivezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhisnonbritishfivebatch" && (
          <>
            <a
              href="/ai/writing/ocrhisnonbritishfive"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhisnonbritishfivehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhisnonbritishfiveword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhisnonbritishfivemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhisnonbritishfivezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhisnonbritishfiveword" && (
          <>
            <a
              href="/ai/writing/ocrhisnonbritishfive"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhisnonbritishfivehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhisnonbritishfivebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhisnonbritishfivemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/ocrhisnonbritishfivezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhisnonbritishfivemulti" && (
          <>
            <a
              href="/ai/writing/ocrhisnonbritishfive"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhisnonbritishfivehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhisnonbritishfivebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhisnonbritishfiveword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhisnonbritishfivezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/ocrhisnonbritishfivezip" && (
          <>
            <a
              href="/ai/writing/ocrhisnonbritishfive"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/ocrhisnonbritishfivehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/ocrhisnonbritishfivebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/ocrhisnonbritishfiveword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/ocrhisnonbritishfivemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthreetwenty" && (
          <>
            <a
              href="/ai/writing/histocrthreetwentyhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocrthreetwentybatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocrthreetwentyword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocrthreetwentymulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocrthreetwentyzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthreetwentyhand" && (
          <>
            <a
              href="/ai/writing/histocrthreetwenty"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocrthreetwentybatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocrthreetwentyword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocrthreetwentymulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocrthreetwentyzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthreetwentybatch" && (
          <>
            <a
              href="/ai/writing/histocrthreetwenty"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocrthreetwentyhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocrthreetwentyword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocrthreetwentymulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocrthreetwentyzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthreetwentyword" && (
          <>
            <a
              href="/ai/writing/histocrthreetwenty"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocrthreetwentyhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocrthreetwentybatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocrthreetwentymulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocrthreetwentyzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthreetwentymulti" && (
          <>
            <a
              href="/ai/writing/histocrthreetwenty"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocrthreetwentyhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocrthreetwentybatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocrthreetwentyword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocrthreetwentyzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthreetwentyzip" && (
          <>
            <a
              href="/ai/writing/histocrthreetwenty"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocrthreetwentyhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocrthreetwentybatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocrthreetwentyword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocrthreetwentymulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthreeten" && (
          <>
            <a
              href="/ai/writing/histocrthreetenhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocrthreetenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocrthreetenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocrthreetenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocrthreetenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthreetenhand" && (
          <>
            <a
              href="/ai/writing/histocrthreeten"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocrthreetenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocrthreetenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocrthreetenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocrthreetenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthreetenbatch" && (
          <>
            <a
              href="/ai/writing/histocrthreeten"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocrthreetenhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocrthreetenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocrthreetenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocrthreetenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthreetenword" && (
          <>
            <a
              href="/ai/writing/histocrthreeten"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocrthreetenhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocrthreetenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocrthreetenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocrthreetenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthreetenmulti" && (
          <>
            <a
              href="/ai/writing/histocrthreeten"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocrthreetenhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocrthreetenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocrthreetenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocrthreetenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthreetenzip" && (
          <>
            <a
              href="/ai/writing/histocrthreeten"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocrthreetenhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocrthreetenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocrthreetenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocrthreetenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthreetenwritten" && (
          <>
            <a
              href="/ai/writing/histocrthreetenwrittenhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocrthreetenwrittenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocrthreetenwrittenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocrthreetenwrittenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocrthreetenwrittenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthreetenwrittenhand" && (
          <>
            <a
              href="/ai/writing/histocrthreetenwritten"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocrthreetenwrittenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocrthreetenwrittenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocrthreetenwrittenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocrthreetenwrittenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthreetenwrittenbatch" && (
          <>
            <a
              href="/ai/writing/histocrthreetenwritten"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocrthreetenwrittenhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocrthreetenwrittenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocrthreetenwrittenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocrthreetenwrittenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthreetenwrittenword" && (
          <>
            <a
              href="/ai/writing/histocrthreetenwritten"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocrthreetenwrittenhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocrthreetenwrittenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocrthreetenwrittenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/histocrthreetenwrittenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthreetenwrittenmulti" && (
          <>
            <a
              href="/ai/writing/histocrthreetenwritten"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocrthreetenwrittenhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocrthreetenwrittenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocrthreetenwrittenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocrthreetenwrittenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/histocrthreetenwrittenzip" && (
          <>
            <a
              href="/ai/writing/histocrthreetenwritten"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/histocrthreetenwrittenhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/histocrthreetenwrittenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/histocrthreetenwrittenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/histocrthreetenwrittenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychtwentyextent" && (
          <>
            <a
              href="/ai/writing/edexpsychtwentyextenthand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychtwentyextentbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychtwentyextentword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychtwentyextentmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychtwentyextentzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychtwentyextenthand" && (
          <>
            <a
              href="/ai/writing/edexpsychtwentyextent"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychtwentyextentbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychtwentyextentword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychtwentyextentmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychtwentyextentzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychtwentyextentbatch" && (
          <>
            <a
              href="/ai/writing/edexpsychtwentyextent"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychtwentyextenthand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychtwentyextentword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychtwentyextentmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychtwentyextentzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychtwentyextentword" && (
          <>
            <a
              href="/ai/writing/edexpsychtwentyextent"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychtwentyextenthand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychtwentyextentbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychtwentyextentmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychtwentyextentzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychtwentyextentmulti" && (
          <>
            <a
              href="/ai/writing/edexpsychtwentyextent"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychtwentyextenthand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychtwentyextentbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychtwentyextentword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychtwentyextentzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychtwentyextentzip" && (
          <>
            <a
              href="/ai/writing/edexpsychtwentyextent"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychtwentyextenthand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychtwentyextentbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychtwentyextentword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychtwentyextentmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychtwentyassess" && (
          <>
            <a
              href="/ai/writing/edexpsychtwentyassesshand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychtwentyassessbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychtwentyassessword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychtwentyassessmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychtwentyassesszip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychtwentyassesshand" && (
          <>
            <a
              href="/ai/writing/edexpsychtwentyassess"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychtwentyassessbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychtwentyassessword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychtwentyassessmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychtwentyassesszip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychtwentyassessbatch" && (
          <>
            <a
              href="/ai/writing/edexpsychtwentyassess"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychtwentyassesshand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychtwentyassessword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychtwentyassessmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychtwentyassesszip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychtwentyassessword" && (
          <>
            <a
              href="/ai/writing/edexpsychtwentyassess"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychtwentyassesshand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychtwentyassessbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychtwentyassessmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychtwentyassesszip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychtwentyassessmulti" && (
          <>
            <a
              href="/ai/writing/edexpsychtwentyassess"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychtwentyassesshand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychtwentyassessbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychtwentyassessword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychtwentyassesszip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychtwentyassesszip" && (
          <>
            <a
              href="/ai/writing/edexpsychtwentyassess"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychtwentyassesshand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychtwentyassessbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychtwentyassessword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychtwentyassessmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychevalsixteen" && (
          <>
            <a
              href="/ai/writing/edexpsychevalsixteenhand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychevalsixteenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychevalsixteenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychevalsixteenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychevalsixteenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychevalsixteenhand" && (
          <>
            <a
              href="/ai/writing/edexpsychevalsixteen"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychevalsixteenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychevalsixteenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychevalsixteenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychevalsixteenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychevalsixteenbatch" && (
          <>
            <a
              href="/ai/writing/edexpsychevalsixteen"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychevalsixteenhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychevalsixteenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychevalsixteenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychevalsixteenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychevalsixteenword" && (
          <>
            <a
              href="/ai/writing/edexpsychevalsixteen"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychevalsixteenhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychevalsixteenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychevalsixteenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/edexpsychevalsixteenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychevalsixteenmulti" && (
          <>
            <a
              href="/ai/writing/edexpsychevalsixteen"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychevalsixteenhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychevalsixteenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychevalsixteenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychevalsixteenzip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/edexpsychevalsixteenzip" && (
          <>
            <a
              href="/ai/writing/edexpsychevalsixteen"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/edexpsychevalsixteenhand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/edexpsychevalsixteenbatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/edexpsychevalsixteenword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/edexpsychevalsixteenmulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/texasengone" && (
          <>
            <a
              href="/ai/writing/texasengonehand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/texasengonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/texasengoneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/texasengonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/texasengonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/texasengonehand" && (
          <>
            <a
              href="/ai/writing/texasengone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/texasengonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/texasengoneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/texasengonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/texasengonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/texasengonebatch" && (
          <>
            <a
              href="/ai/writing/texasengone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/texasengonehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/texasengoneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/texasengonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/texasengonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/texasengoneword" && (
          <>
            <a
              href="/ai/writing/texasengone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/texasengonehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/texasengonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/texasengonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/texasengonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/texasengonemulti" && (
          <>
            <a
              href="/ai/writing/texasengone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/texasengonehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/texasengonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/texasengoneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/texasengonezip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/texasengonezip" && (
          <>
            <a
              href="/ai/writing/texasengone"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/texasengonehand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/texasengonebatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/texasengoneword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/texasengonemulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/texasengtwo" && (
          <>
            <a
              href="/ai/writing/texasengtwohand"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/texasengtwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/texasengtwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/texasengtwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/texasengtwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/texasengtwohand" && (
          <>
            <a
              href="/ai/writing/texasengtwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/texasengtwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/texasengtwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/texasengtwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/texasengtwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/texasengtwobatch" && (
          <>
            <a
              href="/ai/writing/texasengtwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/texasengtwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/texasengtwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/texasengtwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/texasengtwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/texasengtwoword" && (
          <>
            <a
              href="/ai/writing/texasengtwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/texasengtwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/texasengtwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/texasengtwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
            <a
              href="/ai/writing/texasengtwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/texasengtwomulti" && (
          <>
            <a
              href="/ai/writing/texasengtwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/texasengtwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/texasengtwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/texasengtwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/texasengtwozip"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts - Barcoded Single PDF
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/texasengtwozip" && (
          <>
            <a
              href="/ai/writing/texasengtwo"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Typed
            </a>
            <a
              href="/ai/writing/texasengtwohand"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking: Handwritten Script
            </a>
            <a
              href="/ai/writing/texasengtwobatch"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays
            </a>
            <a
              href="/ai/writing/texasengtwoword"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Typed Essays in Word
            </a>
            <a
              href="/ai/writing/texasengtwomulti"
              className="ml-4 bg-green-500 hover-bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking: Handwritten Scripts
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/pareto" && (
          <>
            <a
              href="/ai/writing/pareto2"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking
            </a>
            <a
              href="/ai/writing/pareto3"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking with Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/pareto2" && (
          <>
            <a
              href="/ai/writing/pareto"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking
            </a>
            <a
              href="/ai/writing/pareto3"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking with Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/pareto3" && (
          <>
            <a
              href="/ai/writing/pareto"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking
            </a>
            <a
              href="/ai/writing/pareto2"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/tarigo" && (
          <>
            <a
              href="/ai/writing/tarigo2"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking
            </a>
            <a
              href="/ai/writing/tarigo3"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking with Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/tarigo2" && (
          <>
            <a
              href="/ai/writing/tarigo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking
            </a>
            <a
              href="/ai/writing/tarigo3"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking with Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/tarigo3" && (
          <>
            <a
              href="/ai/writing/tarigo"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking
            </a>
            <a
              href="/ai/writing/tarigo2"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/tarigosample" && (
          <>
            <a
              href="/ai/writing/tarigosample2"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking
            </a>
            <a
              href="/ai/writing/tarigosample3"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking with Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/tarigosample2" && (
          <>
            <a
              href="/ai/writing/tarigosample"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking
            </a>
            <a
              href="/ai/writing/tarigosample3"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking with Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/tarigosample3" && (
          <>
            <a
              href="/ai/writing/tarigosample"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking
            </a>
            <a
              href="/ai/writing/tarigosample2"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eaton" && (
          <>
            <a
              href="/ai/writing/eaton2"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking
            </a>
            <a
              href="/ai/writing/eaton3"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking with Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eaton2" && (
          <>
            <a
              href="/ai/writing/eaton"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking
            </a>
            <a
              href="/ai/writing/eaton3"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking with Word
            </a>
          </>
        )}

        {location.pathname === "/ai/writing/eaton3" && (
          <>
            <a
              href="/ai/writing/eaton"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Single Essay Marking
            </a>
            <a
              href="/ai/writing/eaton2"
              className="ml-4 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center"
            >
              Batch Marking
            </a>
          </>
        )}
      </div>

      <div className="container mx-auto px-4 md:px-28 pt-4 select-none ">
        {options && (
          <div className="align-bottom bg-white text-left transform transition-all sm:align-middle transition inline-flex ">
            {options.map((option, index) => (
              <Option key={index} currentOption={currentOption} {...option} />
            ))}
          </div>
        )}

        {children}
      </div>
    </div>
  );
}

export function Option({ title, Icon, onClick, currentOption, color }) {
  let active = currentOption === title;
  return (
    <div
      className={`lg:py-4 lg:px-8 py-3 px-6 flex transition text-${
        active ? `${color ? color : "green"}-800` : "gray-500"
      } font-medium border-b -mb-px rounded-t-lg border-${
        active ? `${color ? color : "green"}-500` : "gray-400"
      } bg-${active ? `${color ? color : "green"}-100` : "white"} hover:bg-${
        active ? `${color ? color : "green"}-200` : "gray-100"
      } cursor-pointer `}
      onClick={() => onClick(title)}
    >
      <div
        className={`md:mr-4  transition flex-shrink-0 inline-flex items-center justify-center text-sm h-6 w-6 rounded-full bg-${
          active ? `${color ? color : "green"}` : "gray"
        }-300 text-${active ? `${color ? color : "green"}` : "gray"}-600`}
      >
        <Icon
          className={`h-4 w-4 text-${
            active ? `${color ? color : "green"}` : "gray"
          }-600`}
          aria-hidden="true"
        />
      </div>
      <div className="hidden md:block">{title}</div>
    </div>
  );
}

export default Header;
