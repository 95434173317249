import { ThemeProvider } from 'styled-components'
import React, { Component, useState } from 'react';

import { Provider } from 'mobx-react'
import { observer } from 'mobx-react'

import AppStore from './store'
import colors from 'tailwindcss/colors'
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";

import Header from './Header'
import Search from './Search'
import Pricing from './Pricing'
import Verification from '../src/Verification'
import PaymentDetails from './Profile/PaymentDetails';
import Dashboard from './Dashboard'
import PasswordReset from './Login/PasswordReset';
import Tool from './Core/Tool'
import Chat from './Core/Chat'
import Login from './Login/Login'
import Profile from './Profile/'
import LoginSuccess from './Login/Success'
import ForgotPassword from "./forgotPassword/index";
import ResetPassword from "./forgotPassword/ResetPassword";
import HandwritingModal from './Core/HandwritingModal';
import Handwriting from './tools/Handwriting';
import  handwritingConfig  from './tools/HandwritingConfig';
import HandwritingTool from './tools/HandwritingTool';

import AQAEnglishGCSE from './AQAEnglishGCSE';
import CantabEnglishGCSE from './CantabEnglishGCSE';
import EdexcelEnglishGCSE from './EdexcelEnglishGCSE';
import EdexcelHistoryGCSE from './EdexcelHistoryGCSE';
import EduqasEnglishGCSE from './EduqasEnglishGCSE';

import UserHistory from './UserHistory';


import Cookies from "js-cookie";


import './App.scss'

if(!window.store){
  window.store = new AppStore();
}

@observer
class App extends Component {
 
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  toggleModal = () => {
    this.setState((prevState) => ({ showModal: !prevState.showModal }));
  };
 
 
  componentDidMount() {
    const data = {
      token: Cookies.get("token"),
      profile: Cookies.get("profile"),
    };

    if (data.profile && data.token) {
      window.store.loginWithDataTokenAndProfile(data);
    }
  }

  render() {
    const { showModal } = this.state;

  
    
      return (
        <ThemeProvider theme={colors}>
          <Provider store={window.store}>
            <Router>
              {window.store.redirect ? <Redirect to={window.store.redirect} /> : null }
              {window.store.isLoggedIn ? (
                <>
                  {window.store.profile.status ? (
                    // Logged in with plan
                    <>
                      <Switch>
                        <Route path="/writing/document"><div /></Route>
                        <Route component={Header} />
                      </Switch>
    
                      <Switch>
                        <Route path="/" exact component={Dashboard} />
                        <Route path="/search" exact component={Search} />
                        <Route
                          path="/ai/"
                          render={(props) => (
                            <Tool {...props} showModal={showModal} toggleModal={this.toggleModal} />
                          )}
                        />
                        <Route path="/my-profile" component={Profile} />
                        <Route path="/signup/failed" component={Profile} />
                        <Route path="/signup/success" component={LoginSuccess} />
                        <Route path="/login/success" component={LoginSuccess} />
                        <Route path="/verify" exact component={Verification} />
                        <Route path="/payment-details" exact component={PaymentDetails} />
                        <Route path="/handwriting" exact component={Handwriting} />
                        <Route path="/handwritingtool" exact component={HandwritingTool} />
                        <Route path="/writing/handwritingtool" exact component={HandwritingTool} />
                        <Route path="/aqa-english" exact component={AQAEnglishGCSE} />
                        <Route path="/cantab-english" exact component={CantabEnglishGCSE} />
                        <Route path="/edexcel-english" exact component={EdexcelEnglishGCSE} />
                        <Route path="/edexcel-history" exact component={EdexcelHistoryGCSE} />
                        <Route path="/eduqas-english" exact component={EduqasEnglishGCSE} />
                        <Route path="/history" exact component={UserHistory} />


                      </Switch>
                      <HandwritingModal
  config={handwritingConfig}
  showModal={showModal}
  closeModal={this.toggleModal}
  // Add this line
  onEntered={() => console.log("config", handwritingConfig)}
/>

                    </>
                  ) : (
                    // Logged in but no plan
                    <>
                      <Switch>
                        <Route path="/signup/success" component={LoginSuccess} />
                        <Route>
                          <Pricing />
                        </Route>
                      </Switch>
                    </>
                  )}
                </>
              ) : (
                // Not logged in
                <>
                  <Switch>
                    <Route path="/" exact>
                      <Redirect to="/login" />
                    </Route>
                    <Route path="/forgotPassword" exact>
                      <ForgotPassword />
                    </Route>
                    <Route path="/reset-password" exact>
                      <ResetPassword />
                    </Route>
                    <Route path="/" component={Login} />
                  </Switch>
                </>
              )}
            </Router>
          </Provider>
        </ThemeProvider>
      );
    }}
    
export default App;
