import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { computed,  } from 'mobx'
import MainBody from './Components/Body'
import {Helmet} from "react-helmet"
import logo from './logo.png'
import mouse from './mouse.png' 
import inspector from './Inspector.png' 
import shakespeare from './shakespeare-2.png' 
import power from './power.png' 
import hyde from './hyde.png' 
import brainone from './brainone.png' 
import braintwo from './braintwo.png' 
import love from './love.png' 
import worlds from './worlds.png' 
import macbeth from './macbeth.png' 
import bassanio from './bassanio.png' 
import animalfarm from './animalfarm.png' 
import unseen from './unseen.png' 
import shakegen from './shakesgen.png'
import tyger from './tyger.png'
import elizabeth from './elizabeth.png'
import henry from './henry8.png'
import kennedy from './kennedy.png'
import mao from './mao.png'
import handwriting from './handwriting.png'
import pi from './pi.png'
import hughes from './hughes.png'
import othello from './othello.png'
import sixplus from './edexcelhistory.png'
import sevenplus from './aqaenglish.png'
import eightplus from './edexcelenglish.png'
import nineplus from './aqaenglish.png'
import tenplus from './cambridgeenglish.png'
import elevenplus from './eduqasenglish.png'
import mlk from './mlk.png'
import supreme from './supreme.png'
import vote from './vote.png'
import creativey from './creativey.png'




import './Dashboard.css';


import { observer, inject } from 'mobx-react'
@inject('store')
@observer
class Body extends Component {

	@computed get permissions() {
		return this.props.store.tools.filter(tool=>
			tool.permissions.some(r=> this.props.store.profile.permissions.includes(r))
		)
	}

	@computed get ibenglish() {
		return this.permissions.filter(tool => tool.category === 'ibenglish')
	}


	@computed get experimental() {
		return [
			...this.permissions.filter(tool => tool.category === 'Experimental'),
			{
			  title: 'Turn Handwritten Scripts into Text!',
			  category: 'Experimental',
			  to: '/writing/handwritingtool',
			  desc: 'Use this tool to convert PDFs of handwritten scripts into fully editable text (that we can then mark!).',
			  fromColor: "blue-400",
		  toColor: "blue-600",
			  Icon: null,
			},
		  ];
		}

	@computed get apgov() {
		return this.permissions.filter(tool => tool.category === 'APGov')
	}


	@computed get generators() {
		return this.permissions.filter(tool => tool.category === 'Generators')
	}


	@computed get apenglish() {
		return this.permissions.filter(tool => tool.category === 'APEnglish')
	}


	@computed get aphistory() {
		return this.permissions.filter(tool => tool.category === 'APHistory')
	}

	@computed get regentseng() {
		return this.permissions.filter(tool => tool.category === 'regentseng')
	}

	@computed get ibhistory() {
		return this.permissions.filter(tool => tool.category === 'ibhistory')
	}

	@computed get staareng() {
		return this.permissions.filter(tool => tool.category === 'staareng')
	}

	@computed get global() {
		return this.permissions.filter(tool => tool.category === 'global')
	}

	@computed get handwriting() {
		return this.permissions.filter(tool => tool.category === 'Handwriting')
	}

	render() {
	return (

		<>
			<Helmet>
				<title>{`Tools - Exam Paper Autopilot`}</title>
			</Helmet>
			<MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12 ">

		





			{this.generators.length ? <>
					  </> : null}




					  {this.apenglish.length ? <>
				<Title title={<p><b>AP English:</b> Literature & Composition | Language & Composition</p>} />
				<Grid>
					{this.apenglish.map((tool, index) => 
					  <div key={index} className="tool-container">
					  {index === 0 ? <img src={hughes} alt={tool.title} className="tool-image" /> : 
					  index === 1 ? <img src={pi} alt={tool.title} className="tool-image" /> :
					  index === 2 ? <img src={mouse} alt={tool.title} className="tool-image" /> :
					  index === 3 ? <img src={animalfarm} alt={tool.title} className="tool-image" /> : 
					  index === 4 ? <img src={inspector} alt={tool.title} className="tool-image" /> :
					  index === 5 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> :
					  index === 6 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> : 
					  index === 7 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> : 
					  index === 8 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> : null} 
						<Tool 
							key={index}
							group={tool.category}
							title={tool.title} 
							to={tool.to} 
							Icon={tool.Icon} 
							desc={tool.desc} 
							fromColor={tool.fromColor} 
							toColor={tool.toColor} 
							/>
							</div>
						  )}
						</Grid>
						<Divider />
					  </> : null}


					  {this.aphistory.length ? <>
				<Title title={<p><b>AP History:</b> American History | World History | European History</p>} />
				<Grid>
					{this.aphistory.map((tool, index) => 
					  <div key={index} className="tool-container">
					  {index === 0 ? <img src={kennedy} alt={tool.title} className="tool-image" /> : 
					  index === 1 ? <img src={mao} alt={tool.title} className="tool-image" /> :
					  index === 2 ? <img src={elizabeth} alt={tool.title} className="tool-image" /> :
					  index === 3 ? <img src={animalfarm} alt={tool.title} className="tool-image" /> : 
					  index === 4 ? <img src={inspector} alt={tool.title} className="tool-image" /> :
					  index === 5 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> :
					  index === 6 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> : 
					  index === 7 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> : 
					  index === 8 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> : null} 
						<Tool 
							key={index}
							group={tool.category}
							title={tool.title} 
							to={tool.to} 
							Icon={tool.Icon} 
							desc={tool.desc} 
							fromColor={tool.fromColor} 
							toColor={tool.toColor} 
							/>
							</div>
						  )}
						</Grid>
						<Divider />
					  </> : null}


					  {this.apgov.length ? <>
				<Title title={<p><b>AP US Government & Politics:</b> Free Response Paper</p>} />
				<Grid>
					{this.apgov.map((tool, index) => 
					  <div key={index} className="tool-container">
					  {index === 0 ? <img src={vote} alt={tool.title} className="tool-image" /> : 
					  index === 1 ? <img src={supreme} alt={tool.title} className="tool-image" /> :
					  index === 2 ? <img src={mlk} alt={tool.title} className="tool-image" /> :
					  index === 3 ? <img src={animalfarm} alt={tool.title} className="tool-image" /> : 
					  index === 4 ? <img src={inspector} alt={tool.title} className="tool-image" /> :
					  index === 5 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> :
					  index === 6 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> : 
					  index === 7 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> : 
					  index === 8 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> : null} 
						<Tool 
							key={index}
							group={tool.category}
							title={tool.title} 
							to={tool.to} 
							Icon={tool.Icon} 
							desc={tool.desc} 
							fromColor={tool.fromColor} 
							toColor={tool.toColor} 
							/>
							</div>
						  )}
						</Grid>
						<Divider />
					  </> : null}


					  {this.staareng.length ? <>
				<Title title={<p><b>Texas STAAR:</b> English I | English II</p>} />
				<Grid>
					{this.staareng.map((tool, index) => 
					  <div key={index} className="tool-container">
					  {index === 0 ? <img src={worlds} alt={tool.title} className="tool-image" /> : 
					  index === 1 ? <img src={animalfarm} alt={tool.title} className="tool-image" /> :
					  index === 2 ? <img src={elizabeth} alt={tool.title} className="tool-image" /> :
					  index === 3 ? <img src={animalfarm} alt={tool.title} className="tool-image" /> : 
					  index === 4 ? <img src={inspector} alt={tool.title} className="tool-image" /> :
					  index === 5 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> :
					  index === 6 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> : 
					  index === 7 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> : 
					  index === 8 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> : null} 
						<Tool 
							key={index}
							group={tool.category}
							title={tool.title} 
							to={tool.to} 
							Icon={tool.Icon} 
							desc={tool.desc} 
							fromColor={tool.fromColor} 
							toColor={tool.toColor} 
							/>
							</div>
						  )}
						</Grid>
						<Divider />

					  </> : null}


					  {this.regentseng.length ? <>
				<Title title={<p><b>New York Regents:</b> English Language Arts </p>} />
				<Grid>
					{this.regentseng.map((tool, index) => 
					  <div key={index} className="tool-container">
					  {index === 0 ? <img src={hughes} alt={tool.title} className="tool-image" /> : 
					  index === 1 ? <img src={mao} alt={tool.title} className="tool-image" /> :
					  index === 2 ? <img src={elizabeth} alt={tool.title} className="tool-image" /> :
					  index === 3 ? <img src={animalfarm} alt={tool.title} className="tool-image" /> : 
					  index === 4 ? <img src={inspector} alt={tool.title} className="tool-image" /> :
					  index === 5 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> :
					  index === 6 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> : 
					  index === 7 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> : 
					  index === 8 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> : null} 
						<Tool 
							key={index}
							group={tool.category}
							title={tool.title} 
							to={tool.to} 
							Icon={tool.Icon} 
							desc={tool.desc} 
							fromColor={tool.fromColor} 
							toColor={tool.toColor} 
							/>
							</div>
						  )}
						</Grid>
						<Divider />

					  </> : null}

					  {this.ibhistory.length ? <>
				<Title title={<p><b>IB History</b>: IB History Marking Assistants</p>} />
				<Grid>
    {this.ibhistory.map((tool, index) => 
        <div key={index} className="tool-container">
        {index === 0 ? <img src={kennedy} alt={tool.title} className="tool-image" /> : 
        index === 1 ? <img src={worlds} alt={tool.title} className="tool-image" /> :
		index === 2 ? <img src={tyger} alt={tool.title} className="tool-image" /> :
		index === 3 ? <img src={inspector} alt={tool.title} className="tool-image" /> :
		index === 4 ? <img src={shakespeare} alt={tool.title} className="tool-image" /> :
		null}
        <Tool 
                group={tool.category}
                title={tool.title} 
                to={tool.to} 
                Icon={tool.Icon} 
                desc={tool.desc} 
                fromColor={tool.fromColor} 
                toColor={tool.toColor}
         	   />
        		</div>
 			   )} 
			</Grid>
			<Divider />
			</> : null}

			{this.global.length ? <>
				<Title title={<p><b>IB DP Global Politics</b>: Marking Assistants</p>} />
				<Grid>
    {this.global.map((tool, index) => 
        <div key={index} className="tool-container">
        {index === 0 ? <img src={kennedy} alt={tool.title} className="tool-image" /> : 
        index === 1 ? <img src={worlds} alt={tool.title} className="tool-image" /> :
		index === 2 ? <img src={tyger} alt={tool.title} className="tool-image" /> :
		index === 3 ? <img src={inspector} alt={tool.title} className="tool-image" /> :
		index === 4 ? <img src={shakespeare} alt={tool.title} className="tool-image" /> :
		null}
        <Tool 
                group={tool.category}
                title={tool.title} 
                to={tool.to} 
                Icon={tool.Icon} 
                desc={tool.desc} 
                fromColor={tool.fromColor} 
                toColor={tool.toColor}
         	   />
        		</div>
 			   )} 
			</Grid>
			<Divider />
			</> : null}

			{this.ibenglish.length ? <>
				<Title title={<p><b>IB English</b>: IB English Marking Assistants</p>} />
				<Grid>
    {this.ibenglish.map((tool, index) => 
        <div key={index} className="tool-container">
        {index === 0 ? <img src={unseen} alt={tool.title} className="tool-image" /> : 
        index === 1 ? <img src={mouse} alt={tool.title} className="tool-image" /> :
		index === 2 ? <img src={tyger} alt={tool.title} className="tool-image" /> :
		index === 3 ? <img src={inspector} alt={tool.title} className="tool-image" /> :
		index === 4 ? <img src={animalfarm} alt={tool.title} className="tool-image" /> :
		index === 5 ? <img src={mouse} alt={tool.title} className="tool-image" /> :
		index === 6 ? <img src={tyger} alt={tool.title} className="tool-image" /> :
		index === 7 ? <img src={creativey} alt={tool.title} className="tool-image" /> :
		index === 8 ? <img src={mouse} alt={tool.title} className="tool-image" /> :
		index === 9 ? <img src={tyger} alt={tool.title} className="tool-image" /> :
		index === 10 ? <img src={creativey} alt={tool.title} className="tool-image" /> :
		null}
        <Tool 
                group={tool.category}
                title={tool.title} 
                to={tool.to} 
                Icon={tool.Icon} 
                desc={tool.desc} 
                fromColor={tool.fromColor} 
                toColor={tool.toColor}
         	   />
        		</div>
 			   )} 
			</Grid>
			</> : null}

<title>{`Tools - Exam Paper Autopilot`}</title>
<MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12 ">
<div className="title-container">


    
    {/* Add more buttons for each grid page */}
  </div>


  {this.experimental.length ? <>
	<Divider />
				<Title title={<p><b>Turn Handwritten Work into Editable Text</b></p>} />
				<Grid>
					{this.experimental.map((tool, index) => 
					  <div key={index} className="tool-container">
					  {index === 0 ? <img src={handwriting} alt={tool.title} className="tool-image" /> : 
					  index === 1 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> :
					  index === 2 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> :
					  index === 3 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> : 
					  index === 4 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> :
					  index === 5 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> :
					  index === 6 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> : 
					  index === 7 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> : 
					  index === 8 ? <img src={sevenplus} alt={tool.title} className="tool-image" /> : null} 
						<Tool 
							key={index}
							group={tool.category}
							title={tool.title} 
							to={tool.to} 
							Icon={tool.Icon} 
							desc={tool.desc} 
							fromColor={tool.fromColor} 
							toColor={tool.toColor} 
							/>
							</div>
						  )}
						</Grid>
						<Divider />
					  </> : null}


  
</MainBody>
	

</MainBody>
</>)
}
  }

export const Divider = () => <div className="divide-y-2 divide-dashed divide-gray-300 py-8 md:py-12"> <div></div>
<div></div></div>

export const Title = ({ title }) => <h2 className="text-xl sm:text-2xl md:text-3xl text-gray-700 mb-4 md:mb-6 text-center">
{title}
</h2>

export const Grid = ({ children }) => <div className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-2 xl:grid-cols-3 ">{children}</div>

export const Tool = ({ Icon, title, desc, to, group, fromColor, toColor }) => <Link to={to || "/"} className="flex relative ">
	
	<div className={`bg-white flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border border-gray-300 md:flex relative transform hover:scale-105  hover:text-black`}>
  <div className="p-4">
	<div className={`uppercase tracking-wide text-sm text-${fromColor ? fromColor : "green-500"} font-semibold leading-none`}>{group || "New"}</div>
	<div href="#" className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-black leading-none">{title}</div>
	<p className="mt-1 pr-1 text-sm ">{desc} </p>
  </div>
</div>
</Link>



export default Body