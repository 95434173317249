import jobad from "./jobad";
import summarize from "./Macbeth Planner";
import codeInterpret from "./interpret";
import intro from "./intro";
import helloworld from "./helloworld";
import example from "./example";
import shakespeareplanner from "./Shakespeare Planner";
import handwriting from "./Handwriting";
import handwritingtwo from "./HandwritingConfig";
import appoetry from "./appoetry";
import approse from "./approse";
import aplit from "./aplit";
import aprhetoric from "./aprhetoric";
import apargument from "./apargument";
import apamhistorylong from "./apamhistorylong";
import apmodhistorylong from "./apmodhistorylong";
import apeurohistorylong from "./apeurohistorylong";
import regentseng from "./regentseng";
import ibdplitpaperone from "./ibdplitpaperone";
import ibdplitpapertwo from "./ibdplitpapertwo";
import ibdphistpapertwo from "./ibdphistpapertwo";
import ibdphistpaperthree from "./ibdphistpaperthree";
import ibdplanglitpapertwo from "./ibdplanglitpapertwo";
import apgovfour from "./apgovfour";
import apgovthree from "./apgovthree";
import apgovone from "./apgovone";
import ibdplithigher from "./ibdplithigher";
import ibdplanglithigher from "./ibdplanglithigher";
import ibglobaltwo from "./ibglobaltwo";
import ibmyponeanalytical from "./ibmyponeanalytical";
import ibmyponecompare from "./ibmyponecompare";
import ibmyponecreative from "./ibmyponecreative";
import ibmypthreeanalytical from "./ibmypthreeanalytical";
import ibmypthreecompare from "./ibmypthreecompare";
import ibmypthreecreative from "./ibmypthreecreative";

import texasengone from "./texasengone";
import texasengonebatch from "./texasengonebatch";
import texasengonehand from "./texasengonehand";
import texasengoneword from "./texasengoneword";
import texasengonemulti from "./texasengonemulti";
import texasengonezip from "./texasengonezip";

import texasengtwo from "./texasengtwo";

import texasengtwobatch from "./texasengtwobatch";
import texasengtwohand from "./texasengtwohand";
import texasengtwoword from "./texasengtwoword";
import texasengtwomulti from "./texasengtwomulti";
import texasengtwozip from "./texasengtwozip";

const TOOLS = [
  handwriting,
  handwritingtwo,
  appoetry,
  approse,
  aplit,
  aprhetoric,
  apargument,
  apamhistorylong,
  apmodhistorylong,
  apeurohistorylong,
  regentseng,
  ibdplitpaperone,
  ibdplitpapertwo,
  ibdphistpapertwo,
  ibdphistpaperthree,
  ibdplithigher,
  ibdplanglitpapertwo,
  ibdplanglithigher,
  apgovone,
  apgovthree,
  apgovfour,
  ibglobaltwo,
  ibmyponeanalytical,
  ibmyponecompare,
  ibmyponecreative,
  ibmypthreeanalytical,
  ibmypthreecompare,
  ibmypthreecreative,

  texasengone,
  texasengonebatch,
  texasengonehand,
  texasengoneword,
  texasengonemulti,
  texasengonezip,

  texasengtwo,
  texasengtwobatch,
  texasengtwohand,
  texasengtwoword,
  texasengtwomulti,
  texasengtwozip,
];

export default TOOLS;
